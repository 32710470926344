import React from 'react'

export const SafetyNetIcon = ({ fill }: { fill: string }) => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="38" height="38" rx="8" fill={fill} />
      <path
        d="M20.2 25H17.8L17.8 22.6H20.2L20.2 25ZM20.2 20.2H17.8L17.8 13H20.2L20.2 20.2ZM19 31C25.624 31 31 25.624 31 19C31 12.376 25.624 7 19 7C12.376 7 7 12.376 7 19C7 25.624 12.376 31 19 31Z"
        fill="white"
      />
    </svg>
  )
}
