import React from 'react'
import { Flex, SimpleGrid, IconButton, Link, useMediaQuery, Image, Text } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

import { CloseIcon } from '@blueprinthq/joy'
import { useAuthStore, useWebViewStore } from '@hooks'
import AppInviteQRCode from '../../assets/images/AppInvite-QRCode.jpeg'

export const AppPrompt = observer(() => {

  const authStore = useAuthStore()
  const webViewStore = useWebViewStore()
  const navigate = useNavigate()

  const [isDesktopView] = useMediaQuery([
    "(min-width: 960px)"
  ])

  if (!authStore.isAppPromptVisible || webViewStore.isReactNativeWebView) {
    return null
  }

  if (isDesktopView) {
    return (
      <Flex onClick={() => navigate('/app-invite')} _hover={{
        cursor: 'pointer',
        shadow: 'md',
      }} sx={{
        transition: 'box-shadow 320ms',
        position: 'fixed',
        left: '24px',
        bottom: '24px',
        bg: 'white',
        p: 'small',
        borderRadius: 'md',
        flexDirection: 'column',
      }}>
        <Image src={AppInviteQRCode} width="85px" height="85px" />
        <Text fontSize="sm" mt="xsmall" fontWeight="bold">Use the app</Text>
      </Flex>
    )
  }


  return (
    <SimpleGrid columns={3} sx={{
      position: 'fixed',
      height: '40px',
      width: '100%',
      backgroundColor: 'primary',
      bottom: 0,
    }}>
      <Flex justify="center" align="center" gridColumnStart={2}>
        <Link href={process.env.REACT_APP_BRANCH_APP_INVITE_LINK} textDecoration="underline" color="white">Use the App</Link>
      </Flex>
      <Flex justify="flex-end" align="center" gridColumnStart={3}>
        <IconButton onClick={() => authStore.setAppPromptVisible(false)} variant="ghost" m="0px" mr="xsmall" icon={<CloseIcon size="sm" fill="white" />} aria-label="Close" />
      </Flex>
    </SimpleGrid>
  )
})