import { Button, TextField } from '@blueprinthq/joy'
import {
  Box,
  Heading, HStack,
  Radio, RadioGroup, Text,
  VStack
} from '@chakra-ui/react'
import { renderErrorMessage } from '@utilities'
import { Field, FieldProps, Form, Formik } from 'formik'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import InputMask from 'react-input-mask'
import * as Yup from 'yup'

import { PublicOnboardingControllerPostLookupMutationBody, PublicOnboardingControllerPostLookupMutationResult, usePublicOnboardingControllerPostLookup } from '~/api/public'

enum PreferredField {
  PHONE = 'phone',
  EMAIL = 'email'
}

interface LookupInviteFormValues {
  email: string | null
  phoneNumber: string | null
  lastName?: string | undefined;
  dateOfBirth?: string | undefined;
}

interface LookupInviteFormProps {
  onSuccess: (values: LookupInviteFormValues) => void
}

const LookupInviteSchema = Yup.object({
  showExtraFields: Yup.boolean(),
  preferredField: Yup.string(),
  phoneNumber: Yup.string()
    // @ts-ignore
    .phone('US', false, 'Must be a valid phone number.')
    .when(['preferredField'], {
      is: (preferredField: string) => {
        return preferredField === PreferredField.PHONE
      },
      then: (schema: any) => {
        return schema.required('* Required')
      }
    }),
  email: Yup.string()
    .email('Please enter a valid email')
    .when(['preferredField'], {
      is: (preferredField: string) => {
        return preferredField === PreferredField.EMAIL
      },
      then: (schema: any) => {
        return schema.required('* Required')
      }
    }),
  lastName: Yup.string().when('showExtraFields', {
    is: (showExtraFields: boolean) => {
      return showExtraFields
    },
    then: schema => schema.required('* Required')
  }),
  dateOfBirth: Yup.date()
    .max(DateTime.now(), "You can't be born in the future!")
    .min(DateTime.now().minus({ years: 130 }), 'Year is too far in the past.')
    .typeError('Please enter a valid date of birth')
    .when('showExtraFields', {
      is: (showExtraFields: boolean) => {
        return showExtraFields
      },
      then: schema => schema.required('* Required')
    }),
})

export const LookupInviteForm = ({ onSuccess }: LookupInviteFormProps) => {
  const [displayError, setDisplayError] = useState<undefined | string>(
    undefined
  )

  const { mutate: postLookup } = usePublicOnboardingControllerPostLookup()

  return (
    <Box w="100%">
      <Heading size="lg" mb="xsmall">
        Welcome to Blueprint
      </Heading>
      <Text>Please enter the email address or phone number that your clinician invited you to join Blueprint with below.</Text>
      <Box mt="small">
        <Formik
          validationSchema={LookupInviteSchema}
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={{
            preferredField: PreferredField.PHONE,
            email: '',
            phoneNumber: '',
            showExtraFields: false,
            lastName: undefined,
            dateOfBirth: undefined,
          }}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true)

            let body: PublicOnboardingControllerPostLookupMutationBody | {} = {}

            if (values.lastName) {
              // @ts-ignore
              body.lastName = values.lastName
            }

            if (values.dateOfBirth) {
              // @ts-ignore
              body.dateOfBirth = values.dateOfBirth
            }
            

            if (values.preferredField === PreferredField.EMAIL) {
              body = {
                type: 'email',
                email: values.email,
                ...body
              }
            } else {
              body = {
                type: 'phonenumber',
                phoneNumber: values.phoneNumber,
                ...body
              }
            }

            postLookup(
              {
                data: body as PublicOnboardingControllerPostLookupMutationBody
              },
              {
                onSuccess: (data: PublicOnboardingControllerPostLookupMutationResult) => {
                  if (data.status === 'no-active-invite-found' || data.status === 'invite-already-accepted') {
                    setDisplayError('no-active-invite-found')
                  } else if (data.status === 'ambiguous-invite-found') {
                    if (values.showExtraFields) {
                      setDisplayError('ambiguous-invite-found')
                    } else {
                      setDisplayError(undefined)
                      actions.setFieldValue('showExtraFields', true)
                    }
                  } else {
                    onSuccess(values)
                  }
                },
                onError: error => {
                  setDisplayError(error.response?.data.error.message)
                }
              }
            )
          }}
        >
          {({ errors, touched, values, isSubmitting }) => {
            return (
              <Form>
                <VStack spacing="medium" align="flex-start">
                  <Field name="preferredField">
                    {({ field, form }: FieldProps) => (
                      <RadioGroup
                        {...field}
                        onChange={val => {
                          form.setFieldValue('lastName', undefined)
                          form.setFieldValue('dateOfBirth', undefined)
                          form.setFieldValue('showExtraFields', false)
                          // clear other value on change
                          if (val === PreferredField.PHONE) {
                            form.setFieldValue('email', '')
                            form.setFieldError('email', undefined)
                          } else {
                            form.setFieldValue('phoneNumber', '')
                            form.setFieldError('phoneNumber', undefined)
                          }
                          form.setFieldValue(field.name, val)
                          setDisplayError(undefined)
                        }}
                      >
                        <HStack spacing="medium">
                          <Radio value={PreferredField.PHONE}>Phone</Radio>
                          <Radio value={PreferredField.EMAIL}>Email</Radio>
                        </HStack>
                      </RadioGroup>
                    )}
                  </Field>

                  {values.preferredField === PreferredField.PHONE && (
                    <Box
                      w="100%"
                      pb={
                        errors.phoneNumber && touched.phoneNumber
                          ? 'small'
                          : 'initial'
                      }
                    >
                      <Field name="phoneNumber">
                        {({ field }: FieldProps) => (
                          <InputMask mask="(999) 999-9999" {...field}>
                            {() => (
                              <TextField
                                {...field}
                                autoFocus
                                label="Phone number"
                                type="tel"
                                isRequired
                                isInvalid={
                                  !!(errors.phoneNumber && touched.phoneNumber)
                                }
                                errorText={errors.phoneNumber}
                              />
                            )}
                          </InputMask>
                        )}
                      </Field>
                    </Box>
                  )}
                  {values.preferredField === PreferredField.EMAIL && (
                    <Box
                      w="100%"
                      pb={errors.email && touched.email ? 'small' : 'initial'}
                    >
                      <Field name="email">
                        {({ field }: FieldProps) => (
                          <TextField
                            {...field}
                            autoFocus
                            type="email"
                            label="Email"
                            isRequired
                            isInvalid={!!(errors.email && touched.email)}
                            errorText={errors.email}
                          />
                        )}
                      </Field>
                    </Box>
                  )}
                  {values.showExtraFields && (
                    <>
                      <Box mb="small">
                        <Text fontWeight="bold">We need more info</Text>
                        <Text>
                          Please enter a last name and date of birth to further identify
                          your account.
                        </Text>
                      </Box>
                      <Box
                        w="100%"
                        pb={
                          errors.lastName && touched.lastName ? 'small' : 'initial'
                        }
                      >
                        <Field name="lastName">
                          {({ field }: FieldProps) => (
                            <TextField
                              {...field}
                              label="Last name"
                              isRequired
                              isInvalid={!!(errors.lastName && touched.lastName)}
                              errorText={
                                touched.lastName && errors.lastName
                                  ? errors.lastName
                                  : undefined
                              }
                            />
                          )}
                        </Field>
                      </Box>
                      <Box
                        w="100%"
                        pb={
                          errors.dateOfBirth && touched.dateOfBirth
                            ? 'small'
                            : 'initial'
                        }
                      >
                        <Field name="dateOfBirth">
                          {({ field }: FieldProps) => (
                            <InputMask
                              mask={'99/99/9999'}
                              {...field}
                            >
                              {(inputProps: any) => (
                                <TextField
                                  {...inputProps}
                                  label="Date of Birth"
                                  isRequired
                                  isInvalid={
                                    !!(errors.dateOfBirth && touched.dateOfBirth)
                                  }
                                  errorText={errors.dateOfBirth}
                                />
                              )}
                            </InputMask>
                          )}
                        </Field>
                      </Box>
                    </>
                  )}
                </VStack>
                {displayError && (
                  <Box w="100%" mt="medium" mb="xsmall" color="error">
                    {renderErrorMessage(displayError)}
                  </Box>
                )}
                <Box w="100%">
                  <Button
                    mt="small"
                    size="lg"
                    w="100%"
                    isLoading={isSubmitting}
                    type="submit"
                  >
                    Next
                  </Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </Box>
    </Box>
  )
}
