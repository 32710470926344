import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Flex, SimpleGrid, IconButton } from '@chakra-ui/react'
import { Button, ArrowLeftIcon } from '@blueprinthq/joy'

import WhiteLogo from '../../assets/svgs/white-logo.svg'
import { useAuthStore } from '@hooks'
import { useAppBarStore } from '@core/store'
import { Logo } from '@components'

export const AppBarMobileView = observer(() => {
  const { isAuthenticated } = useAuthStore()
  const { onBack, isCheckInButtonVisible, rightComponent, logoUrl } = useAppBarStore()
  const navigate = useNavigate()

  return (
    <SimpleGrid
      sx={{
        w: '100%'
      }}
      columns={3}
    >
      <Flex gridColumnStart={1} justifyContent="flex-start" alignItems="center">
        {onBack && (
          <IconButton
            aria-label="Go back"
            icon={<ArrowLeftIcon fill="white" />}
            onClick={onBack}
            _focus={{ boxShadow: 'none' }}
          />
        )}
      </Flex>
      <Flex
        gridColumnStart={2}
        justifyContent="center"
        alignItems="center"
        onClick={() => navigate('/')}
        _hover={{ cursor: 'pointer' }}
      >
        <Logo src={logoUrl || WhiteLogo} />
      </Flex>
      <Flex gridColumnStart={3} justifyContent="flex-end" alignItems="center">
        {isAuthenticated && isCheckInButtonVisible ? (
          <Button as={Link} to="/check-ins">
            Check-In
          </Button>
        ) : rightComponent ? (
          rightComponent
        ) : null}
      </Flex>
    </SimpleGrid>
  )
})
