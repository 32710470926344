import React from 'react'
import { observer } from 'mobx-react-lite'
import { Link, useNavigate } from 'react-router-dom'
import { Flex, SimpleGrid } from '@chakra-ui/react'
import { Button, ArrowLeftIcon } from '@blueprinthq/joy'

import WhiteLogo from '../../assets/svgs/white-logo.svg'
import { useAppBarStore } from '@core/store'
import { useAuthStore } from '@hooks'
import { Logo } from '@components'

export const AppBarDesktopView = observer(() => {
  const { isAuthenticated } = useAuthStore()
  const { leftComponent, rightComponent, onBack, isCheckInButtonVisible, logoUrl } =
    useAppBarStore()
  const navigate = useNavigate()

  return (
    <SimpleGrid
      sx={{
        w: '100%'
      }}
      columns={3}
    >
      <Flex gridColumnStart={1} justifyContent="flex-start" alignItems="center">
        {onBack ? (
          <Button
            iconSpacing="0px"
            paddingLeft="8px"
            leftIcon={<ArrowLeftIcon fill="white" />}
            onClick={onBack}
            _focus={{ boxShadow: 'none' }}
          >
            Back
          </Button>
        ) : (
          leftComponent
        )}
      </Flex>
      <Flex
        gridColumnStart={2}
        justifyContent="center"
        alignItems="center"
        onClick={() => navigate('/')}
        _hover={{ cursor: 'pointer' }}
      >
        <Logo src={logoUrl || WhiteLogo} />
      </Flex>
      <Flex gridColumnStart={3} justifyContent="flex-end" alignItems="center">
        {isAuthenticated && isCheckInButtonVisible ? (
          <Button as={Link} to="/check-ins">
            Check-In
          </Button>
        ) : rightComponent ? (
          rightComponent
        ) : null}
      </Flex>
    </SimpleGrid>
  )
})
