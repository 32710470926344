import React from 'react'
import { Box, Center, BoxProps, useMediaQuery } from '@chakra-ui/react'
import { ChartIcon, InboxIcon, SettingsOutlineIcon } from '@blueprinthq/joy'
import { NavLink } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { useAuthStore, useWebViewStore } from '@hooks'

import { usePatientProfileStore } from '@core/store'

import { useUserControllerV1GetAssignedAssessments } from '~/api/public'

import { IconBadge } from '../../components/icon-badge'

export const BottomNav = ({
  props,
  userId
}: {
  props: BoxProps
  userId: string
}) => {
  const authStore = useAuthStore()
  const webViewStore = useWebViewStore()

  const [isDesktopView] = useMediaQuery(['(min-width: 960px)'])

  const { data: assignedAssessments = [] } =
    useUserControllerV1GetAssignedAssessments(userId)

  const overdueAssessments = assignedAssessments.filter(a => a.isOverdue)

  return (
    <Box
      as="nav"
      w="264px"
      h="60px"
      bottom={
        authStore.isAppPromptVisible &&
        !webViewStore.isReactNativeWebView &&
        !isDesktopView
          ? '64px'
          : '24px'
      }
      borderRadius="50px"
      position="fixed"
      display="flex"
      justifyContent="space-around"
      sx={{
        boxShadow: '0px -2px 18px rgba(45, 84, 232, 0.1)',
        background: 'white',
        left: '50%',
        transform: 'translateX(-50%)'
      }}
      {...props}
    >
      <Box as={NavLink} to="/" flex="1">
        {/* @ts-ignore */}
        {({ isActive }) => (
          <Center h="100%" fill={isActive ? 'primary' : 'black'}>
            <IconBadge count={overdueAssessments?.length}>
              <InboxIcon />
            </IconBadge>
          </Center>
        )}
      </Box>
      <Box as={NavLink} to="progress" flex="1">
        {/* @ts-ignore */}
        {({ isActive }) => (
          <Center h="100%" fill={isActive ? 'primary' : 'black'}>
            <ChartIcon />
          </Center>
        )}
      </Box>
      <Box as={NavLink} to="settings" flex="1">
        {/* @ts-ignore */}
        {({ isActive }) => (
          <Center h="100%" fill={isActive ? 'primary' : 'black'}>
            <SettingsOutlineIcon />
          </Center>
        )}
      </Box>
    </Box>
  )
}

export const BottomNavView = observer((props: BoxProps) => {
  const { activeProfile } = usePatientProfileStore()

  if (!activeProfile?.userId) {
    return null
  }

  return (
    <BottomNav props={props} userId={activeProfile.userId} />
  )
})
