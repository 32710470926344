import React from 'react'
import {
  Container,
  Box,
  Text,
  GridItem,
  Heading,
  SkeletonText,
  Flex
} from '@chakra-ui/react'
import { LayoutGrid, ArrowRightIcon, CheckIcon } from '@blueprinthq/joy'
import { groupBy, partition } from 'lodash'
import { Link, useNavigate } from 'react-router-dom'

import { 
  useUsersControllerV1GetCheckInScores,
  useUsersControllerV2GetAssignedCheckins,
 } from '~/api/client'
import { CheckInDtoV2 } from '~/api/client/models'
import { useWebViewStore, useOnNavBack } from '@hooks'
import { WebViewMessages } from '@types'
import { DateTime } from 'luxon'
import { usePatientProfileStore } from '@core/store'
import { observer } from 'mobx-react-lite'

type CheckInItem = CheckInDtoV2 & { lastCompleted: string }

interface CheckInItemProps {
  label: string
  url: string
  lastCompleted: string | undefined
}

interface CheckInGroupProps {
  label: string
  modules: CheckInItem[]
}

const CheckInGroup = ({ label, modules }: CheckInGroupProps) => {
  return (
    <>
      <Box mb="xsmall" mt="medium">
        <Text fontWeight="bold">{label}</Text>
      </Box>
      <Box borderWidth="1px" rounded="lg">
        {modules.map(module => {
          return (
            <CheckInItem
              key={module.id}
              label={module.title}
              url={`/check-ins/${module.id}`}
              lastCompleted={module.lastCompleted}
            />
          )
        })}
      </Box>
    </>
  )
}

const CheckInItem = ({ label, url, lastCompleted }: CheckInItemProps) => {
  var d1 = lastCompleted && DateTime.fromISO(lastCompleted)
  var d2 = DateTime.now()
  const withinADay = d1 ? d1.hasSame(d2, 'day') : false

  return (
    <Box borderTopWidth="1px" _first={{ borderTopWidth: 0 }}>
      <Box
        as={Link}
        w="100%"
        to={{
          pathname: url
        }}
      >
        <Box w="100%" p="small" pr="xsmall">
          <Box
            position="relative"
            display="flex"
            justifyContent="space-between"
            w="100%"
          >
            {/* TODO border color */}
            <Box
              width="4px"
              borderRadius="2px"
              bg={withinADay ? 'pale_gray' : 'primary'}
              height="100%"
              position="absolute"
              float="left"
            />
            {label ? (
              <Box display="flex" alignItems="top" h="100%" width="100%">
                <Box ml="small" width="100%">
                  <Flex justifyContent="space-between" alignItems="center">
                    <Box>
                      <Text fontWeight="bold">{label}</Text>
                      {lastCompleted && (
                        <Text fontSize="x-small" color="gray">
                          Last completed{' '}
                          {DateTime.fromISO(lastCompleted).toRelative()}
                        </Text>
                      )}
                    </Box>
                    <Flex>
                      {withinADay ? (
                        <Text color="gray" display="flex" alignItems="center">
                          <CheckIcon fill="gray" size="sm" mr="1" /> Done
                        </Text>
                      ) : (
                        <Text color="primary" fontWeight="bold">
                          Start
                        </Text>
                      )}
                      <Box ml="xsmall" pt="1px">
                        <Box display="flex">
                          <ArrowRightIcon />
                        </Box>
                      </Box>
                    </Flex>
                  </Flex>
                </Box>
              </Box>
            ) : (
              <Box w="100%" h="72px" pr="xsmall">
                <SkeletonText ml="4" mt="2" noOfLines={3} spacing="4" />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export const CheckInListView = observer(() => {
  const webViewStore = useWebViewStore()
  const navigate = useNavigate()

  const { activeProfile } = usePatientProfileStore()

  useOnNavBack(() => {
    if (webViewStore.isReactNativeWebView && webViewStore.isUsingOldNav()) {
      webViewStore.postMessage(WebViewMessages.NAVIGATE_PROFILE)
    }
    navigate('/')
  })

  const { data: assignedCheckIns, isLoading } = useUsersControllerV2GetAssignedCheckins(
    activeProfile.userId
  )
  const assignedCheckInIds = assignedCheckIns?.map(ci => ci.id)

  const { data: checkInScoresResponse } = useUsersControllerV1GetCheckInScores(activeProfile.userId, {
    checkInIds: assignedCheckInIds || []
  })

  const checkInScores = groupBy(
    checkInScoresResponse?.checkInScores,
    'moduleId'
  )

  const groupedCheckIns = groupBy(assignedCheckIns, 'type')

  const treatmentActivities = groupedCheckIns['treatment_activity'] || []
  const symptoms = groupedCheckIns['symptom'] || []

  const [lifestyleCorrelates, symptomModules] = partition(
    symptoms,
    m => m.title === 'Lifestyle correlates'
  )

  const lifestyleCorrelate = {
    ...lifestyleCorrelates[0],
    lastCompleted:
      checkInScores[lifestyleCorrelates[0]?.id] &&
      checkInScores[lifestyleCorrelates[0]?.id][0].updatedAt
  }

  const formattedSymptomModules = symptomModules.map(module => ({
    ...module,
    lastCompleted:
      checkInScores[module.id] && checkInScores[module.id][0].updatedAt
  }))

  const formattedTreatmentActivities = treatmentActivities.map(module => ({
    ...module,
    lastCompleted:
      checkInScores[module.id] && checkInScores[module.id][0].updatedAt
  }))

  if (!assignedCheckIns) {
    return null
  }

  return (
    <Container
      paddingTop={{
        base: '24px'
      }}
      paddingLeft={{
        base: '8px',
        sm: '0px'
      }}
      paddingRight={{
        base: '8px',
        sm: '0px'
      }}
      pb="100px"
    >
      <LayoutGrid>
        <GridItem
          colStart={{
            base: 1,
            sm: 2,
            md: 4
          }}
          colEnd={{
            base: 5,
            sm: 8,
            md: 10
          }}
        >
          <Box w="100%">
            <Box mb={4}>
              <Heading size="lg">Select a check-in module</Heading>
            </Box>
            {lifestyleCorrelate && lifestyleCorrelate.id && (
              <CheckInGroup
                label="Lifestyle Correlates"
                modules={[lifestyleCorrelate]}
              />
            )}
            {formattedSymptomModules.length > 0 && (
              <CheckInGroup
                label="Symptom Modules"
                modules={formattedSymptomModules}
              />
            )}
            {formattedTreatmentActivities.length > 0 && (
              <CheckInGroup
                label="Worksheets"
                modules={formattedTreatmentActivities}
              />
            )}
          </Box>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
})
