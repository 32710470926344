import React from 'react'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { theme as defaultJoyTheme } from '@blueprinthq/joy'
import { QueryClientProvider } from 'react-query'
import * as yup from 'yup'
import YupPassword from 'yup-password'
import './core/lib/yup-phone'

import flagsmith from 'flagsmith'
import { FlagsmithProvider } from 'flagsmith/react'

import {
  AuthStoreProvider,
  WebViewStoreProvider,
  AppBarStoreProvider
} from '@core/store'
import { AppRouter } from './app.router'
import { queryClient } from './core/lib'

YupPassword(yup) // extend yup

const theme = extendTheme({
  ...defaultJoyTheme,
  styles: {
    global: {
      'html, body': {
        transition: 'none'
      }
    },
  },
  colors: {
    ...defaultJoyTheme.colors,
    black: '#282828'
  }
})

export const App = () => (
  <FlagsmithProvider
    flagsmith={flagsmith}
    options={{
      environmentID: process.env.REACT_APP_FLAGSMITH_ENV_ID as string
    }}
  >
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AppBarStoreProvider>
          <WebViewStoreProvider>
            <AuthStoreProvider>
              <AppRouter />
            </AuthStoreProvider>
          </WebViewStoreProvider>
        </AppBarStoreProvider>
      </QueryClientProvider>
    </ChakraProvider>
  </FlagsmithProvider>
)
