import React from 'react'
import { VStack, Container, GridItem } from '@chakra-ui/react'
import { useSearchParams } from 'react-router-dom'
import {
  CompletedWorksheets,
  SymptomsChart,
  LifestyleChart,
  PhysicalActivityChart
} from './modules'
import { LayoutGrid } from '@blueprinthq/joy'
import { usePatientProfileStore } from '@core/store'

import {
  useUsersControllerV1GetInsightsLifestyle,
  useUsersControllerV1GetInsightsSymptoms,
  useUsersControllerV1GetAssignedCheckins,
} from '~/api/client'
import { observer } from 'mobx-react-lite'

export const ProgressView = observer(() => {
  const [searchParams] = useSearchParams()
  const platform = searchParams.get('platform')
  const { activeProfile } = usePatientProfileStore()

  const showPhysicalActivity = platform === 'ios'

  const { data: symptomsGraph = [], isLoading: isSymptomsLoading } =
    useUsersControllerV1GetInsightsSymptoms(activeProfile.userId)
  const { data: lifestyleGraph = [], isLoading: isLifestyleLoading } =
    useUsersControllerV1GetInsightsLifestyle(activeProfile.userId)
  const { data: assignedCheckIns = [], isLoading: isCheckinsLoading } =
    useUsersControllerV1GetAssignedCheckins(activeProfile.userId)

  const isSymptomsChartVisible = assignedCheckIns.some(
    c => c.type === 'symptom' && c.title !== 'Lifestyle correlates'
  )

  return (
    <Container
      paddingTop={{
        base: '24px'
      }}
      pb="150px"
      paddingLeft={{
        base: '8px',
        sm: '0px'
      }}
      paddingRight={{
        base: '8px',
        sm: '0px'
      }}
    >
      <LayoutGrid>
        <GridItem
          colStart={{
            base: 1,
            sm: 2,
            md: 4
          }}
          colEnd={{
            base: 5,
            sm: 8,
            md: 10
          }}
        >
          <VStack spacing="32px">
            <LifestyleChart
              data={lifestyleGraph}
              isLoading={isLifestyleLoading}
            />
            {isSymptomsChartVisible && (
              <SymptomsChart
                data={symptomsGraph}
                isLoading={isSymptomsLoading}
              />
            )}
            <CompletedWorksheets />
            {showPhysicalActivity && (
              <PhysicalActivityChart
                data={lifestyleGraph}
                isLoading={isLifestyleLoading}
              />
            )}
          </VStack>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
})