import React, { useEffect } from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useParams,
  useLocation,
  useNavigate,
} from 'react-router-dom'

import {
  LoginView,
  HomeView,
  LogoutView,
  ForgotPasswordView,
  ResetPasswordView,
  KioskClinicView,
  UserAssessmentsView,
  AppInviteView,
  UnsubscribeSuccessfulView,
  CheckInView,
  CheckInListView,
  ProgressView,
  SettingsView,
  PermissionsView,
  AddCheckInReminderView,
  AccountView,
  CompletedCheckInListView,
  CompletedCheckInView,
  CheckInRemindersView,
  NotificationsView,
  SafetyNetView,
  InterventionView,
  AssessmentPreviewView,
  CheckInPreviewView,
  UserCheckInsView,
} from './views'
import {
  PublicLayout,
  PublicUserLayout,
  AuthedUserLayout,
  PublicKioskLayout
} from './layouts'
import { ScrollToTop } from '@components'

import { AcceptInviteView } from '@views/public'
import { useAuthStore } from '@hooks'
import { useKioskControllerV1GetPatientById } from '~/api/public'

const PatientNotificationRerouter = () => {
  const { cognitoId } = useParams()
  const { search } = useLocation()
  const notificationType =
    new URLSearchParams(search).get('notification_type') || 'assessment'
  window.location.href = `${process.env.REACT_APP_OLD_PATIENT_APP_URL}/patients/${cognitoId}/notification-preferences?notification_type=${notificationType}`
  return null
}

const LegacyPatientAssessmentRedirect = () => {
  const { patientId } = useParams()
  const navigate = useNavigate()

  const { data: client } = useKioskControllerV1GetPatientById(patientId || '')

  useEffect(() => {
    if (client) {
      navigate({
        pathname: `/user/${client?.userId}/assessments`
      })
    }
  }, [client])

  return null
}

const AppWindowHandlers = () => {
  const navigate = useNavigate()
  const authStore = useAuthStore()

  useEffect(() => {
    // @ts-ignore
    window.appNavigate = route => {
      navigate({
        pathname: route
      })
    }

    // @ts-ignore
    window.resumeSession = () => {
      authStore.resumeSession()
    }
  }, [])

  return null
}

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <AppWindowHandlers />
      <Routes>
        <Route path="/" element={<AuthedUserLayout />}>
          <Route index element={<HomeView />} />
          <Route path="/profile" element={<Navigate to="/" />} />
          <Route path="/check-ins" element={<CheckInListView />} />
          <Route
            path="/check-ins/:checkInId/completed"
            element={<CompletedCheckInListView />}
          />
          <Route
            path="/check-ins/:checkInId/completed/:checkInScoreId"
            element={<CompletedCheckInView />}
          />
          <Route path="/check-ins/:checkInId" element={<CheckInView />} />
          <Route path="/progress" element={<ProgressView />} />
          <Route path="/settings" element={<SettingsView />} />
          <Route path="/settings/account" element={<AccountView />} />
          <Route path="/settings/notifications" element={<NotificationsView />} />
          <Route path="/settings/check-in-reminders" element={<CheckInRemindersView />} />
          <Route
            path="/settings/check-in-reminders/new"
            element={<AddCheckInReminderView />}
          />
          <Route path="/settings/permissions" element={<PermissionsView />} />
          <Route path="/user/:userId/intervention/:interventionId" element={<InterventionView />} />
        </Route>
        <Route path="/" element={<PublicLayout />}>
          <Route path="/login" element={<LoginView />} />
          <Route path="/forgot-password" element={<ForgotPasswordView />} />
          <Route path="/reset-password" element={<ResetPasswordView />} />
          <Route path="/accept-invite" element={<AcceptInviteView />} />
          <Route path="/app-invite" element={<AppInviteView />} />
          <Route
            path="/unsubscribe-successful/:userId"
            element={<UnsubscribeSuccessfulView />}
          />
          <Route path="/assessment-preview" element={<AssessmentPreviewView />} />
          <Route path="/check-ins/:checkInId/preview" element={<CheckInPreviewView />} />
        </Route>
        <Route path="/" element={<PublicKioskLayout />}>
          <Route
            path="/clinics/:clinicDisplayId"
            element={<KioskClinicView />}
          />
        </Route>
        <Route path="/user/:userId" element={<PublicUserLayout />}>
          <Route path="assessments" element={<UserAssessmentsView />} />
          <Route path="check-ins" element={<UserCheckInsView />} />
          <Route path="safety-net" element={<SafetyNetView />} />
        </Route>
        <Route path="/logout" element={<LogoutView />} />
        <Route
          path="/patients/:patientId/assessments"
          element={<LegacyPatientAssessmentRedirect />}
        />
        <Route
          path="/patients/:cognitoId/notification-preferences"
          element={<PatientNotificationRerouter />}
        />
      </Routes>
    </BrowserRouter>
  )
}
