import { useEffect } from 'react'
import { useAppBarStore } from '@core/store'

export function useOnNavBack(callback: any) {
  const appBarStore = useAppBarStore()

  useEffect(() => {
    appBarStore.setOnBack(callback)
    return () => appBarStore.setOnBack(null)
  })

  return null
}
