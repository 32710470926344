import React, { useState } from 'react'
import { Box } from '@chakra-ui/react'
import {
  useSearchParams,
  Link,
  useNavigate,
  useLocation,
  Location
} from 'react-router-dom'

import { useOnNavBack, useWebViewStore } from '@hooks'

import {
  LookupInviteForm,
  VerifyInviteForm,
  SetupAccountInviteForm
} from './components'
import { WebViewMessages } from '@types'

export enum ClientInviteStep {
  LOOKUP = 'initiate',
  VERIFY = 'verify',
  SETUP_ACCOUNT = 'setup-account'
}

interface AcceptInviewViewState {
  step: ClientInviteStep;
  patientId: string | null;
  email: string | null;
  phoneNumber: string | null;
  lastName?: string;
  dateOfBirth?: string;
}

interface LocationProps extends Location {
  state: AcceptInviewViewState
}

export const AcceptInviteView = () => {
  const location = useLocation() as LocationProps
  const navigate = useNavigate()
  const webViewStore = useWebViewStore()

  const [state, setState] = useState<AcceptInviewViewState>({
    step: location.state?.step || ClientInviteStep.LOOKUP,
    patientId: location.state?.patientId || null,
    email: location.state?.email || null,
    phoneNumber: null
  })

  useOnNavBack(() => {
    if (state.step === ClientInviteStep.LOOKUP || state.step === ClientInviteStep.SETUP_ACCOUNT) {
      if (webViewStore.isReactNativeWebView) {
        webViewStore.postMessage(WebViewMessages.NAVIGATE_LOGIN)
      } else {
        navigate('/login')
      }
    } else if (state.step === ClientInviteStep.VERIFY) {
      setState({
        patientId: null,
        email: null,
        phoneNumber: null,
        step: ClientInviteStep.LOOKUP
      })
    }
  })

  return (
    <Box w="100%">
      {state.step === ClientInviteStep.LOOKUP && (
        <LookupInviteForm
          onSuccess={values => {
            setState({
              patientId: null,
              step: ClientInviteStep.VERIFY,
              email: values.email,
              phoneNumber: values.phoneNumber,
              dateOfBirth: values.dateOfBirth,
              lastName: values.lastName,
            })
          }}
        />
      )}
      {state.step === ClientInviteStep.VERIFY && (
        <VerifyInviteForm
          onSuccess={(patientId: string, email: string | null) => {
            setState(state => {
              return { ...state, patientId, email, step: ClientInviteStep.SETUP_ACCOUNT }
            })
          }}
          lastName={state.lastName}
          dateOfBirth={state.dateOfBirth}
          email={state.email!}
          phoneNumber={state.phoneNumber!}
        />
      )}
      {state.step === ClientInviteStep.SETUP_ACCOUNT && (
        <SetupAccountInviteForm patientId={state.patientId as string} email={state.email} />
      )}
    </Box>
  )
}
