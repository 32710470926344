import { useFormikContext } from 'formik'
import { useRef, useEffect, RefObject } from 'react'

export const useScrollToInvalidField = <T extends HTMLElement>(
  name: string
): RefObject<T> => {
  const formikContext = useFormikContext()
  const fieldRef = useRef<T>(null)

  useEffect(() => {
    const firstError = Object.keys(formikContext.errors)[0]
    if (formikContext.isSubmitting && firstError === name) {
      window.requestAnimationFrame(() => {
        fieldRef.current?.scrollIntoView({ behavior: 'auto', block: 'center' })
      })
    }
  }, [formikContext.isSubmitting, name, formikContext.errors])
  return fieldRef
}
