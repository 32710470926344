import React from 'react'
import { Box} from '@chakra-ui/react'

import { Loading } from '@components'
import { CheckInForm } from '@features'
import { useCheckInsControllerV1GetCheckIns } from '~/api/public'
import { useParams, useSearchParams } from 'react-router-dom'

export const CheckInPreviewView = () => {
  const [searchParams] = useSearchParams()
  const { checkInId } = useParams()

  const { data: checkIn, isLoading } = useCheckInsControllerV1GetCheckIns(checkInId!, {
    versionId: searchParams.get('versionId') ?? undefined
  })

  return (
    <Box w="100%" height="100%">
      {isLoading ? <Loading /> : (
        <CheckInForm checkIn={checkIn!} isPreview={true} onSubmit={() => {}} />
      )}
    </Box>
  )

}