import React, { useState } from 'react'
import {
  Heading,
  Text,
  Box,
  useTheme,
  HStack,
  Button,
  Link
} from '@chakra-ui/react'

import {
  TimeRangeSelector,
  TimeRangeFilter
} from './components/time-range-selector'
import { EmptyChart } from './empty-chart'
import { LoadingChart } from './loading-chart'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts'
import { DateTime } from 'luxon'
import { LifestyleGraphDto } from '~/api/client/models'
import { meanBy, inRange } from 'lodash'
import { buildChartIntervals, formatXAxisTick, formatYAxisTick } from './utils'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

interface LifestyleProps {
  data: LifestyleGraphDto[]
  isLoading: boolean
}
const lineStyle = [
  {
    key: 'energy',
    color: '#F8D557',
    title: 'Energy',
    hide: false
  },
  {
    key: 'positivity',
    color: '#68E19F',
    title: 'Positivity',
    hide: false
  },
  {
    key: 'sleep_quality',
    color: '#4BA8F8',
    title: 'Sleep',
    hide: false
  },
  {
    key: 'social',
    color: '#D341DE',
    title: 'Social',
    hide: false
  }
]
const lifestyleTypes = lineStyle.map(l => l.key)

export const LifestyleChart = ({ data, isLoading }: LifestyleProps) => {
  const [lines, setLines] = useState(lineStyle)

  const [timeRange, setTimeRange] = useState<TimeRangeFilter>(
    TimeRangeFilter.ONE_WEEK
  )

  const theme = useTheme()

  const lifestyleData = data.filter(d => lifestyleTypes.includes(d.type))

  const intervals = buildChartIntervals(timeRange)

  const chartDatas = intervals.map(interval => {
    const lineDatas = lifestyleData.reduce((acc, lifestyle) => {
      const data = lifestyle.data.filter(d =>
        inRange(
          DateTime.fromISO(d.date).toUnixInteger(),
          interval.start,
          interval.end
        )
      )
      return {
        ...acc,
        [lifestyle.type]: data.length ? meanBy(data, d => d.value + 3) : null
      }
    }, {})

    return {
      date: interval.end,
      ...lineDatas
    }
  })

  const renderLegend = () => {
    const handleToggle = (key: string) => {
      const mapped = lines.map(task => {
        return key === task.key ? { ...task, hide: !task.hide } : { ...task }
      })

      setLines(mapped)
    }

    return (
      <HStack justifyContent="space-around">
        {lines.map((option: any, index: number) => (
          <Button
            key={index}
            bg={option.hide ? 'white' : option.color}
            border="1px solid"
            borderColor={option.hide ? option.color : 'white'}
            color={option.hide ? option.color : 'white'}
            cursor="pointer"
            size="sm"
            height="24px"
            width="100%"
            margin="0"
            fontSize="10px"
            borderRadius="full"
            variant={option.hide ? 'outline' : 'solid'}
            justifyContent="center"
            onClick={() => handleToggle(option.key)}
          >
            {option.title}
          </Button>
        ))}
      </HStack>
    )
  }

  return (
    <Box w="100%">
      <Heading size="lg">Lifestyle</Heading>
      <Text mb={4}>
        Track your positivity, energy, sleep quality, and sociability over time.
      </Text>
      <Box mb={4}>
        <TimeRangeSelector
          value={timeRange}
          onChange={val => setTimeRange(val)}
        />
      </Box>
      {isLoading ? (
        <LoadingChart />
      ) : (
        <Box p={4} bg="white" borderRadius={8}>
          {lifestyleData.length ? (
            <ResponsiveContainer width="100%" height={200}>
              <LineChart
                width={500}
                height={180}
                margin={{ top: 10, right: 10, bottom: 0, left: 10 }}
                data={chartDatas}
              >
                <CartesianGrid
                  vertical={false}
                  stroke={theme.colors.pale_gray}
                />
                <XAxis
                  dataKey="date"
                  height={40}
                  interval={0}
                  tickFormatter={(val, i) => {
                    return formatXAxisTick(val, timeRange, i === 0)
                  }}
                  axisLine={{ stroke: theme.colors.pale_gray }}
                  tickLine={false}
                  style={{
                    fontSize: '10px',
                    color: theme.colors.dark_gray
                  }}
                />
                <YAxis
                  width={20}
                  domain={[0, 5]}
                  tickCount={6}
                  interval={0}
                  tickLine={false}
                  tickFormatter={val => formatYAxisTick(val)}
                  axisLine={{ stroke: theme.colors.pale_gray }}
                  style={{
                    fontSize: '10px',
                    color: theme.colors.dark_gray
                  }}
                />
                <Legend content={renderLegend} />

                {lines.map(line => {
                  return (
                    <Line
                      dataKey={line.key}
                      fill={line.color}
                      stroke={line.color}
                      dot={{
                        stroke: line.color,
                        strokeOpacity: 1,
                        strokeWidth: 2
                      }}
                      strokeWidth="4"
                      strokeOpacity=".5"
                      isAnimationActive={false}
                      type="monotone"
                      hide={line.hide}
                    />
                  )
                })}
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <EmptyChart message="No Lifestyle Correlates completed.">
              <Button as={Link} to="/check-ins" isFullWidth>
                Check in
              </Button>
            </EmptyChart>
          )}
        </Box>
      )}
    </Box>
  )
}
