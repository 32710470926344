import React, { useMemo } from 'react'
import { Box, Text, VStack } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Button } from '@blueprinthq/joy'
import { StickyFooter } from '@components'
import { AssessmentFormField } from './assessment-form-field'
import { AssessmentDto } from '~/api/public/models'
import { validate } from 'uuid'
import { datadogLogs } from '@datadog/browser-logs'

interface AssessmentFormProps {
  assessment: AssessmentDto
  userId?: string
  values?: any
  onSubmit?: any
  isLastAssessment?: boolean
  isPreview?: boolean;
}

function formatAssessmentValues(assessment: AssessmentDto, values: any) {
  const formattedValues: any = {}
  const section = assessment.content.sections[0]

  Object.entries(values).forEach(([key, value]: [string, any]) => {
    const matchingQuestion = section.questions.find(q => q.key === key)

    if (matchingQuestion?.type === 'multi-select') {
      formattedValues[key] = value.map((v: any) => parseFloat(v))
    } else if (matchingQuestion?.type === 'free_text') {
      formattedValues[key] = value
    } else {
      formattedValues[key] = validate(value) ? value : parseFloat(value)
    }
  })

  return formattedValues
}

export const AssessmentForm = ({
  assessment,
  userId,
  values,
  onSubmit,
  isLastAssessment,
  isPreview
}: AssessmentFormProps) => {
  const section = useMemo(() => assessment.content.sections[0], [assessment.id])

  const schema = useMemo(() => {
    const questionsSchema = section.questions.reduce((qs: any, question) => {
      let schema
      if (question.type === 'multi-select') {
        schema = Yup.array()
        if (!question.skippable) {
          schema = schema.min(1, '* Must select at least one option')
        }
      } else if (question.type === 'free_text') {
        schema = Yup.string()
      } else if (question.type === 'number') {
        schema = Yup.number()
          .when({
            is: (value: unknown) => value === null,
            then: schema => schema.required('* Enter whole number')
          })
          .typeError('* Enter whole number')
          .nullable()
        
        if (question.meta?.max !== undefined) {
          schema = schema.max(question.meta?.max , `* Enter <= ${question.meta.max}`)
        }

        if (question.meta?.min !== undefined) {
          if (question.meta.max !== undefined) {
            schema = schema.min(question.meta?.min , `* Enter between ${question.meta.min} and ${question.meta.max}`)
          } else {
            schema = schema.min(question.meta?.min , `* Enter >= ${question.meta.min}`)
          }
        }
      } else {
        schema = Yup.string().nullable()
      }

      if (question.skippable) {
        schema = schema?.notRequired()
      } else {
        schema = schema?.required('* Required')
      }

      qs[question.key] = schema

      return qs
    }, {})

    return Yup.object().shape(questionsSchema)
  }, [assessment.id])

  const initialValues = useMemo(() => {
    if (values) {
      return values
    }

    return section.questions.reduce((values: any, question) => {
      if (question.type === 'multi-select') {
        values[question.key] = []
      } else if (question.type === 'free_text') {
        values[question.key] = ''
      } else {
        values[question.key] = null
      }
      return values
    }, {})
  }, [assessment.id, values])

  return (
    <Box>
      <Box>
        <Text fontWeight="bold">{assessment.name}</Text>
        <Text mt="small" whiteSpace="pre-line" fontSize="lg">{section.title}</Text>
      </Box>
      <Box mt="medium" mb="xxlarge">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={async (values, actions) => {
            datadogLogs.logger.info('assessment-form onSubmit', { userId, assessmentId: assessment.id })
            actions.setSubmitting(true)
            await onSubmit(
              assessment.id,
              formatAssessmentValues(assessment, values)
            )
            actions.setSubmitting(false)
            actions.resetForm(initialValues)
          }}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <VStack spacing="medium" pb="128px" align="flex-start">
                  {section.questions.map((question, i) => {
                    const answerOptions = question.answers || section.answers
                    return (
                      <AssessmentFormField
                        key={assessment.id + ':' + i + ':' + question.key}
                        number={i + 1}
                        question={question}
                        answers={answerOptions}
                      />
                    )
                  })}
                </VStack>
                <StickyFooter>
                  <Box w="100%">
                    <Button
                      isDisabled={isPreview}
                      size="lg"
                      w="100%"
                      isLoading={isSubmitting}
                      type="submit"
                    >
                      {isPreview ? 'Preview Only' : isLastAssessment ? 'Submit' : 'Next'}
                    </Button>
                  </Box>
                </StickyFooter>
              </Form>
            )
          }}
        </Formik>
      </Box>
    </Box>
  )
}
