import React, { useState } from 'react'
import { Heading, Box, Text, Flex, Link, Button } from '@chakra-ui/react'
import { CheckIcon } from '@blueprinthq/joy'
import { Link as RouterLink } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { WebViewMessages } from '@types'
import { useWebViewStore, useOnNavBack } from '@core/hooks'
import { ResetPasswordForm } from './components'

export const ResetPasswordView = () => {
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const webViewStore = useWebViewStore()
  const navigate = useNavigate()

  useOnNavBack(() => {
    if (webViewStore.isReactNativeWebView) {
      webViewStore.postMessage(WebViewMessages.NAVIGATE_LOGIN)
    } else {
      navigate('/login')
    }
  })

  if (showConfirmation) {
    return (
      <Flex align="center" direction="column" paddingTop="small">
        <Flex
          bg="primary"
          width="64px"
          height="64px"
          borderRadius="50%"
          align="center"
          justify="center"
        >
          <CheckIcon size="lg" fill="white" />
        </Flex>
        <Box mt="large" textAlign="center">
          <Text textAlign="center" fontWeight="bold" fontSize="lg">
            Password reset
          </Text>
          <Text>
            Please return to the login screen and sign in.
          </Text>
        </Box>
        <Box mt="medium">
        {webViewStore.isReactNativeWebView ? (
          <Button onClick={() => webViewStore.postMessage(WebViewMessages.NAVIGATE_LOGIN)}>Back to sign in</Button>
        ) : (
          <Link to="/login" color="primary" as={RouterLink}>
            Back to sign in
          </Link>
        )}
      </Box>
      </Flex>
    )
  }

  return (
    <Box w="100%">
      <Heading size="lg" mb="small">
        Reset your password
      </Heading>
      <Text>
        If your account was found, an email was sent with a password reset code.
        Please enter this code and choose a new password. If you cannot find
        this email, check in your spam folder.
      </Text>
      <Box mt="small">
        <ResetPasswordForm onSuccess={setShowConfirmation} />
      </Box>
    </Box>
  )
}
