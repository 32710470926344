import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuthStore, useWebViewStore } from '@core/hooks'
import { WebViewMessages } from '@types'

export const LogoutView = () => {
  const authStore = useAuthStore()
  const webViewStore = useWebViewStore()
  const navigate = useNavigate()

  useEffect(() => {
    if (webViewStore.isReactNativeWebView) {
      webViewStore.postMessage(WebViewMessages.ACTION_LOGOUT)
    } else {
      authStore.logout()
      navigate('/login')
    }
  }, [])

  return null
}
