import React from 'react'
import {
  Box,
  Center,
  Container,
  Flex,
  GridItem,
  Heading,
  SkeletonText,
  StackDivider,
  Text,
  VStack
} from '@chakra-ui/react'
import {
  Button,
  LayoutGrid,
  ArrowRightIcon,
  CheckIcon,
  AssessmentIcon
} from '@blueprinthq/joy'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'
import { createSearchParams } from 'react-router-dom'
import { partition, sortBy } from 'lodash'

import { AssignedAssessmentDto } from '~/api/public/models'
import { useUserControllerV1GetAssignedAssessments } from '~/api/public'
import { SafetyNetCard } from './safety-net-card'
import { observer } from 'mobx-react-lite'
import { usePatientProfileStore } from '@core/store'

const MIN_CARD_HEIGHT = '98px'

interface AssessmentCardProps {
  assessment?: AssignedAssessmentDto
  isLoading?: boolean
}

const AssessmentCard = ({
  assessment,
  isLoading = false
}: AssessmentCardProps) => {
  return (
    <Box w="100%" p="small" pr="xsmall" minHeight={MIN_CARD_HEIGHT}>
      <Box
        position="relative"
        display="flex"
        justifyContent="space-between"
        w="100%"
      >
        <Box
          width="4px"
          borderRadius="2px"
          bg={isLoading || !assessment?.isOverdue ? 'pale_gray' : 'primary'}
          height="100%"
          position="absolute"
          float="left"
        />
        {assessment ? (
          <>
            <Box display="flex" alignItems="top" h="100%" width="100%">
              <Box ml="small" width="100%">
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Text fontWeight="bold">{assessment.assessment.name}</Text>
                  </Box>
                  <Box>
                    {assessment.isOverdue ? (
                      <Text color="primary" fontWeight="bold">
                        Take Now
                      </Text>
                    ) : (
                      <Text color="gray">
                        {assessment.administrationDate && DateTime.fromISO(
                          assessment.administrationDate
                        ).toFormat('M/d')}
                      </Text>
                    )}
                  </Box>
                </Box>
                <Text>{assessment.assessment.disorder}</Text>
                <Flex>
                  <Text>{assessment.assessment.totalQuestions} questions</Text>
                  <Text color="pale_gray" mx="xsmall" fontWeight="bold">
                    |
                  </Text>
                  <Text>{assessment.cadence}</Text>
                </Flex>
              </Box>
              {assessment.isOverdue ? (
                <Box ml="xsmall" pt="1px">
                  <Box display="flex">
                    <ArrowRightIcon />
                  </Box>
                </Box>
              ) : (
                <Box ml="xsmall"></Box>
              )}
            </Box>
          </>
        ) : (
          <Box w="100%" h="72px" pr="xsmall">
            <SkeletonText ml="4" mt="2" noOfLines={3} spacing="4" />
          </Box>
        )}
      </Box>
    </Box>
  )
}

interface AssessmentCardLinkProps {
  assessment: AssignedAssessmentDto
  userId: string
}

const AssessmentCardLink = ({ assessment, userId }: AssessmentCardLinkProps) => {
  return (
    <Box
      key={assessment.clinicAssessmentId}
      as={Link}
      w="100%"
      to={{
        pathname: `/user/${userId}/assessments`,
        search: createSearchParams({
          assessments: assessment.assessment.id
        }).toString()
      }}
    >
      <AssessmentCard assessment={assessment} />
    </Box>
  )
}

interface AssessmentGroupProps {
  isLoading?: boolean
  isOverdue?: boolean
  assessments: AssignedAssessmentDto[]
  userId: string
}

const AssessmentGroup = ({
  isLoading,
  assessments,
  isOverdue,
  userId,
}: AssessmentGroupProps) => {
  return (
    <VStack
      border="1px solid"
      borderColor="pale_blue"
      borderRadius="8px"
      spacing="0"
      bg="white"
      divider={<StackDivider />}
    >
      {isLoading ? (
        <AssessmentCard isLoading />
      ) : (
        sortBy(assessments, 'dueDate').map(assessment => {
          return isOverdue ? (
            <AssessmentCardLink
              userId={userId}
              assessment={assessment}
              key={assessment.clinicAssessmentId}
            />
          ) : (
            <AssessmentCard
              assessment={assessment}
              key={assessment.clinicAssessmentId}
            />
          )
        })
      )}
    </VStack>
  )
}

export const HomeView = observer(() => {
  const { activeProfile } = usePatientProfileStore()

  const { data: assignedAssessments = [], isLoading } =
    useUserControllerV1GetAssignedAssessments(activeProfile?.userId, {
      query: {
        enabled: !!activeProfile?.userId
      }
    })

  const [overdueAssessments, upcomingAssessments] = partition(
    assignedAssessments,
    a => a.isOverdue
  )

  return (
    <Container
      paddingTop={{
        base: '24px'
      }}
      paddingLeft={{
        base: '8px',
        sm: '0px'
      }}
      paddingRight={{
        base: '8px',
        sm: '0px'
      }}
      pb="100px"
    >
      <LayoutGrid>
        <GridItem
          colStart={{
            base: 1,
            sm: 2,
            md: 4
          }}
          colEnd={{
            base: 5,
            sm: 8,
            md: 10
          }}
        >
          <Box w="100%" pb="xxlarge">
            <Box w="100%">
              <Box mb={6}>
                <Heading size="lg">{`Hi, ${activeProfile?.firstName}`}</Heading>
              </Box>
              {activeProfile?.safetyNetEnabled && <SafetyNetCard />}
              <Box mb={4}>
                <Text fontWeight="bold">To Do</Text>
              </Box>
              {isLoading || overdueAssessments.length ? (
                <AssessmentGroup
                  userId={activeProfile.userId}
                  assessments={overdueAssessments}
                  isLoading={isLoading}
                  isOverdue
                />
              ) : (
                <Box minHeight={MIN_CARD_HEIGHT}>
                  <Center>
                    <CheckIcon />
                  </Center>
                  <Center>
                    <Text>You're all set!</Text>
                  </Center>
                </Box>
              )}
              {overdueAssessments.length ? (
                <Button
                  size="lg"
                  isFullWidth
                  color="white"
                  m="0px"
                  mt={4}
                  as={Link}
                  to={`/user/${activeProfile?.userId}/assessments`}
                >
                  Take All Assessments
                </Button>
              ) : null}
              <Box mb={4} mt={4}>
                <Text fontWeight="bold">Upcoming</Text>
              </Box>
              {isLoading || upcomingAssessments.length ? (
                <AssessmentGroup
                  userId={activeProfile.userId}
                  assessments={upcomingAssessments}
                  isLoading={isLoading}
                />
              ) : (
                <Box minHeight={MIN_CARD_HEIGHT}>
                  <Center>
                    <AssessmentIcon />
                  </Center>
                  <Center>
                    <Text>No upcoming assessments</Text>
                  </Center>
                </Box>
              )}
            </Box>
          </Box>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
})
  