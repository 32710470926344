import React from 'react'
import semver from 'semver'

export class WebViewStore {
  private static globalInstance: WebViewStore | null = null

  isReactNativeWebView: boolean = false
  platform: 'android' | 'ios' | null
  // @ts-ignore
  version: string

  constructor() {
    // @ts-ignore
    this.isReactNativeWebView = window.ReactNativeWebView
    // @ts-ignore
    this.platform = window.reactNativePlatform
    // @ts-ignore
    this.version = window.reactNativeVersion
  }

  isUsingOldNav(): boolean {
    if (this.platform === 'android') return semver.lte(this.version, '3.2.4')
    if (this.platform === 'ios') return semver.lte(this.version, '2.2.3')
    return true
  }

  postMessage(message: string) {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(message)
  }

  postRawMessage(raw: any) {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(JSON.stringify(raw))
  }

  static instance() {
    if (this.globalInstance === null) {
      this.globalInstance = new WebViewStore()
    }

    return this.globalInstance
  }
}

// @ts-ignore
export const WebViewStoreContext = React.createContext<WebViewStore>(null)

export const WebViewStoreProvider = ({ children }: any) => {
  const store = WebViewStore.instance()
  return (
    <WebViewStoreContext.Provider value={store}>
      {children}
    </WebViewStoreContext.Provider>
  )
}
