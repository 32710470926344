import React from 'react'
import {
  Container,
  GridItem,
  useToast,
  Box,
} from '@chakra-ui/react'
import { Navigate } from 'react-router-dom';
import { LayoutGrid } from '@blueprinthq/joy'
import { useParams, useNavigate } from 'react-router-dom'

import { usePatientProfileStore } from '@core/store'
import { CheckInForm } from '@features'
import { 
  useUsersControllerV1PostCheckInsSubmit,
  useUsersControllerV2GetAssignedCheckInById
 } from '~/api/client'
import { useOnNavBack } from '@hooks';
import { observer } from 'mobx-react-lite';

export const CheckInView = observer(() => {
  const { checkInId } = useParams()
  const { activeProfile } = usePatientProfileStore()

  const toast = useToast()

  const navigate = useNavigate()

  useOnNavBack(() => {
    navigate(-1)
  })

  const {
    data: checkIn, isError: checkInIsError, error: checkInError  
  } = useUsersControllerV2GetAssignedCheckInById(activeProfile.userId, checkInId!, {
    query: { enabled: !!checkInId }
  })
  
  const { mutate: submitCheckIn } =
    useUsersControllerV1PostCheckInsSubmit({
      mutation: {
        onSuccess() {
          navigate('/check-ins')
          toast({
            title: 'Thanks for checking in!',
            status: 'success',
            duration: 2000,
            isClosable: true
          })
        }
      }
    })

  const handleSubmit = (checkInId: string, answers: Record<string, any>) => {
    if (checkIn) {
      submitCheckIn({
        userId: activeProfile.userId,
        data: [
          {
            moduleVersionId: checkIn.versionId,
            moduleId: checkIn.id,
            answers: Object.keys(answers).map(key => ({
              key,
              value: answers[key]
            }))
          }
        ]
      })
    }
  }

  if(checkInIsError && checkInError.response && checkInError.response.status === 404) {
    return <Navigate to="/check-ins" replace={true} />
  }

  if (!checkIn) return null

  return (
    <Container
      height="100%"
      paddingLeft={{
        base: '8px',
        sm: '0px'
      }}
      paddingRight={{
        base: '8px',
        sm: '0px'
      }}
    >
      <LayoutGrid>
        <GridItem
          colStart={{
            base: 1,
            sm: 2,
            md: 4
          }}
          colEnd={{
            base: 5,
            sm: 8,
            md: 10
          }}
        >
          <Box height="100%" pt="medium">
            <CheckInForm
              checkIn={checkIn!}
              onSubmit={handleSubmit}
              isPreview={false}
            />
          </Box>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
})