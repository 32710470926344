import React from 'react'
import { makeAutoObservable } from 'mobx'
import { Auth, Amplify } from 'aws-amplify'
import { Buffer } from 'buffer';
import { StorageHelper } from '@aws-amplify/core';
import { datadogLogs } from '@datadog/browser-logs'

import { 
  getAccountControllerV1GetUserProfilesQueryKey, 
  accountControllerV1GetUserProfiles, 
  AccountControllerV1GetUserProfilesQueryResult 
} from '~/api/client'
import { queryClient } from '../lib'
import { WebViewStore } from './webview.store'
import { AppBarStore } from './app-bar.store'
import { WebViewMessages } from '@types'

const storage = new StorageHelper().getStorage();

const authLogger = datadogLogs.createLogger('Auth')

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  },
  storage: storage
})

const COOKIES = [
  'blueprint_id',
  'blueprint_idToken',
  'blueprint_accessToken',
  'blueprint_refreshToken'
]

export const decodePayload = (jwtToken: string) => {
  const payload = jwtToken.split('.')[1];
    try {
    return JSON.parse(Buffer.from(payload, 'base64').toString('utf8'));
    } catch (err) {
    return {};
    }
};

export const calculateClockDrift = (iatAccessToken: any, iatIdToken: any) => {
  // @ts-ignore
  const now = Math.floor(new Date() / 1000);
  const iat = Math.min(iatAccessToken, iatIdToken);
  return now - iat;
  };

export class AuthStore {
  private static globalInstance: AuthStore | null = null

  isAuthenticated: boolean = false
  isAppPromptVisible: boolean = true;

  constructor() {
    makeAutoObservable(this)
  }

  async resumeSession(skipAccountFetch = false) {
    authLogger.info('Attempting session resume')
    await this.attemptLoadAuthTokensFromCookies()
    const session = await Auth.currentSession()
    if (session.isValid()) {
      this.setIsLoggedIn(true)
      if (!skipAccountFetch) {
        // Prefetch account
        await queryClient.fetchQuery<AccountControllerV1GetUserProfilesQueryResult>({
          queryFn: accountControllerV1GetUserProfiles,
          queryKey: getAccountControllerV1GetUserProfilesQueryKey(),
        })
      }
      const webviewStore = WebViewStore.instance()

      if (webviewStore.isReactNativeWebView) {
        webviewStore.postMessage(WebViewMessages.ACTION_AUTH_SUCCESS)
      }
    } else {
      this.setIsLoggedIn(false)
    }
  }

  async login(email: string, password: string) {
    await Auth.signIn(email.toLowerCase(), password)
    await this.resumeSession()
  }

  public setIsLoggedIn(isLoggedIn: boolean): void {
    this.isAuthenticated = isLoggedIn;
  }
  
  async getJWTAccessToken(): Promise<string | null> {
    const session = await Auth.currentSession()
    if (session !== null) {
      return session.getAccessToken().getJwtToken()
    }
    return null
  }

  async logout(softLogout = false, options = { clearLogo: true }) {
    const webviewStore = WebViewStore.instance()
    const appbarStore = AppBarStore.instance()
    // Force webview logout
    if (!softLogout && webviewStore.isReactNativeWebView) {
      webviewStore.postMessage(WebViewMessages.ACTION_LOGOUT)
    }
    // Clear session cache
    queryClient.clear()
    localStorage.clear()

    // Clear logo
    if (options.clearLogo) {
      appbarStore.setLogoUrl(null)
    }

    // end auth session in native app
    await Auth.signOut()
    this.setIsLoggedIn(false)
  }

  static instance() {
    if (this.globalInstance === null) {
      this.globalInstance = new AuthStore()
    }

    return this.globalInstance
  }

  private attemptLoadAuthTokensFromCookies() {
    const match = this._getCookie(COOKIES[0])
    if (match) {
      const [id, idToken, accessToken, refreshToken] = this._getCookies(COOKIES)
      const idTokenData = decodePayload(idToken as string);
      const accessTokenData = decodePayload(accessToken as string);
      const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID

      const cognitoUser = idTokenData['cognito:username']

      authLogger.info('Loading auth session from cookies.', {
        cognitoUser
      })

      storage.setItem(`CognitoIdentityServiceProvider.${clientId}.LastAuthUser`, cognitoUser);
      storage.setItem(`CognitoIdentityServiceProvider.${clientId}.${idTokenData['cognito:username']}.idToken`, idToken);
      storage.setItem(`CognitoIdentityServiceProvider.${clientId}.${idTokenData['cognito:username']}.accessToken`, accessToken);
      storage.setItem(`CognitoIdentityServiceProvider.${clientId}.${idTokenData['cognito:username']}.refreshToken`, refreshToken);
      storage.setItem(`CognitoIdentityServiceProvider.${clientId}.${idTokenData['cognito:username']}.clockDrift`, ''+calculateClockDrift(accessTokenData['iat'], idTokenData['iat'])+'');
    }
  }

  private _getCookie = (name: string) => {
    function escape(s: string) {
      return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1')
    }
    var match = document.cookie.match(
      RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)')
    )
    return match ? match[1] : null
  }

  private _getCookies = (names: string[]) => {
    return names.map(name => this._getCookie(name))
  }

  public setAppPromptVisible(isVisible: boolean) {
    this.isAppPromptVisible = isVisible;
  }
}

// @ts-ignore
export const AuthStoreContext = React.createContext<AuthStore>(null)

export const AuthStoreProvider = ({ children }: any) => {
  const store = AuthStore.instance()
  return (
    <AuthStoreContext.Provider value={store}>
      {children}
    </AuthStoreContext.Provider>
  )
}
