import React from 'react'
import * as ReactDOM from 'react-dom'
import FontFaceObserver from 'fontfaceobserver'
import branch from 'branch-sdk'
import { App } from './app'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

export async function renderApp() {
  try {
    const fonts = [
      new FontFaceObserver('TofinoPersonal'),
      new FontFaceObserver('TofinoPersonal', { weight: 'bold' })
    ]
    await Promise.all(fonts.map(font => font.load(null, 5000)))
  } catch (err) {
    console.error('Error loading fonts', err)
  } finally {
    if (process.env.REACT_APP_ENV !== 'development') {
      if (process.env.REACT_APP_DATADOG_RUM_ENABLED === 'true') {
        datadogRum.init({
          site: 'datadoghq.com',
          applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
          clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
          env: process.env.REACT_APP_ENV,
          service: process.env.REACT_APP_DATADOG_SERVICE,
          sessionSampleRate: 100,
          sessionReplaySampleRate: 100,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: 'allow',
        })
      }
      datadogLogs.init({
        env: process.env.REACT_APP_ENV,
        service: process.env.REACT_APP_DATADOG_SERVICE,
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
        forwardErrorsToLogs: true,
      })
    }

    branch.init(process.env.REACT_APP_BRANCH_KEY)

    ReactDOM.render(<App />, document.getElementById('root'))
  }
}

renderApp()
