import React from 'react'
import { Box, Text, Flex, Link } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { SafetyNetIcon } from '@icons'
import { CloseIcon } from '@blueprinthq/joy'

import { usePatientProfileStore } from '@core/store'
import { useUsersControllerV1PatchSafetyNetDismiss, getAccountControllerV1GetUserProfilesQueryKey } from '~/api/client'
import { observer } from 'mobx-react-lite'

export const SafetyNetCard = observer(() => {
  const {
    activeProfile
  } = usePatientProfileStore()
  const queryClient = useQueryClient()

  const { mutate: dismissSafetyNet } = useUsersControllerV1PatchSafetyNetDismiss({
    mutation: {
      onSuccess() {
        queryClient.invalidateQueries(
          getAccountControllerV1GetUserProfilesQueryKey()
        )
      }
    }
  })

  return (
    <Flex
      w="100%"
      p="small"
      pr="xsmall"
      bg="white"
      minHeight="98px"
      borderRadius="8px"
      mb="small"
    >
      <SafetyNetIcon fill="#EB5164" />
      <Flex flexGrow={1} justifyContent="space-between">
        <Box ml="small">
          <Text as="b">Need Support?</Text>
          <Text>Access resources for more immediate assistance.</Text>
          <Link as={RouterLink} color="primary" to={`/user/${activeProfile?.userId}/safety-net`}>
            View Your Safety Net
          </Link>
        </Box>
        <Box mr="xsmall" _hover={{ cursor: 'pointer' }}>
          <CloseIcon onClick={() => dismissSafetyNet({ userId: activeProfile?.userId })} />
        </Box>
      </Flex>
    </Flex>
  )
})