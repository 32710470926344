import React, { useMemo } from 'react'
import { Box, Text, Heading, Link } from '@chakra-ui/react'

import { KioskFlow } from '@types'
import { Loading } from '@components'
import { KioskIdentificationForm } from './components'
import { useKioskControllerV1GetClinic } from '~/api/public'

interface KioskSharedViewProps {
  flow: KioskFlow
  clinicDisplayId?: string | null
}

export const KioskSharedView = ({
  flow,
  clinicDisplayId
}: KioskSharedViewProps) => {
  const {
    data: clinic,
    isLoading: isClinicLoading,
    isError: isClinicError
  } = useKioskControllerV1GetClinic(
    (clinicDisplayId && encodeURIComponent(clinicDisplayId)) || '',
    {
      query: {
        enabled: !!clinicDisplayId && flow === KioskFlow.CLINIC
      }
    }
  )

  const displayId: string = useMemo(() => {
    if (clinic) {
      return clinic?.displayId as string
    }
    return ''
  }, [clinic])

  return (
    <Box pb="xlarge">
      {isClinicError ? (
        <Box>
          <Text color="error" align="center">
            Could not find clinic:{' '}
            <Text as="span" fontWeight="bold">
              {clinicDisplayId}
            </Text>
            .
          </Text>
          <Text align="center">Are you sure you have the right URL?</Text>
        </Box>
      ) : isClinicLoading ? (
        <Loading isFullPage />
      ) : (
        <Box>
          <Heading mb="medium">Welcome to Blueprint</Heading>
          <Box mb="small">
            <Text>
              {clinic?.organizationName} uses Blueprint to measure your progress
              throughout treatment and ensure you're on the right track.
            </Text>
            <Text mt="medium">
              Fill out the following fields so we can match you up with the
              correct assessments.
            </Text>
          </Box>
          <KioskIdentificationForm clinicDisplayId={displayId} />
          <Box mt="small">
            <Text fontSize="sm">
              By continuing, you agree to our{' '}
              <Link
                href={`${process.env.REACT_APP_WWW_URL}/privacy`}
                target="_blank"
                textDecoration="underline"
              >
                Privacy Policy
              </Link>{' '}
              and{' '}
              <Link
                href={`${process.env.REACT_APP_WWW_URL}/terms`}
                target="_blank"
                textDecoration="underline"
              >
                Terms of Service
              </Link>
              .
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  )
}
