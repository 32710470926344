import React from 'react'
import { Outlet } from 'react-router-dom'
import { theme } from '@blueprinthq/joy'

import { Container, GridItem } from '@chakra-ui/react'
import { LayoutGrid } from '@blueprinthq/joy'
import { AppBar } from '@features'

export const PublicLayout = () => {
  return (
    <>
      <AppBar />
      <Container
        pt="medium"
        height={{
          // @ts-ignore
          base: `calc(100vh - ${theme.components.AppBar.baseStyle.height.base})`,
          // @ts-ignore
          sm: `calc(100vh - ${theme.components.AppBar.baseStyle.height.sm})`,
          // @ts-ignore
          xl: `calc(100vh - ${theme.components.AppBar.baseStyle.height.xl})`
        }}
        paddingLeft={{
          base: '8px',
          sm: '0px'
        }}
        paddingRight={{
          base: '8px',
          sm: '0px'
        }}
      >
        <LayoutGrid>
          <GridItem
            colStart={{
              base: 1,
              sm: 2,
              md: 4
            }}
            colEnd={{
              base: 5,
              sm: 8,
              md: 10
            }}
          >
            <Outlet />
          </GridItem>
        </LayoutGrid>
      </Container>
    </>
  )
}
