import React from 'react'
import {
  Box,
  Text,
  Container,
  GridItem,
  Heading,
  useToast
} from '@chakra-ui/react'
import { LayoutGrid, AddCircleIcon } from '@blueprinthq/joy'
import { useOnNavBack, useWebViewStore } from '@hooks'
import { useQueryClient } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'
import { 
  useUsersControllerV1GetNotificationPreferences,
  getUsersControllerV1GetNotificationPreferencesQueryKey,
  useUsersControllerV1DeleteNotificationPreference,
} from '~/api/client'
import { WebViewMessages } from '@types'
import { ConfirmDeleteCheckInReminderDialog } from './components'
import { usePatientProfileStore } from '@core/store'
import { observer } from 'mobx-react-lite'

export const CheckInRemindersView = observer(() => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const webviewStore = useWebViewStore()
  const { activeProfile } = usePatientProfileStore()

  useOnNavBack(() => {
    if (webviewStore.isReactNativeWebView && webviewStore.isUsingOldNav()) {
      webviewStore.postMessage(WebViewMessages.NAVIGATE_SETTINGS)
    } else {
      navigate('/settings')
    }
  })

  const { data = [] } = useUsersControllerV1GetNotificationPreferences(activeProfile.userId, {
    notificationType: 'check_in'
  })

  const { mutate: deleteReminder } =
    useUsersControllerV1DeleteNotificationPreference({
      mutation: {
        onSuccess() {
          toast({
            title: 'Reminder deleted',
            status: 'success',
            duration: 2000,
            isClosable: true
          })
          queryClient.invalidateQueries(
            getUsersControllerV1GetNotificationPreferencesQueryKey(activeProfile.userId, {
              notificationType: 'check_in'
            })
          )
        }
      }
    })

  return (
    <Container
      paddingTop={{
        base: '24px'
      }}
      paddingLeft={{
        base: '8px',
        sm: '0px'
      }}
      paddingRight={{
        base: '8px',
        sm: '0px'
      }}
    >
      <LayoutGrid>
        <GridItem
          colStart={{
            base: 1,
            sm: 2,
            md: 4
          }}
          colEnd={{
            base: 5,
            sm: 8,
            md: 10
          }}
        >
          <Box mb="small">
            <Heading size="lg">Check-In Reminders</Heading>
          </Box>
          <Box borderWidth="1px" rounded="lg">
            <Box borderTopWidth="1px" _first={{ borderTopWidth: 0 }}>
              {data.map(reminder => (
                <Box
                  p={4}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderBottomWidth="1px"
                >
                  <Box>{reminder.daysOfWeek.join(', ')}</Box>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Box>
                      <Text color="dark_gray">
                        {DateTime.fromISO(reminder.timeOfDay).toLocaleString(
                          DateTime.TIME_SIMPLE
                        )}
                      </Text>
                    </Box>
                    <Box ml={5}>
                      <ConfirmDeleteCheckInReminderDialog
                        onDeleteReminder={() => deleteReminder({
                          userId: activeProfile.userId,
                          id: reminder.id 
                        })}
                      />
                    </Box>
                  </Box>
                </Box>
              ))}
              <Box p={4} display="flex" alignItems="center" color="primary">
                <AddCircleIcon fill="primary" />
                <Link to="/settings/check-in-reminders/new">
                  <Text ml={2}>Add reminder</Text>
                </Link>
              </Box>
            </Box>
          </Box>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
})