import React, { useState } from 'react'
import {
  TimeRangeSelector,
  TimeRangeFilter
} from './components/time-range-selector'
import { EmptyChart } from './empty-chart'
import { LoadingChart } from './loading-chart'
import { Heading, Text, Box, useTheme } from '@chakra-ui/react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Text as ChartText
} from 'recharts'
import { SymptomIntervalDto } from '~/api/client/models'

interface SymptomsProps {
  data: SymptomIntervalDto[]
  isLoading: boolean
}

const CustomXAxisTick = ({ x, y, payload }: any) => {
  if (payload && payload.value) {
    const label = payload.value.length >= 40 ? `${payload.value.substr(0, 40)}...` : payload.value
    return (
      <ChartText
        fontSize="12px"
        width="12px"
        x={x}
        y={y}
        textAnchor="middle"
        verticalAnchor="start"
      >
        {label}
      </ChartText>
    )
  }
  return null
}

export const SymptomsChart = ({ data, isLoading }: SymptomsProps) => {
  const [timeRange, setTimeRange] = useState<TimeRangeFilter>(
    TimeRangeFilter.ONE_WEEK
  )

  const theme = useTheme()

  const intervalData = data.find(d => d.timeScale === timeRange)
  if (!intervalData) return null

  const chartData = intervalData.data.slice(0, 3).map(d => ({
    name: d.display,
    value: d.value
  }))

  return (
    <Box w="100%">
      <Heading size="lg">Symptoms</Heading>
      <Text mb={4}>
        Track symptom severity and distribution over time to learn about
        potential indicators.
      </Text>
      <Box mb={4}>
        <TimeRangeSelector
          value={timeRange}
          onChange={val => setTimeRange(val)}
        />
      </Box>
      {isLoading ? (
        <LoadingChart />
      ) : (
        <Box px={6} bg="white" borderRadius={8} h={220}>
          <Text pt={4}>Top Symptoms</Text>
          <Box h="100%">
            {chartData.length > 1 ? (
              <ResponsiveContainer width="100%" height="80%">
                <BarChart
                  width={500}
                  height={160}
                  data={chartData}
                  margin={{
                    top: 17
                  }}
                >
                  <CartesianGrid
                    vertical={false}
                    stroke={theme.colors.pale_gray}
                  />
                  <XAxis
                    dataKey="name"
                    interval={0}
                    height={70}
                    tick={<CustomXAxisTick />}
                    axisLine={{ stroke: theme.colors.pale_gray }}
                  />
                  <YAxis
                    width={40}
                    type="number"
                    domain={[0, 100]}
                    tickFormatter={val =>
                      [0, 100].includes(val) ? `${val}%` : ''
                    }
                    tickLine={false}
                    tick={{ fill: theme.colors.dark_gray }}
                    ticks={[0, 25, 50, 75, 100]}
                    axisLine={{ stroke: theme.colors.pale_gray }}
                    style={{
                      fontSize: '12px'
                    }}
                  />
                  <Bar
                    dataKey="value"
                    fill={theme.colors.primary}
                    barSize={30}
                  />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <EmptyChart message="We need more data to calculate this." />
            )}
          </Box>
        </Box>
      )}
    </Box>
  )
}
