import React from 'react'
import { CircularProgress, Flex, useBreakpointValue } from '@chakra-ui/react'

export interface LoadingProps {
  isFullPage?: boolean
}

export const Loading = ({ isFullPage = false }: LoadingProps) => {

  const size = useBreakpointValue({
    base: '50px',
    md: '100px'
  })
  return (
    <Flex
      align="center"
      justify="center"
      sx={
        isFullPage
          ? {
              position: 'fixed',
              top: '0px',
              left: '0px',
              height: '100vh',
              width: '100vw'
            }
          : {}
      }
    >
      <CircularProgress
        isIndeterminate
        color="primary"
        size={size}
        thickness="7px"
      />
    </Flex>
  )
}
