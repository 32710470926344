import React, { useState } from 'react'
import { Box, VStack, Text } from '@chakra-ui/react'
import { Button, TextField } from '@blueprinthq/joy'
import { Formik, Field, Form, FieldProps } from 'formik'
import { useNavigate, createSearchParams } from 'react-router-dom'
import * as Yup from 'yup'

import { useAuthenticationControllerV1PostForgotPasswordRequestCode } from '~/api/public'

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required')
})

export const ForgotPasswordForm = () => {
  const [confirmError, setConfirmError] = useState<string | null>(null)
  const navigate = useNavigate()

  const { mutate: forgotPasswordRequestCode } =
    useAuthenticationControllerV1PostForgotPasswordRequestCode()

  return (
    <Formik
      validationSchema={ForgotPasswordSchema}
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={{
        email: ''
      }}
      validate={() => setConfirmError(null)}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true)

        await forgotPasswordRequestCode(
          {
            data: {
              userType: 'patient',
              email: values.email
            }
          },
          {
            onSuccess() {
              navigate({
                pathname: '/reset-password',
                search: createSearchParams({
                  email: values.email
                }).toString()
              })
            },
            onError() {
              setConfirmError(
                'Something went wrong. Did you enter the right email?'
              )
            },
            onSettled() {
              actions.setSubmitting(false)
            }
          }
        )
      }}
    >
      {({ errors, touched, isSubmitting, values, isValid, dirty }) => (
        <Form>
          <VStack spacing="small" mb="medium" alignItems="flex-start">
            <Box w="100%">
              <Field name="email">
                {({ field }: FieldProps) => (
                  <TextField
                    {...field}
                    isRequired
                    label="Email"
                    isInvalid={!!(errors.email && touched.email)}
                    errorText={errors.email}
                  />
                )}
              </Field>
            </Box>
            {confirmError && (
              <Text w="100%" color="error">
                {confirmError}
              </Text>
            )}
            <Box w="100%">
              <Button
                disabled={!isValid || !dirty}
                size="lg"
                w="100%"
                isLoading={isSubmitting}
                type="submit"
              >
                Send reset instructions
              </Button>
            </Box>
          </VStack>
        </Form>
      )}
    </Formik>
  )
}
