import React from 'react'
import { Container, GridItem, Box, Heading } from '@chakra-ui/react'
import { LayoutGrid } from '@blueprinthq/joy'

export const AppContainer = ({
  title,
  children
}: {
  title?: string | React.ReactNode
  children: React.ReactNode
}) => {
  const header =
    typeof title === 'string' ? <Heading size="lg">{title}</Heading> : title
  return (
    <Container
      paddingTop={{
        base: '24px'
      }}
      paddingLeft={{
        base: '8px',
        sm: '0px'
      }}
      paddingRight={{
        base: '8px',
        sm: '0px'
      }}
      pb="100px"
    >
      <LayoutGrid>
        <GridItem
          colStart={{
            base: 1,
            sm: 2,
            md: 4
          }}
          colEnd={{
            base: 5,
            sm: 8,
            md: 10
          }}
        >
          {header && <Box mb={6}>{header}</Box>}
          {children}
        </GridItem>
      </LayoutGrid>
    </Container>
  )
}
