import React from 'react'
import { makeAutoObservable } from 'mobx'
import { AccountUserProfileDto } from '~/api/client/models'

export class PatientProfileStore {
  private static globalInstance: PatientProfileStore | null = null

  activeProfile!: AccountUserProfileDto

  constructor() {
    makeAutoObservable(this)
  }

  setActiveProfile(profile: AccountUserProfileDto) {
    this.activeProfile = profile
  }

  static instance() {
    if (this.globalInstance === null) {
      this.globalInstance = new PatientProfileStore()
    }

    return this.globalInstance
  }
}

// @ts-ignore
export const PatientProfileStoreContext = React.createContext<PatientProfileStore>(null)

export const PatientProfileStoreProvider = ({ children }: any) => {
  const store = PatientProfileStore.instance()

  return (
    <PatientProfileStoreContext.Provider value={store}>
      {children}
    </PatientProfileStoreContext.Provider>
  )
}

export const usePatientProfileStore = () => React.useContext(PatientProfileStoreContext)
