// Originally copy-pasted from clinician-web component

import React, { useMemo } from 'react'
import { VStack, Box, Flex, Text } from '@chakra-ui/react'

import { CheckIcon, CloseCircle, CloseIcon } from '@blueprinthq/joy'

import * as Yup from 'yup'
import YupPassword from 'yup-password'
YupPassword(Yup)

// the rules in this library coincide with the DEFAULT_PASSWORD_RULES
const DEFAULT_PASSWORD_SCHEMA = Yup.string().password().required('Required')

const DEFAULT_PASSWORD_RULES = [
  {
    description: 'No spaces',
    regex: /^\S+$/
  },
  {
    description: 'At least one number',
    regex: /.*[0-9]+.*/
  },
  {
    description: 'Uppercase and lowercase',
    regex: /(?=.*[a-z])(?=.*[A-Z])/
  },
  {
    description: 'At least 8 characters',
    regex: /^.{8,}$/
  },
  {
    description: 'At least one special character',
    regex: /[.,!&^%$#@()/_*\]<>{}"~`|'?[\\;:-]/
  }
]

interface PasswordRulesProps {
  rules?: {
    description: string
    regex: RegExp
  }[]
  password: string
}

export const PasswordRules = ({
  rules = DEFAULT_PASSWORD_RULES,
  password
}: PasswordRulesProps) => {
  const validatedRules: { description: string; isValid: boolean }[] =
    useMemo(() => {
      return rules.map(rule => {
        return {
          description: rule.description,
          isValid: Boolean(password.match(rule.regex))
        }
      })
    }, [password, rules])

  return (
    <Box>
      <Text mb="xxsmall">Passwords must have...</Text>
      <VStack spacing="xxsmall" align="flex-start">
        {validatedRules.map((rule, index) => (
          <Flex align="center" key={index}>
            {rule.isValid ? (
              <CheckIcon fill="green" />
            ) : (
              <CloseIcon fill="severe" />
            )}
            <Text ml="xxsmall" fontWeight="bold">
              {rule.description}
            </Text>
          </Flex>
        ))}
      </VStack>
    </Box>
  )
}
