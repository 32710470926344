import React, { useEffect } from 'react'
import { Heading, Box, Text, Link } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

import { useWebViewStore } from '@hooks'
import { WebViewMessages } from '@types'
import { LoginForm } from './components'

export const LoginView = () => {
  const webviewStore = useWebViewStore()

  useEffect(() => {
    if (webviewStore.isReactNativeWebView) {
      webviewStore.postMessage(WebViewMessages.ACTION_LOGOUT)
    }
  }, [])
  return (
    <Box w="100%">
      <Heading size="lg" mb="medium">
        Log in to Blueprint
      </Heading>
      <LoginForm />
      <Box>
        <Text>Are you a client logging in for the first time?</Text>
        <Link to="/accept-invite" color="primary" as={RouterLink}>
          Create your account
        </Link>
      </Box>
    </Box>
  )
}
