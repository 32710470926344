import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { AssessmentFormContext } from '@types'


export const useIsFromMagicLink = (): boolean => {
  const [searchParams] = useSearchParams()
  const isFromMagicLink = useMemo(() => {
  
    if (searchParams.has('context')) {
      const ctx = searchParams.get('context') as string
      return (
        ctx === AssessmentFormContext.SMS ||
        ctx === AssessmentFormContext.EMAIL ||
        ctx === AssessmentFormContext.MANUAL_EMAIL ||
        ctx === AssessmentFormContext.MANUAL_SMS
      )
    }
    return false
  }, [searchParams])

  return isFromMagicLink
}
