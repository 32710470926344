export enum AssessmentFormContext {
  CLINICIAN_APP = 'clinician_app',
  KIOSK = 'kiosk',
  SMS = 'sms',
  EMAIL = 'email',
  MANUAL_SMS = 'manual_sms',
  MANUAL_EMAIL = 'manual_email',
  DEFAULT = 'patient_app'
}

export enum AssignedAssessmentFlowStatus {
  LOADING = 'LOADING',
  CONSENT_REQURIED = 'CONSENT_REQUIRED',
  BIRTHDAY_CONFIRMATION_REQUIRED = 'BIRTHDAY_CONFIRMATION_REQUIRED',
  SUBMITTED = 'SUBMITTED',
  ASSIGNED_NO_TAKEABLE = 'ASSIGNED_NO_TAKEABLE',
  ASSIGNED_TAKEABLE = 'ASSIGNED_TAKEABLE',
  NONE_ASSIGNED = 'NONE_ASSIGNED'
}

export enum WebViewMessages {
  NAVIGATE_LOGIN = '@navigate/login',
  NAVIGATE_SETTINGS = '@navigate/settings',
  NAVIGATE_PROFILE = '@navigate/profile',
  ACTION_LOGOUT = '@action/logout',
  ACTION_LOGIN = '@action/login',
  ACTION_AUTH_INVALID = '@action/auth-invalid',
  ACTION_AUTH_SUCCESS = '@action/auth-success'
}

export enum KioskFlow {
  CLINIC = 'clinic',
  CLINICIAN = 'clinician'
}

export enum FlagsmithFeature {
  ACCEPT_INVITE_FLOW = 'accept_invite_flow',
  ASSESSMENT_BIRTHDAY_CONFIRMATION = 'assessment_birthday_confirmation',
}

export enum ClientEnrollStatus {
  NO_OP = 'NO_OP',
  ENROLL_NEW_PATIENT = 'ENROLL_NEW_PATIENT'
}

export enum OptionalComms {
  SCHEDULED_ASSESSMENT_ADMINISTRATION = 'SCHEDULED_ASSESSMENT_ADMINISTRATION',
  SCHEDULED_CHECKIN_REMINDER = 'SCHEDULED_CHECKIN_REMINDER',
  CLIENT_SAFETY_NET = 'CLIENT_SAFETY_NET',
}
