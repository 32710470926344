import React, { useEffect, useMemo } from 'react'
import {
  Button,
  Box,
  Heading,
  VStack
} from '@chakra-ui/react'
import { StickyFooter } from '@components'
import * as Yup from 'yup'
import { Formik, Form } from 'formik';

import {
  CheckInDtoV2,
  CheckInMultipleChoiceNode
} from '~/api/client/models'
import { useWebViewStore } from '@hooks'
import { LifestyleCorrelates } from './components/lifestyle-correlates';

import { CheckInFormField } from './components/check-in-fields'
interface CheckInFormProps {
  checkIn: CheckInDtoV2
  onSubmit?: (checkInId: string, result: Record<string, any>) => void
  isPreview?: boolean
  isDisabled?: boolean
  answers?: Record<string, string>
}

export const CheckInForm = ({ 
  checkIn, 
  onSubmit,
  isPreview,
  isDisabled,
  answers,
}: CheckInFormProps) => {
  const webViewStore = useWebViewStore()

  const schema = useMemo(() => {
    const questionSchema = checkIn.content.nodes.filter((n) => n.type !== 'heading').reduce((result: Record<string, any>, node) => {
      let schema
      if (node.type === 'free_text') {
        schema = Yup.string()
      } else if (node.type === 'likert') {
        schema = Yup.string()
      } else if (node.type === 'multiple_choice') {
        schema = Yup.array()
      } else if (node.type === 'slider_vertical') {
        schema = Yup.string()
      }

      schema = schema?.nullable().notRequired()

      result[node.id] = schema

      return result

    }, {})

    return Yup.object().shape(questionSchema)
  }, [checkIn.id, checkIn.versionId])

  const initialValues = useMemo(() => {
    return answers || checkIn.content.nodes.filter((n) => n.type !== 'heading').reduce((result: Record<string, any>, node) => {
      result[node.id] = (node.type === 'multiple_choice' && (node as CheckInMultipleChoiceNode).multiselect) ? [] : null
      return result
    }, {})
  }, [answers, checkIn.id])
  
  return (
    <Box w="100%" height="100%" sx={{
      // Fix issue with Safari mobile browser where the web URL bar expands over the content of the page
      '@supports (-webkit-touch-callout: none)': {
        paddingBottom: webViewStore.isReactNativeWebView ? '0px' : '70px',
      }
    }}>
      {checkIn.title !== 'Lifestyle correlates' && (<Box>
        <Heading size="lg" mb={4}>
          {checkIn.title}
        </Heading>
      </Box>)
      }
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={async (values) => {
          if (onSubmit) {
            onSubmit(checkIn.id, values);
          }
        }}
      >
        {({ isSubmitting, values, setFieldValue, submitForm, setValues }) => (
          <Form style={{ height: '100%'}}>
            {checkIn.title === 'Lifestyle correlates' ? <LifestyleCorrelates
                  checkIn={checkIn}
                  onChange={(nodeId, value) => {
                    setFieldValue(nodeId, value)
                  }}
                  answers={values}
                  onSubmit={submitForm}
               /> : (
                <>
                  <VStack spacing="medium" pb="128px" align="flex-start" height="100%">
                    {function renderFields(): any {
                      let questionNum = 0
                      return checkIn.content.nodes.map((node, i) => {
                        if (node.type !== 'heading') {
                          questionNum += 1
                        }
                        return (
                          <CheckInFormField 
                            node={node}
                            number={questionNum}
                            isDisabled={isDisabled}
                          />
                        )
                      })
                    }()}
                  </VStack>
                  <StickyFooter>
                    <Button
                      isDisabled={isPreview}
                      size="lg"
                      w="100%"
                      isLoading={isSubmitting}
                      type="submit"
                    >
                      {isPreview ? 'Preview Only' : 'Submit'}
                    </Button>
                  </StickyFooter>
                </>
               )}
          </Form>
        )}
      </Formik>
    </Box>
  )
}