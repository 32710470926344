import React from 'react'
import {
  Box,
  Text,
  VStack,
} from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import gPhoneNumber from 'google-libphonenumber'

const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance()
const PNF = gPhoneNumber.PhoneNumberFormat

export function formatPhone(phone: string | undefined): string {
  if (phone) {
    const number = phoneUtil.parse(phone, 'US')
    const phoneNumber = phoneUtil.format(number, PNF.NATIONAL)
    return phoneNumber
  } else {
    return ''
  }
}

export function renderErrorMessage (errorType: string): any {
  switch (errorType) {
    case 'no-active-invite-found':
      return (
        <VStack spacing='small' align="left">
          <Text>
            No active invite found. Try both your Phone and Email.
          </Text>
          <Box>
            <Text as="span">
              {'If you already have an account, try to '}
            </Text>
            <Text as={NavLink} textDecoration="underline" to="/login">
              log in 
            </Text>
            <Text as="span">
              {' or '} 
            </Text>
            <Text as={NavLink} textDecoration="underline" to="/forgot-password">
              reset your password
            </Text>
            <Text as="span">
              .
            </Text>
          </Box>
          <Text>
            If you are still unable to access your account, please reach out to your clinician or Blueprint support for help.
          </Text>
        </VStack>
      )
    case 'ambiguous-invite-found':
      return (
        <Text>
          There was an issue finding your account. Try using email instead or please reach out to your clinician and/or Blueprint support for help.
        </Text>
      )
  
    default:
      return <Text>Error</Text>
  }
}
