import React from 'react'
import { LoadingChart } from './loading-chart'
import { Link } from 'react-router-dom'
import { Heading, Text, Box } from '@chakra-ui/react'
import { Button } from '@blueprinthq/joy'
import Slider from 'react-slick'

import { usePatientProfileStore } from '@core/store'

import { EmptyChart } from './empty-chart'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { useUsersControllerV1GetInsightsWorksheetSummary } from '~/api/client'

export const CompletedWorksheets = () => {
  const { activeProfile } = usePatientProfileStore()

  const { data = [], isLoading } = useUsersControllerV1GetInsightsWorksheetSummary(activeProfile.userId)
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <Box w="100%">
      <Box>
        <Heading size="lg">Worksheets</Heading>
      </Box>
      <Box mb={4}>
        <Text>
          Complete treatment monitoring worksheets that your clinician has
          assigned to you.
        </Text>
      </Box>
      {isLoading ? (
        <LoadingChart />
      ) : (
        <Box px={6} bg="white" borderRadius={8}>
          {data.length ? (
            <Slider {...settings}>
              {data.map(summary => {
                return (
                  <Link
                    to={`/check-ins/${summary.id}/completed`}
                    key={summary.id}
                  >
                    <Box key={summary.id} pt={4} pb={10} px={6} h="220px">
                      <Box
                        h="100%"
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                      >
                        <Box>
                          <Text mb={2}>{summary.title}</Text>
                          <Box color="primary">
                            <Heading display="inline">
                              {summary.count}
                            </Heading>{' '}
                            Completed
                          </Box>
                        </Box>
                        <Box>
                          <Text>Tap card to view latest results</Text>
                        </Box>
                      </Box>
                    </Box>
                  </Link>
                )
              })}
            </Slider>
          ) : (
            <EmptyChart message="No worksheets completed.">
              <Button as={Link} to="/check-ins" isFullWidth>
                Check in
              </Button>
            </EmptyChart>
          )}
        </Box>
      )}
    </Box>
  )
}
