import React, { useState, useMemo } from 'react'
import {
  Container,
  GridItem,
  Box,
  Heading,
  Text,
  Input,
  Button,
  useToast
} from '@chakra-ui/react'
import { DateTime } from 'luxon'
import { LayoutGrid } from '@blueprinthq/joy'
import { useNavigate } from 'react-router-dom'
import { useOnNavBack } from '@hooks'
import { useUsersControllerV1CreateNotificationPreference } from '~/api/client'
import { DayOfWeekSelector } from '@features'
import { usePatientProfileStore } from '@core/store'
import { observer } from 'mobx-react-lite'

export const AddCheckInReminderView = observer(() => {
  const [selectedDays, setSelectedDays] = useState<string[]>([])
  const [timeOfDay, setTimeOfDay] = useState('09:00')
  const { activeProfile } = usePatientProfileStore()

  const navigate = useNavigate()
  const toast = useToast()

  useOnNavBack(() => navigate('/settings/notifications'))

  const { mutate: submit } = useUsersControllerV1CreateNotificationPreference({
    mutation: {
      onSuccess() {
        navigate('/settings/notifications')
        toast({
          title: 'Reminder created',
          status: 'success',
          duration: 2000,
          isClosable: true
        })
      }
    }
  })

  const handleSelectedDays = (days: string[]) => {
    setSelectedDays(days)
  }

  const handleSubmit = () => {
    submit({
      userId: activeProfile.userId,
      data: {
        daysOfWeek: selectedDays,
        timeOfDay: DateTime.fromISO(timeOfDay).toString(),
        notificationType: 'check_in'
      }
    })
  }

  return (
    <Container
      paddingTop={{
        base: '24px'
      }}
      paddingLeft={{
        base: '8px',
        sm: '0px'
      }}
      paddingRight={{
        base: '8px',
        sm: '0px'
      }}
    >
      <LayoutGrid>
        <GridItem
          colStart={{
            base: 1,
            sm: 2,
            md: 4
          }}
          colEnd={{
            base: 5,
            sm: 8,
            md: 10
          }}
        >
          <Box mb="small">
            <Heading size="lg">Add Reminder</Heading>
          </Box>
          <Box mb="small">
            <Text fontWeight="bold">Remind me on these days</Text>
          </Box>
          <Box mb="large">
            <DayOfWeekSelector
              selectedDays={selectedDays}
              onChange={handleSelectedDays}
              showEveryDayOption
            />
          </Box>
          <Box mb="small">
            <Text fontWeight="bold">Remind me at this time</Text>
          </Box>
          <Box mb="small">
            <Input
              type="time"
              value={timeOfDay}
              onChange={e => setTimeOfDay(e.target.value)}
            />
          </Box>
          <Box>
            <Button
              onClick={handleSubmit}
              isFullWidth
              isDisabled={selectedDays.length === 0}
            >
              Save
            </Button>
          </Box>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
})