import React, { useState, useCallback } from 'react'
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom'
import { Box, Text, VStack, Radio, RadioGroup, HStack } from '@chakra-ui/react'
import { Button, TextField } from '@blueprinthq/joy'
import {
  Field,
  Form,
  Formik,
  FieldProps,
  FieldInputProps,
  FormikProps
} from 'formik'
import { DateTime } from 'luxon'
import * as Yup from 'yup'
import InputMask from 'react-input-mask'

import { AssessmentFormContext } from '@types'
import { 
  useKioskControllerV1PostIdentifyPatientFromAttributes,
} from '~/api/public'
import { KioskIdentifyPatientDto } from '~/api/public/models'

interface KioskClinicFormProps {
  clinicDisplayId: string
}

enum FormFlow {
  NAME_DOB = 'name_dob',
  MRN = 'mrn'
}

enum PreferredExtraField {
  PHONE = 'phone',
  EMAIL = 'email'
}

const KioskIdentificationFormValidationSchema = Yup.object({
  flow: Yup.string().required(),
  mrn: Yup.string().when('flow', {
    is: (flow: string) => flow === FormFlow.MRN,
    then: schema => schema.required('* Required')
  }),
  lastName: Yup.string().when('flow', {
    is: (flow: string) => flow === FormFlow.NAME_DOB,
    then: schema => schema.required('* Required')
  }),
  dateOfBirth: Yup.date()
    .max(DateTime.now(), "You can't be born in the future!")
    .min(DateTime.now().minus({ years: 130 }), 'Year is too far in the past.')
    .typeError('Please enter a valid date of birth')
    .when('flow', {
      is: (flow: string) => flow === FormFlow.NAME_DOB,
      then: schema => schema.required('* Required')
    }),
  showExtraFields: Yup.boolean(),
  preferredExtraField: Yup.string(),
  phoneNumber: Yup.string()
    // @ts-ignore
    .phone('US', false, 'Must be a valid phone number.')
    .when(['showExtraFields', 'preferredExtraField'], {
      is: (showExtraFields: boolean, preferredExtraField: string) => {
        return (
          showExtraFields && preferredExtraField === PreferredExtraField.PHONE
        )
      },
      then: (schema: any) => {
        return schema.required('* Required')
      }
    }),
  email: Yup.string()
    .email('Please enter a valid email')
    .when(['showExtraFields', 'preferredExtraField'], {
      is: (showExtraFields: boolean, preferredExtraField: string) => {
        return (
          showExtraFields && preferredExtraField === PreferredExtraField.EMAIL
        )
      },
      then: (schema: any) => {
        return schema.required('* Required')
      }
    })
})

export const KioskIdentificationForm = ({
  clinicDisplayId
}: KioskClinicFormProps) => {
  const [displayError, setDisplayError] = useState<undefined | string>(
    undefined
  )
  const { mutate: identifyClientFromAttributes } =
    useKioskControllerV1PostIdentifyPatientFromAttributes()

  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Formik
      initialValues={{
        flow: FormFlow.NAME_DOB, // default to name/dob flow
        mrn: '',
        lastName: '',
        dateOfBirth: '',
        phoneNumber: '',
        email: '',
        preferredExtraField: PreferredExtraField.PHONE, // default to phone
        showExtraFields: false
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={KioskIdentificationFormValidationSchema}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true)

        const commonFields = {
          clinicDisplayId,
          phoneNumber:
            values.showExtraFields &&
            values.preferredExtraField === PreferredExtraField.PHONE
              ? values.phoneNumber
              : null,
          email:
            values.showExtraFields &&
            values.preferredExtraField === PreferredExtraField.EMAIL
              ? values.email
              : null
        }

        function onSuccess(data: KioskIdentifyPatientDto) {
          if (data.status === 'more_info_needed') {

            if (values.showExtraFields) {
              setDisplayError('There was an issue finding your account. Try using email or MRN instead. If you need help, ask your clinician.')
            } else {
              actions.setFieldValue('showExtraFields', true)
              setDisplayError(undefined)
            }

          } else if (data.status === 'match_found') {
            navigate({
              pathname: `/user/${data.userId}/assessments`,
              search: createSearchParams({
                context: AssessmentFormContext.KIOSK,
                homescreenUrl: location.pathname
              }).toString()
            })
          } else {
            setDisplayError(
              'No account found that matches the provided info. If you need help, ask your clinician.'
            )
          }
        }

        if (values.flow === FormFlow.MRN) {
          await identifyClientFromAttributes(
            {
              data: {
                medicalRecord: values.mrn,
                ...commonFields
              }
            },
            {
              onSuccess,
              onError(error) {
                if (error.response?.data.error.message) {
                  setDisplayError(error.response?.data.error.message)
                }
              }
            }
          )
        } else {
          await identifyClientFromAttributes(
            {
              data: {
                lastName: values.lastName,
                dateOfBirth: values.dateOfBirth,
                ...commonFields
              }
            },
            {
              onSuccess,
              onError(error) {
                if (error.response?.data.error.message) {
                  setDisplayError(error.response?.data.error.message)
                }
              }
            }
          )
        }
      }}
    >
      {({ errors, touched, isSubmitting, values, submitForm }) => {
        const onPrimaryFieldChange =
          (
            e: React.ChangeEvent<HTMLInputElement>,
            field: FieldInputProps<any>,
            form: FormikProps<any>,
          ) => {
            field.onChange(e)
            if (values.showExtraFields) {
              form.setFieldValue('email', '')
              form.setFieldValue('phoneNumber', '')
              form.setFieldValue('showExtraFields', false)
              form.setFieldValue(
                'preferredExtraField',
                PreferredExtraField.PHONE
              ) // reset back to default
              setDisplayError(undefined)
            }
          }

        return (
          <Form>
            <VStack spacing="medium" align="flex-start">
              <Field name="flow">
                {({ field, form }: FieldProps) => (
                  <RadioGroup
                    {...field}
                    onChange={val => {
                      form.resetForm({})
                      setDisplayError(undefined)
                      form.setFieldValue(field.name, val)
                    }}
                  >
                    <HStack spacing="medium">
                      <Radio value={FormFlow.NAME_DOB}>Name & DOB</Radio>
                      <Radio value={FormFlow.MRN}>MRN</Radio>
                    </HStack>
                  </RadioGroup>
                )}
              </Field>

              {values.flow === FormFlow.MRN && (
                <Box
                  w="100%"
                  pb={errors.mrn && touched.mrn ? 'small' : 'initial'}
                >
                  <Field name="mrn">
                    {({ field, form }: FieldProps) => (
                      <TextField
                        {...field}
                        onChange={e => onPrimaryFieldChange(e, field, form)}
                        autoFocus
                        label="Medical Record Number"
                        isRequired
                        isInvalid={!!(errors.mrn && touched.mrn)}
                        errorText={errors.mrn}
                      />
                    )}
                  </Field>
                </Box>
              )}

              {values.flow === FormFlow.NAME_DOB && (
                <>
                  <Box
                    w="100%"
                    pb={
                      errors.lastName && touched.lastName ? 'small' : 'initial'
                    }
                  >
                    <Field name="lastName">
                      {({ field, form }: FieldProps) => (
                        <TextField
                          {...field}
                          onChange={e => onPrimaryFieldChange(e, field, form)}
                          autoFocus
                          label="Last name"
                          isRequired
                          isInvalid={!!(errors.lastName && touched.lastName)}
                          errorText={
                            touched.lastName && errors.lastName
                              ? errors.lastName
                              : undefined
                          }
                        />
                      )}
                    </Field>
                  </Box>
                  <Box
                    w="100%"
                    pb={
                      errors.dateOfBirth && touched.dateOfBirth
                        ? 'small'
                        : 'initial'
                    }
                  >
                    <Field name="dateOfBirth">
                      {({ field, form }: FieldProps) => (
                        <InputMask
                          mask={'99/99/9999'}
                          {...field}
                          onChange={e => onPrimaryFieldChange(e, field, form)}
                        >
                          {(inputProps: any) => (
                            <TextField
                              {...inputProps}
                              label="Date of Birth"
                              isRequired
                              isInvalid={
                                !!(errors.dateOfBirth && touched.dateOfBirth)
                              }
                              errorText={errors.dateOfBirth}
                            />
                          )}
                        </InputMask>
                      )}
                    </Field>
                  </Box>
                </>
              )}

              {values.showExtraFields && (
                <>
                  <Field name="preferredExtraField">
                    {({ field, form }: FieldProps) => (
                      <Box>
                        <Box mb="small">
                          <Text fontWeight="bold">We need more info</Text>
                          <Text>
                            Please enter a phone or email to further identify
                            your account.
                          </Text>
                        </Box>
                        <RadioGroup
                          {...field}
                          onChange={val => {
                            // clear other value on change
                            if (val === PreferredExtraField.PHONE) {
                              form.setFieldValue('email', '')
                              form.setFieldError('email', undefined)
                            } else {
                              form.setFieldValue('phoneNumber', '')
                              form.setFieldError('phoneNumber', undefined)
                            }
                            form.setFieldValue(field.name, val)
                            setDisplayError(undefined)
                          }}
                        >
                          <HStack>
                            <Radio value={PreferredExtraField.PHONE}>
                              Phone
                            </Radio>
                            <Radio value={PreferredExtraField.EMAIL}>
                              Email
                            </Radio>
                          </HStack>
                        </RadioGroup>
                      </Box>
                    )}
                  </Field>
                  {values.preferredExtraField === PreferredExtraField.PHONE && (
                    <Box
                      w="100%"
                      pb={
                        errors.phoneNumber && touched.phoneNumber
                          ? 'small'
                          : 'initial'
                      }
                    >
                      <Field name="phoneNumber">
                        {({ field }: FieldProps) => (
                          <InputMask mask="(999) 999-9999" {...field}>
                            {() => (
                              <TextField
                                {...field}
                                autoFocus
                                label="Phone number"
                                type="tel"
                                isRequired
                                isInvalid={
                                  !!(errors.phoneNumber && touched.phoneNumber)
                                }
                                errorText={errors.phoneNumber}
                              />
                            )}
                          </InputMask>
                        )}
                      </Field>
                    </Box>
                  )}
                  {values.preferredExtraField === PreferredExtraField.EMAIL && (
                    <Box
                      w="100%"
                      pb={errors.email && touched.email ? 'small' : 'initial'}
                    >
                      <Field name="email">
                        {({ field }: FieldProps) => (
                          <TextField
                            {...field}
                            autoFocus
                            type="email"
                            label="Email"
                            isRequired
                            isInvalid={!!(errors.email && touched.email)}
                            errorText={errors.email}
                          />
                        )}
                      </Field>
                    </Box>
                  )}
                </>
              )}
            </VStack>
            {displayError && (
              <Text w="100%" mt="medium" color="error" align="left">
                {displayError}
              </Text>
            )}
            <Box w="100%">
              <Button
                mt="large"
                size="lg"
                w="100%"
                isLoading={isSubmitting}
                type="submit"
                onClick={() => submitForm()}
              >
                Next
              </Button>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}
