import React from 'react'
import { Flex, Image, ImageProps } from '@chakra-ui/react'

export const Logo = (props: ImageProps) => {
  return (
    <Flex sx={{
      width: '160px',
      height: '32px'
    }} justify="center" align="center">
      <Image
        loading="eager"
        objectFit="contain"
        maxWidth="160px"
        maxHeight="32px"
        {...props}
      />
    </Flex>
  )
}