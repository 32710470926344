import React from 'react'
import { Flex, Box, Text } from '@chakra-ui/react'
import { CheckIcon } from '@blueprinthq/joy'

interface CompletedFormProps {
  children?: JSX.Element | null,
  text?: string
}

export const CompletedForm = ({
  children,
  text
}: CompletedFormProps) => {
  return (
    <Flex align="center" direction="column" paddingTop="small">
      <Flex
        bg="primary"
        width="64px"
        height="64px"
        borderRadius="50%"
        align="center"
        justify="center"
      >
        <CheckIcon size="lg" fill="white" />
      </Flex>
      <Box mt="large">
        <Text textAlign="center" fontWeight="bold" fontSize="lg">
          {text}
        </Text>
      </Box>
      {children}
    </Flex>
  )
}
