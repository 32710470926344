import React from 'react'
import { Box, Text, Container, GridItem, Switch } from '@chakra-ui/react'
import { LayoutGrid, ArrowRightIcon } from '@blueprinthq/joy'

export const PermissionsView = () => {
  return (
    <Container>
      <LayoutGrid>
        <GridItem
          colStart={{
            base: 1,
            sm: 1,
            md: 4
          }}
          colEnd={{
            base: 5,
            sm: 9,
            md: 10
          }}
        >
          <Box borderWidth="1px" rounded="lg" mt={8}>
            <Box borderTopWidth="1px" _first={{ borderTopWidth: 0 }}>
              <Box
                p={4}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Text fontWeight="bold">Permissions</Text>
                </Box>
              </Box>
              <Box
                p={4}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottomWidth="1px"
              >
                <Box>Face ID</Box>
                <Box>
                  <Switch size="lg" />
                </Box>
              </Box>
              <Box
                p={4}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottomWidth="1px"
              >
                <Box>Healthkit</Box>
                <Box>
                  <ArrowRightIcon size="md" />
                </Box>
              </Box>
            </Box>
          </Box>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
}
