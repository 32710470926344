import React from 'react'
import { Outlet, useParams } from 'react-router-dom'

import { Container, GridItem } from '@chakra-ui/react'
import { LayoutGrid } from '@blueprinthq/joy'
import { useKioskControllerV1GetClinic } from '~/api/public'

import { KioskAppBar } from './components'

export const PublicKioskLayout = () => {
  const { clinicDisplayId } = useParams()
  const { data: clinic } = useKioskControllerV1GetClinic(
    (clinicDisplayId && encodeURIComponent(clinicDisplayId)) || '',
    {
      query: {
        enabled: !!clinicDisplayId
      }
    }
  )

  return (
    <>
      <KioskAppBar logoUrl={clinic?.logoUrl as string | undefined} />
      <Container
        marginTop={{
          base: '40px'
        }}
        paddingLeft={{
          base: '16px',
          sm: '0px'
        }}
        paddingRight={{
          base: '16px',
          sm: '0px'
        }}
      >
        <LayoutGrid>
          <GridItem
            colSpan={{
              base: 4,
              sm: 6,
              md: 6,
              lg: 4
            }}
            colStart={{
              sm: 2,
              md: 4,
              lg: 5
            }}
          >
            <Outlet />
          </GridItem>
        </LayoutGrid>
      </Container>
    </>
  )
}
