import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { datadogLogs } from '@datadog/browser-logs'

import { AppBar } from '@features'
import { useAuthStore } from '@core/hooks'
import { Loading } from '@components'

export interface AuthWallProps {
  unauthedRedirect: string
}

export const AuthWall = observer<AuthWallProps>(
  ({ children, unauthedRedirect }) => {
    const authStore = useAuthStore()
    const navigate = useNavigate()

    useEffect(() => {
      async function run() {
        if (!authStore.isAuthenticated) {
          try {
            // Attempt to resume session if unauthed, will resume if cookies are still present.
            await authStore.resumeSession()
          } catch (error) {
            datadogLogs.logger.warn('Failed auth wall check', { error })
            // Reroute to login page if cognito cookies are not present or expired.
            navigate(unauthedRedirect)
          }
        }
      }
      run()
    }, [authStore.isAuthenticated])

    if (!authStore.isAuthenticated) {
      return (
        <>
          <AppBar isLoading />
          <Loading isFullPage />
        </>
      )
    }

    return <>{children}</>
  }
)
