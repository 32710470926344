import React from 'react'
import { Link, Flex, Box, Text, VStack, StackDivider } from '@chakra-ui/react'

import { Resource } from './safety-net'
import {
  CallFilledIcon,
  TextFilledIcon,
  ArrowRightIcon,
  LinkIcon
} from '@blueprinthq/joy'

const ResourceCard = ({ resource }: { resource: Resource }) => {
  const { displayText, type, value } = resource

  const handleResourceType = (type: string, value: string) => {
    switch (type.toLowerCase()) {
      case 'call':
        return {
          href: `tel:${value}`,
          image: <CallFilledIcon fill="white" />,
          displayDescription: (
            <>
              <Text as="span">{'Call: '}</Text>
              <Text as="span" textDecoration="underline">
                {value}
              </Text>
            </>
          )
        }
      case 'sms':
        return {
          href: `sms:${value}`,
          image: <TextFilledIcon fill="white" />,
          displayDescription: (
            <>
              <Text as="span">{'Text: '}</Text>
              <Text as="span" textDecoration="underline">
                {value}
              </Text>
            </>
          )
        }
      case 'web':
        const isSafetyPlan = displayText === 'Safety Plan'
        return {
          href: isSafetyPlan ? `${window.location.origin}${value}` : value,
          image: <LinkIcon fill="white" />,
          displayDescription: (
            <>
              <Text as="span">{'Web: '}</Text>
              <Text as="span" textDecoration="underline">
                {isSafetyPlan ? 'View' : value}
              </Text>
            </>
          )
        }
      default:
        return {}
    }
  }

  const { href, image, displayDescription } = handleResourceType(type, value)

  if (!href) return null

  return (
    <Link
      href={href}
      w="100%"
      _hover={{
        textDecoration: 'none'
      }}
    >
      <Flex w="100%" padding="small" alignItems="center">
        <Box
          p={2}
          bgColor="primary"
          borderRadius={8}
          mr="small"
          minWidth="40px"
          minHeight="40px"
        >
          {image}
        </Box>
        <Box width="100%">
          <Text fontWeight="bold">{displayText}</Text>
          <Text wordBreak="break-all">{displayDescription}</Text>
        </Box>
        <Box>
          <ArrowRightIcon size="md" />
        </Box>
      </Flex>
    </Link>
  )
}

export const ResourceCards = ({ resources }: { resources: Resource[] | undefined}) => {
  return (
    <VStack
      border="1px solid"
      borderColor="pale_gray"
      spacing="0"
      mt="medium"
      mb="medium"
      w="100%"
      borderRadius="8px"
      divider={
        <StackDivider
          borderBottom="1px solid"
          borderBottomColor="pale_gray"
        />
      }
    >
      {resources?.map((resource, i) => (
        <ResourceCard resource={resource} key={i} />
      ))}
    </VStack>
  )
}
