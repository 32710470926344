import React from 'react'
import { Flex, SimpleGrid } from '@chakra-ui/react'
import { AppBar as JoyAppBar, Button } from '@blueprinthq/joy'
import { useNavigate } from 'react-router-dom'
import { useFlags } from 'flagsmith/react'

import WhiteLogo from '../../../assets/svgs/white-logo.svg'
import { Logo } from '@components'
import { FlagsmithFeature } from '@types'

interface KioskAppBarProps {
  logoUrl?: string | null | undefined;
}
export const KioskAppBar = ({ logoUrl }: KioskAppBarProps) => {
  const navigate = useNavigate()

  return (
    <JoyAppBar>
      <SimpleGrid
        sx={{
          w: '100%'
        }}
        columns={3}
      >
        <Flex
          gridColumnStart={1}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Button
            variant="ghost"
            color="white"
            m="0px"
            onClick={() => {
              navigate(0)
            }}
          >
            Restart
          </Button>
        </Flex>
        <Flex gridColumnStart={2} justifyContent="center" alignItems="center">
          <Logo src={(logoUrl) || WhiteLogo} />
        </Flex>
      </SimpleGrid>
    </JoyAppBar>
  )
}
