import React from 'react'
import { Hidden, AppBar as JoyAppBar } from '@blueprinthq/joy'

import { AppBarDesktopView } from './desktop-view'
import { AppBarMobileView } from './mobile-view'

interface AppBarProps {
  isLoading?: boolean;
}

export const AppBar = ({
  isLoading
}: AppBarProps) => {

  return (
    <JoyAppBar backgroundColor="primary">
      {isLoading ? null : (
        <>
          {/* @ts-ignore */}
          <Hidden sm>
            <AppBarDesktopView />
          </Hidden>
          {/* @ts-ignore */}
          <Hidden md lg xl>
            <AppBarMobileView />
          </Hidden>
        </>
      )}
    </JoyAppBar>
  )
}
