import React, { useState } from 'react'
import {
  Box,
  Heading,
  Text,
  VStack,
  useToast
} from '@chakra-ui/react'
import {
  Field,
  Form,
  Formik,
  FieldProps,
} from 'formik'
import * as Yup from 'yup'
import { Button, TextField } from '@blueprinthq/joy'
import { useNavigate, createSearchParams } from 'react-router-dom'

import { PasswordRules } from '@components/password-rules'
import { usePublicOnboardingControllerPostSetupAccount } from '~/api/public'
import { useAuthStore, useWebViewStore } from '@core/hooks'
import { WebViewMessages } from '@types'

const SetupAccountSchema = Yup.object({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().password().required('Required'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password')],
    'Both passwords must match.'
  )
})

interface SetupAccountInviteFormProps {
  email: string | null
  patientId: string
}

export const SetupAccountInviteForm = ({
  email,
  patientId,
}: SetupAccountInviteFormProps) => {
  const [displayError, setDisplayError] = useState<undefined | string>(
    undefined
  )
  const authStore = useAuthStore()
  const webviewStore = useWebViewStore()
  const navigate = useNavigate()
  const toast = useToast()

  const { mutate: postSetupAccount } = usePublicOnboardingControllerPostSetupAccount()

  return (
    <Box w="100%">
      <Box>
        <Heading size="lg" mb="xsmall">
          Create your account
        </Heading>
        <Text>
          Create an account to view your treatment progress and complete daily check-in’s.
        </Text>
      </Box>
      <Box mt="medium">
        <Formik
          isInitialValid={true}
          validationSchema={SetupAccountSchema}
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={{
            email: email ? email : '',
            password: '',
            confirmPassword: ''
          }}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true)

            postSetupAccount(
              {
                data: {
                  patientId,
                  email: values.email,
                  password: values.password,
                }
              },
              {
                onSuccess: async (data) => {
                  if (data.status === 'failed') {
                    setDisplayError('Your account could not be created. Try to login instead or please reach out to your clinician or Blueprint support for help.')
                    actions.setSubmitting(false)
                  } else {
                    // Auto-login user
                    toast({
                      title: 'Your account has been created!',
                      duration: 2000,
                      isClosable: true
                    })
                    if (authStore.isAuthenticated) {
                      await authStore.logout(true, {
                        clearLogo: false
                      })
                    }
                    actions.setSubmitting(false)
                    if (webviewStore.isReactNativeWebView) { 
                      webviewStore.postRawMessage({
                        action: WebViewMessages.ACTION_LOGIN,
                        username: values.email,
                        password: values.password,
                      })
                    } else {
                      await authStore.login(values.email, values.password)
                      navigate({
                        pathname: '/app-invite',
                        search: createSearchParams({
                          isInviteFlow: 'true',
                        }).toString()
                      })
                    }
                  }
                },
                onError: error => {
                  setDisplayError(error.response?.data.error.message)
                  actions.setSubmitting(false)
                }
              }
            )
          }}
        >
          {({ errors, touched, values, isSubmitting }) => {
            return (
              <Form>
                <VStack spacing="small" mb="small" >
                  <Box
                    w="100%"
                    pb={errors.email && touched.email ? 'small' : 'initial'}
                  >
                    <Field name="email">
                      {({ field }: FieldProps) => (
                        <TextField
                          {...field}
                          isDisabled={!!email}
                          type="email"
                          label="Email"
                          autoFocus={!email}
                          isRequired
                          isInvalid={!!(errors.email && touched.email)}
                          errorText={errors.email}
                        />
                      )}
                    </Field>
                  </Box>
                  <Box
                    w="100%"
                    pb={
                      errors.password && touched.password ? 'small' : 'initial'
                    }
                  >
                    <Field name="password">
                      {({ field }: FieldProps) => (
                        <>
                          <TextField
                            {...field}
                            autoFocus={!!email}
                            type="password"
                            label="Set password"
                            isRequired
                            isInvalid={!!(errors.password && touched.password)}
                          />
                          <Box>
                            {touched.password && errors.password && (
                              <Box mt="xsmall">
                                <PasswordRules password={field.value} />
                              </Box>
                            )}
                          </Box>
                        </>
                      )}
                    </Field>
                  </Box>
                  <Box
                    w="100%"
                    pb={
                      errors.confirmPassword && touched.confirmPassword
                        ? 'small'
                        : 'initial'
                    }
                  >
                    <Field name="confirmPassword">
                      {({ field }: FieldProps) => (
                        <>
                          <TextField
                            {...field}
                            type="password"
                            label="Confirm password"
                            isRequired
                            isInvalid={
                              !!(
                                errors.confirmPassword &&
                                touched.confirmPassword
                              )
                            }
                            errorText={errors.confirmPassword}
                          />
                        </>
                      )}
                    </Field>
                  </Box>
                </VStack>
                {displayError && (
                  <Text w="100%" mt="small" color="error" align="left">
                    {displayError}
                  </Text>
                )}
                <Box w="100%">
                  <Button
                    size="lg"
                    w="100%"
                    isLoading={isSubmitting}
                    type="submit"
                  >
                    Create account
                  </Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </Box>
    </Box>
  )
}
