import React, { useEffect, useState } from 'react'
import { Flex, Box, Text } from '@chakra-ui/react'
import { CheckIcon } from '@blueprinthq/joy'
import { useParams } from 'react-router-dom'
import { useUserControllerV1PostUnsubscribeUser } from '~/api/public'
import { Loading } from '@components'


export const UnsubscribeSuccessfulView = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const { userId } = useParams()
  
  const { mutate: unsubscribe } = useUserControllerV1PostUnsubscribeUser({
    mutation: {
      async onSuccess(data: any) {
        setError(false)
        setLoading(false)
      },
      async onError(error: any) {
        setError(true)
        setLoading(false)
      },
    },
  })

  useEffect(() => {
    if (userId) {
      unsubscribe({ userId })
    }
  }, [userId, unsubscribe])
  return (
    <>
    {loading && <Loading />}
    {!loading && <Flex align="center" direction="column" paddingTop="small">
      <Flex
        bg="primary"
        width="64px"
        height="64px"
        borderRadius="50%"
        align="center"
        justify="center"
      >
        
        <CheckIcon size="lg" fill="white" />
      </Flex>
      <Box mt="large">
        <Text textAlign="center" fontWeight="bold" fontSize="lg">
          {!error ? 'You have been successfully unsubscribed.' : 'An error occurred. Please try again later.'}
        </Text>
      </Box>
    </Flex>}
    </>
  )
}
