/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Blueprint: Internal API
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  AccountUserProfileDto,
  ApiExceptionDto,
  OneSignalBodyDto,
  PatientNotificationPreferenceDto,
  UsersControllerV1GetNotificationPreferencesParams,
  CreatePatientNotificationPreferenceDto,
  CheckInDtoV1,
  CheckInScoreResponseV1,
  UsersControllerV1GetCheckInScoresParams,
  CheckInScoreBodyV1,
  SymptomIntervalDto,
  LifestyleGraphDto,
  WorksheetSummaryDto,
  PatientInterventionResponse,
  ActiveOptionalCommsBody,
  ActiveOptionalCommsResponse,
  CheckInDtoV2,
  CheckInScoreResponseV2,
  UsersControllerV2GetCheckInScoresParams,
  HealthKitDto
} from './models'
import { hellojoyApiInstance } from '../../axios-instance';
import type { ErrorType } from '../../axios-instance';


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const accountControllerV1GetUserProfiles = (
    
 options?: SecondParameter<typeof hellojoyApiInstance>,signal?: AbortSignal
) => {
      return hellojoyApiInstance<AccountUserProfileDto[]>(
      {url: `/v1/client-api/account/user-profiles`, method: 'get', signal
    },
      options);
    }
  

export const getAccountControllerV1GetUserProfilesQueryKey = () => [`/v1/client-api/account/user-profiles`];

    
export type AccountControllerV1GetUserProfilesQueryResult = NonNullable<Awaited<ReturnType<typeof accountControllerV1GetUserProfiles>>>
export type AccountControllerV1GetUserProfilesQueryError = ErrorType<void | ApiExceptionDto>

export const useAccountControllerV1GetUserProfiles = <TData = Awaited<ReturnType<typeof accountControllerV1GetUserProfiles>>, TError = ErrorType<void | ApiExceptionDto>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof accountControllerV1GetUserProfiles>>, TError, TData>, request?: SecondParameter<typeof hellojoyApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAccountControllerV1GetUserProfilesQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof accountControllerV1GetUserProfiles>>> = ({ signal }) => accountControllerV1GetUserProfiles(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof accountControllerV1GetUserProfiles>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const accountControllerV1PostOneSignalId = (
    oneSignalBodyDto: OneSignalBodyDto,
 options?: SecondParameter<typeof hellojoyApiInstance>,) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/client-api/account/onesignal-id`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: oneSignalBodyDto
    },
      options);
    }
  


    export type AccountControllerV1PostOneSignalIdMutationResult = NonNullable<Awaited<ReturnType<typeof accountControllerV1PostOneSignalId>>>
    export type AccountControllerV1PostOneSignalIdMutationBody = OneSignalBodyDto
    export type AccountControllerV1PostOneSignalIdMutationError = ErrorType<unknown>

    export const useAccountControllerV1PostOneSignalId = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof accountControllerV1PostOneSignalId>>, TError,{data: OneSignalBodyDto}, TContext>, request?: SecondParameter<typeof hellojoyApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof accountControllerV1PostOneSignalId>>, {data: OneSignalBodyDto}> = (props) => {
          const {data} = props ?? {};

          return  accountControllerV1PostOneSignalId(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof accountControllerV1PostOneSignalId>>, TError, {data: OneSignalBodyDto}, TContext>(mutationFn, mutationOptions);
    }
    
export const usersControllerV1GetNotificationPreferences = (
    userId: string,
    params: UsersControllerV1GetNotificationPreferencesParams,
 options?: SecondParameter<typeof hellojoyApiInstance>,signal?: AbortSignal
) => {
      return hellojoyApiInstance<PatientNotificationPreferenceDto[]>(
      {url: `/v1/client-api/users/${userId}/notification-preferences`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getUsersControllerV1GetNotificationPreferencesQueryKey = (userId: string,
    params: UsersControllerV1GetNotificationPreferencesParams,) => [`/v1/client-api/users/${userId}/notification-preferences`, ...(params ? [params]: [])];

    
export type UsersControllerV1GetNotificationPreferencesQueryResult = NonNullable<Awaited<ReturnType<typeof usersControllerV1GetNotificationPreferences>>>
export type UsersControllerV1GetNotificationPreferencesQueryError = ErrorType<void>

export const useUsersControllerV1GetNotificationPreferences = <TData = Awaited<ReturnType<typeof usersControllerV1GetNotificationPreferences>>, TError = ErrorType<void>>(
 userId: string,
    params: UsersControllerV1GetNotificationPreferencesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof usersControllerV1GetNotificationPreferences>>, TError, TData>, request?: SecondParameter<typeof hellojoyApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersControllerV1GetNotificationPreferencesQueryKey(userId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersControllerV1GetNotificationPreferences>>> = ({ signal }) => usersControllerV1GetNotificationPreferences(userId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof usersControllerV1GetNotificationPreferences>>, TError, TData>(queryKey, queryFn, {enabled: !!(userId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const usersControllerV1CreateNotificationPreference = (
    userId: string,
    createPatientNotificationPreferenceDto: CreatePatientNotificationPreferenceDto,
 options?: SecondParameter<typeof hellojoyApiInstance>,) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/client-api/users/${userId}/notification-preferences`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createPatientNotificationPreferenceDto
    },
      options);
    }
  


    export type UsersControllerV1CreateNotificationPreferenceMutationResult = NonNullable<Awaited<ReturnType<typeof usersControllerV1CreateNotificationPreference>>>
    export type UsersControllerV1CreateNotificationPreferenceMutationBody = CreatePatientNotificationPreferenceDto
    export type UsersControllerV1CreateNotificationPreferenceMutationError = ErrorType<unknown>

    export const useUsersControllerV1CreateNotificationPreference = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersControllerV1CreateNotificationPreference>>, TError,{userId: string;data: CreatePatientNotificationPreferenceDto}, TContext>, request?: SecondParameter<typeof hellojoyApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersControllerV1CreateNotificationPreference>>, {userId: string;data: CreatePatientNotificationPreferenceDto}> = (props) => {
          const {userId,data} = props ?? {};

          return  usersControllerV1CreateNotificationPreference(userId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof usersControllerV1CreateNotificationPreference>>, TError, {userId: string;data: CreatePatientNotificationPreferenceDto}, TContext>(mutationFn, mutationOptions);
    }
    
export const usersControllerV1GetNotificationPreference = (
    userId: string,
    id: string,
 options?: SecondParameter<typeof hellojoyApiInstance>,signal?: AbortSignal
) => {
      return hellojoyApiInstance<PatientNotificationPreferenceDto[]>(
      {url: `/v1/client-api/users/${userId}/notification-preferences/${id}`, method: 'get', signal
    },
      options);
    }
  

export const getUsersControllerV1GetNotificationPreferenceQueryKey = (userId: string,
    id: string,) => [`/v1/client-api/users/${userId}/notification-preferences/${id}`];

    
export type UsersControllerV1GetNotificationPreferenceQueryResult = NonNullable<Awaited<ReturnType<typeof usersControllerV1GetNotificationPreference>>>
export type UsersControllerV1GetNotificationPreferenceQueryError = ErrorType<void>

export const useUsersControllerV1GetNotificationPreference = <TData = Awaited<ReturnType<typeof usersControllerV1GetNotificationPreference>>, TError = ErrorType<void>>(
 userId: string,
    id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof usersControllerV1GetNotificationPreference>>, TError, TData>, request?: SecondParameter<typeof hellojoyApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersControllerV1GetNotificationPreferenceQueryKey(userId,id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersControllerV1GetNotificationPreference>>> = ({ signal }) => usersControllerV1GetNotificationPreference(userId,id, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof usersControllerV1GetNotificationPreference>>, TError, TData>(queryKey, queryFn, {enabled: !!(userId && id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const usersControllerV1DeleteNotificationPreference = (
    userId: string,
    id: string,
 options?: SecondParameter<typeof hellojoyApiInstance>,) => {
      return hellojoyApiInstance<PatientNotificationPreferenceDto[]>(
      {url: `/v1/client-api/users/${userId}/notification-preferences/${id}`, method: 'delete'
    },
      options);
    }
  


    export type UsersControllerV1DeleteNotificationPreferenceMutationResult = NonNullable<Awaited<ReturnType<typeof usersControllerV1DeleteNotificationPreference>>>
    
    export type UsersControllerV1DeleteNotificationPreferenceMutationError = ErrorType<void>

    export const useUsersControllerV1DeleteNotificationPreference = <TError = ErrorType<void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersControllerV1DeleteNotificationPreference>>, TError,{userId: string;id: string}, TContext>, request?: SecondParameter<typeof hellojoyApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersControllerV1DeleteNotificationPreference>>, {userId: string;id: string}> = (props) => {
          const {userId,id} = props ?? {};

          return  usersControllerV1DeleteNotificationPreference(userId,id,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof usersControllerV1DeleteNotificationPreference>>, TError, {userId: string;id: string}, TContext>(mutationFn, mutationOptions);
    }
    
export const usersControllerV1GetAssignedCheckins = (
    userId: string,
 options?: SecondParameter<typeof hellojoyApiInstance>,signal?: AbortSignal
) => {
      return hellojoyApiInstance<CheckInDtoV1[]>(
      {url: `/v1/client-api/users/${userId}/check-ins/assigned`, method: 'get', signal
    },
      options);
    }
  

export const getUsersControllerV1GetAssignedCheckinsQueryKey = (userId: string,) => [`/v1/client-api/users/${userId}/check-ins/assigned`];

    
export type UsersControllerV1GetAssignedCheckinsQueryResult = NonNullable<Awaited<ReturnType<typeof usersControllerV1GetAssignedCheckins>>>
export type UsersControllerV1GetAssignedCheckinsQueryError = ErrorType<void | ApiExceptionDto>

export const useUsersControllerV1GetAssignedCheckins = <TData = Awaited<ReturnType<typeof usersControllerV1GetAssignedCheckins>>, TError = ErrorType<void | ApiExceptionDto>>(
 userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof usersControllerV1GetAssignedCheckins>>, TError, TData>, request?: SecondParameter<typeof hellojoyApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersControllerV1GetAssignedCheckinsQueryKey(userId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersControllerV1GetAssignedCheckins>>> = ({ signal }) => usersControllerV1GetAssignedCheckins(userId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof usersControllerV1GetAssignedCheckins>>, TError, TData>(queryKey, queryFn, {enabled: !!(userId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const usersControllerV1GetAssignedCheckInById = (
    userId: string,
    id: string,
 options?: SecondParameter<typeof hellojoyApiInstance>,signal?: AbortSignal
) => {
      return hellojoyApiInstance<CheckInDtoV1>(
      {url: `/v1/client-api/users/${userId}/check-ins/assigned/${id}`, method: 'get', signal
    },
      options);
    }
  

export const getUsersControllerV1GetAssignedCheckInByIdQueryKey = (userId: string,
    id: string,) => [`/v1/client-api/users/${userId}/check-ins/assigned/${id}`];

    
export type UsersControllerV1GetAssignedCheckInByIdQueryResult = NonNullable<Awaited<ReturnType<typeof usersControllerV1GetAssignedCheckInById>>>
export type UsersControllerV1GetAssignedCheckInByIdQueryError = ErrorType<void | ApiExceptionDto>

export const useUsersControllerV1GetAssignedCheckInById = <TData = Awaited<ReturnType<typeof usersControllerV1GetAssignedCheckInById>>, TError = ErrorType<void | ApiExceptionDto>>(
 userId: string,
    id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof usersControllerV1GetAssignedCheckInById>>, TError, TData>, request?: SecondParameter<typeof hellojoyApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersControllerV1GetAssignedCheckInByIdQueryKey(userId,id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersControllerV1GetAssignedCheckInById>>> = ({ signal }) => usersControllerV1GetAssignedCheckInById(userId,id, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof usersControllerV1GetAssignedCheckInById>>, TError, TData>(queryKey, queryFn, {enabled: !!(userId && id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const usersControllerV1GetCheckInScores = (
    userId: string,
    params: UsersControllerV1GetCheckInScoresParams,
 options?: SecondParameter<typeof hellojoyApiInstance>,signal?: AbortSignal
) => {
      return hellojoyApiInstance<CheckInScoreResponseV1>(
      {url: `/v1/client-api/users/${userId}/check-ins/scores`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getUsersControllerV1GetCheckInScoresQueryKey = (userId: string,
    params: UsersControllerV1GetCheckInScoresParams,) => [`/v1/client-api/users/${userId}/check-ins/scores`, ...(params ? [params]: [])];

    
export type UsersControllerV1GetCheckInScoresQueryResult = NonNullable<Awaited<ReturnType<typeof usersControllerV1GetCheckInScores>>>
export type UsersControllerV1GetCheckInScoresQueryError = ErrorType<void | ApiExceptionDto>

export const useUsersControllerV1GetCheckInScores = <TData = Awaited<ReturnType<typeof usersControllerV1GetCheckInScores>>, TError = ErrorType<void | ApiExceptionDto>>(
 userId: string,
    params: UsersControllerV1GetCheckInScoresParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof usersControllerV1GetCheckInScores>>, TError, TData>, request?: SecondParameter<typeof hellojoyApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersControllerV1GetCheckInScoresQueryKey(userId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersControllerV1GetCheckInScores>>> = ({ signal }) => usersControllerV1GetCheckInScores(userId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof usersControllerV1GetCheckInScores>>, TError, TData>(queryKey, queryFn, {enabled: !!(userId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const usersControllerV1PostCheckInsSubmit = (
    userId: string,
    checkInScoreBodyV1: CheckInScoreBodyV1[],
 options?: SecondParameter<typeof hellojoyApiInstance>,) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/client-api/users/${userId}/check-ins/submit`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: checkInScoreBodyV1
    },
      options);
    }
  


    export type UsersControllerV1PostCheckInsSubmitMutationResult = NonNullable<Awaited<ReturnType<typeof usersControllerV1PostCheckInsSubmit>>>
    export type UsersControllerV1PostCheckInsSubmitMutationBody = CheckInScoreBodyV1[]
    export type UsersControllerV1PostCheckInsSubmitMutationError = ErrorType<ApiExceptionDto>

    export const useUsersControllerV1PostCheckInsSubmit = <TError = ErrorType<ApiExceptionDto>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersControllerV1PostCheckInsSubmit>>, TError,{userId: string;data: CheckInScoreBodyV1[]}, TContext>, request?: SecondParameter<typeof hellojoyApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersControllerV1PostCheckInsSubmit>>, {userId: string;data: CheckInScoreBodyV1[]}> = (props) => {
          const {userId,data} = props ?? {};

          return  usersControllerV1PostCheckInsSubmit(userId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof usersControllerV1PostCheckInsSubmit>>, TError, {userId: string;data: CheckInScoreBodyV1[]}, TContext>(mutationFn, mutationOptions);
    }
    
export const usersControllerV1GetInsightsSymptoms = (
    userId: string,
 options?: SecondParameter<typeof hellojoyApiInstance>,signal?: AbortSignal
) => {
      return hellojoyApiInstance<SymptomIntervalDto[]>(
      {url: `/v1/client-api/users/${userId}/insights/symptoms-graph`, method: 'get', signal
    },
      options);
    }
  

export const getUsersControllerV1GetInsightsSymptomsQueryKey = (userId: string,) => [`/v1/client-api/users/${userId}/insights/symptoms-graph`];

    
export type UsersControllerV1GetInsightsSymptomsQueryResult = NonNullable<Awaited<ReturnType<typeof usersControllerV1GetInsightsSymptoms>>>
export type UsersControllerV1GetInsightsSymptomsQueryError = ErrorType<void | ApiExceptionDto>

export const useUsersControllerV1GetInsightsSymptoms = <TData = Awaited<ReturnType<typeof usersControllerV1GetInsightsSymptoms>>, TError = ErrorType<void | ApiExceptionDto>>(
 userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof usersControllerV1GetInsightsSymptoms>>, TError, TData>, request?: SecondParameter<typeof hellojoyApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersControllerV1GetInsightsSymptomsQueryKey(userId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersControllerV1GetInsightsSymptoms>>> = ({ signal }) => usersControllerV1GetInsightsSymptoms(userId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof usersControllerV1GetInsightsSymptoms>>, TError, TData>(queryKey, queryFn, {enabled: !!(userId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const usersControllerV1GetInsightsLifestyle = (
    userId: string,
 options?: SecondParameter<typeof hellojoyApiInstance>,signal?: AbortSignal
) => {
      return hellojoyApiInstance<LifestyleGraphDto[]>(
      {url: `/v1/client-api/users/${userId}/insights/lifestyle-graph`, method: 'get', signal
    },
      options);
    }
  

export const getUsersControllerV1GetInsightsLifestyleQueryKey = (userId: string,) => [`/v1/client-api/users/${userId}/insights/lifestyle-graph`];

    
export type UsersControllerV1GetInsightsLifestyleQueryResult = NonNullable<Awaited<ReturnType<typeof usersControllerV1GetInsightsLifestyle>>>
export type UsersControllerV1GetInsightsLifestyleQueryError = ErrorType<void | ApiExceptionDto>

export const useUsersControllerV1GetInsightsLifestyle = <TData = Awaited<ReturnType<typeof usersControllerV1GetInsightsLifestyle>>, TError = ErrorType<void | ApiExceptionDto>>(
 userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof usersControllerV1GetInsightsLifestyle>>, TError, TData>, request?: SecondParameter<typeof hellojoyApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersControllerV1GetInsightsLifestyleQueryKey(userId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersControllerV1GetInsightsLifestyle>>> = ({ signal }) => usersControllerV1GetInsightsLifestyle(userId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof usersControllerV1GetInsightsLifestyle>>, TError, TData>(queryKey, queryFn, {enabled: !!(userId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const usersControllerV1GetInsightsWorksheetSummary = (
    userId: string,
 options?: SecondParameter<typeof hellojoyApiInstance>,signal?: AbortSignal
) => {
      return hellojoyApiInstance<WorksheetSummaryDto[]>(
      {url: `/v1/client-api/users/${userId}/insights/worksheet-summary`, method: 'get', signal
    },
      options);
    }
  

export const getUsersControllerV1GetInsightsWorksheetSummaryQueryKey = (userId: string,) => [`/v1/client-api/users/${userId}/insights/worksheet-summary`];

    
export type UsersControllerV1GetInsightsWorksheetSummaryQueryResult = NonNullable<Awaited<ReturnType<typeof usersControllerV1GetInsightsWorksheetSummary>>>
export type UsersControllerV1GetInsightsWorksheetSummaryQueryError = ErrorType<void | ApiExceptionDto>

export const useUsersControllerV1GetInsightsWorksheetSummary = <TData = Awaited<ReturnType<typeof usersControllerV1GetInsightsWorksheetSummary>>, TError = ErrorType<void | ApiExceptionDto>>(
 userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof usersControllerV1GetInsightsWorksheetSummary>>, TError, TData>, request?: SecondParameter<typeof hellojoyApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersControllerV1GetInsightsWorksheetSummaryQueryKey(userId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersControllerV1GetInsightsWorksheetSummary>>> = ({ signal }) => usersControllerV1GetInsightsWorksheetSummary(userId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof usersControllerV1GetInsightsWorksheetSummary>>, TError, TData>(queryKey, queryFn, {enabled: !!(userId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const usersControllerV1PatchSafetyNetDismiss = (
    userId: string,
 options?: SecondParameter<typeof hellojoyApiInstance>,) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/client-api/users/${userId}/safety-net/dismiss`, method: 'patch'
    },
      options);
    }
  


    export type UsersControllerV1PatchSafetyNetDismissMutationResult = NonNullable<Awaited<ReturnType<typeof usersControllerV1PatchSafetyNetDismiss>>>
    
    export type UsersControllerV1PatchSafetyNetDismissMutationError = ErrorType<unknown>

    export const useUsersControllerV1PatchSafetyNetDismiss = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersControllerV1PatchSafetyNetDismiss>>, TError,{userId: string}, TContext>, request?: SecondParameter<typeof hellojoyApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersControllerV1PatchSafetyNetDismiss>>, {userId: string}> = (props) => {
          const {userId} = props ?? {};

          return  usersControllerV1PatchSafetyNetDismiss(userId,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof usersControllerV1PatchSafetyNetDismiss>>, TError, {userId: string}, TContext>(mutationFn, mutationOptions);
    }
    
export const usersControllerV1GetPatientIntervention = (
    userId: string,
    patientInterventionId: string,
 options?: SecondParameter<typeof hellojoyApiInstance>,signal?: AbortSignal
) => {
      return hellojoyApiInstance<PatientInterventionResponse>(
      {url: `/v1/client-api/users/${userId}/intervention/${patientInterventionId}`, method: 'get', signal
    },
      options);
    }
  

export const getUsersControllerV1GetPatientInterventionQueryKey = (userId: string,
    patientInterventionId: string,) => [`/v1/client-api/users/${userId}/intervention/${patientInterventionId}`];

    
export type UsersControllerV1GetPatientInterventionQueryResult = NonNullable<Awaited<ReturnType<typeof usersControllerV1GetPatientIntervention>>>
export type UsersControllerV1GetPatientInterventionQueryError = ErrorType<void>

export const useUsersControllerV1GetPatientIntervention = <TData = Awaited<ReturnType<typeof usersControllerV1GetPatientIntervention>>, TError = ErrorType<void>>(
 userId: string,
    patientInterventionId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof usersControllerV1GetPatientIntervention>>, TError, TData>, request?: SecondParameter<typeof hellojoyApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersControllerV1GetPatientInterventionQueryKey(userId,patientInterventionId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersControllerV1GetPatientIntervention>>> = ({ signal }) => usersControllerV1GetPatientIntervention(userId,patientInterventionId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof usersControllerV1GetPatientIntervention>>, TError, TData>(queryKey, queryFn, {enabled: !!(userId && patientInterventionId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const usersControllerV1UpdateActiveOptionalComms = (
    userId: string,
    activeOptionalCommsBody: ActiveOptionalCommsBody,
 options?: SecondParameter<typeof hellojoyApiInstance>,) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/client-api/users/${userId}/optional-comms`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: activeOptionalCommsBody
    },
      options);
    }
  


    export type UsersControllerV1UpdateActiveOptionalCommsMutationResult = NonNullable<Awaited<ReturnType<typeof usersControllerV1UpdateActiveOptionalComms>>>
    export type UsersControllerV1UpdateActiveOptionalCommsMutationBody = ActiveOptionalCommsBody
    export type UsersControllerV1UpdateActiveOptionalCommsMutationError = ErrorType<unknown>

    export const useUsersControllerV1UpdateActiveOptionalComms = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersControllerV1UpdateActiveOptionalComms>>, TError,{userId: string;data: ActiveOptionalCommsBody}, TContext>, request?: SecondParameter<typeof hellojoyApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersControllerV1UpdateActiveOptionalComms>>, {userId: string;data: ActiveOptionalCommsBody}> = (props) => {
          const {userId,data} = props ?? {};

          return  usersControllerV1UpdateActiveOptionalComms(userId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof usersControllerV1UpdateActiveOptionalComms>>, TError, {userId: string;data: ActiveOptionalCommsBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const usersControllerV1GetActiveOptionalComms = (
    userId: string,
 options?: SecondParameter<typeof hellojoyApiInstance>,signal?: AbortSignal
) => {
      return hellojoyApiInstance<ActiveOptionalCommsResponse>(
      {url: `/v1/client-api/users/${userId}/optional-comms`, method: 'get', signal
    },
      options);
    }
  

export const getUsersControllerV1GetActiveOptionalCommsQueryKey = (userId: string,) => [`/v1/client-api/users/${userId}/optional-comms`];

    
export type UsersControllerV1GetActiveOptionalCommsQueryResult = NonNullable<Awaited<ReturnType<typeof usersControllerV1GetActiveOptionalComms>>>
export type UsersControllerV1GetActiveOptionalCommsQueryError = ErrorType<void>

export const useUsersControllerV1GetActiveOptionalComms = <TData = Awaited<ReturnType<typeof usersControllerV1GetActiveOptionalComms>>, TError = ErrorType<void>>(
 userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof usersControllerV1GetActiveOptionalComms>>, TError, TData>, request?: SecondParameter<typeof hellojoyApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersControllerV1GetActiveOptionalCommsQueryKey(userId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersControllerV1GetActiveOptionalComms>>> = ({ signal }) => usersControllerV1GetActiveOptionalComms(userId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof usersControllerV1GetActiveOptionalComms>>, TError, TData>(queryKey, queryFn, {enabled: !!(userId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const usersControllerV2GetAssignedCheckInById = (
    userId: string,
    id: string,
 options?: SecondParameter<typeof hellojoyApiInstance>,signal?: AbortSignal
) => {
      return hellojoyApiInstance<CheckInDtoV2>(
      {url: `/v2/client-api/users/${userId}/check-ins/assigned/${id}`, method: 'get', signal
    },
      options);
    }
  

export const getUsersControllerV2GetAssignedCheckInByIdQueryKey = (userId: string,
    id: string,) => [`/v2/client-api/users/${userId}/check-ins/assigned/${id}`];

    
export type UsersControllerV2GetAssignedCheckInByIdQueryResult = NonNullable<Awaited<ReturnType<typeof usersControllerV2GetAssignedCheckInById>>>
export type UsersControllerV2GetAssignedCheckInByIdQueryError = ErrorType<void | ApiExceptionDto>

export const useUsersControllerV2GetAssignedCheckInById = <TData = Awaited<ReturnType<typeof usersControllerV2GetAssignedCheckInById>>, TError = ErrorType<void | ApiExceptionDto>>(
 userId: string,
    id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof usersControllerV2GetAssignedCheckInById>>, TError, TData>, request?: SecondParameter<typeof hellojoyApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersControllerV2GetAssignedCheckInByIdQueryKey(userId,id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersControllerV2GetAssignedCheckInById>>> = ({ signal }) => usersControllerV2GetAssignedCheckInById(userId,id, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof usersControllerV2GetAssignedCheckInById>>, TError, TData>(queryKey, queryFn, {enabled: !!(userId && id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const usersControllerV2GetCheckInScores = (
    userId: string,
    params: UsersControllerV2GetCheckInScoresParams,
 options?: SecondParameter<typeof hellojoyApiInstance>,signal?: AbortSignal
) => {
      return hellojoyApiInstance<CheckInScoreResponseV2>(
      {url: `/v2/client-api/users/${userId}/check-ins/scores`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getUsersControllerV2GetCheckInScoresQueryKey = (userId: string,
    params: UsersControllerV2GetCheckInScoresParams,) => [`/v2/client-api/users/${userId}/check-ins/scores`, ...(params ? [params]: [])];

    
export type UsersControllerV2GetCheckInScoresQueryResult = NonNullable<Awaited<ReturnType<typeof usersControllerV2GetCheckInScores>>>
export type UsersControllerV2GetCheckInScoresQueryError = ErrorType<void | ApiExceptionDto>

export const useUsersControllerV2GetCheckInScores = <TData = Awaited<ReturnType<typeof usersControllerV2GetCheckInScores>>, TError = ErrorType<void | ApiExceptionDto>>(
 userId: string,
    params: UsersControllerV2GetCheckInScoresParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof usersControllerV2GetCheckInScores>>, TError, TData>, request?: SecondParameter<typeof hellojoyApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersControllerV2GetCheckInScoresQueryKey(userId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersControllerV2GetCheckInScores>>> = ({ signal }) => usersControllerV2GetCheckInScores(userId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof usersControllerV2GetCheckInScores>>, TError, TData>(queryKey, queryFn, {enabled: !!(userId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const usersControllerV2GetAssignedCheckins = (
    userId: string,
 options?: SecondParameter<typeof hellojoyApiInstance>,signal?: AbortSignal
) => {
      return hellojoyApiInstance<CheckInDtoV2[]>(
      {url: `/v2/client-api/users/${userId}/check-ins/assigned`, method: 'get', signal
    },
      options);
    }
  

export const getUsersControllerV2GetAssignedCheckinsQueryKey = (userId: string,) => [`/v2/client-api/users/${userId}/check-ins/assigned`];

    
export type UsersControllerV2GetAssignedCheckinsQueryResult = NonNullable<Awaited<ReturnType<typeof usersControllerV2GetAssignedCheckins>>>
export type UsersControllerV2GetAssignedCheckinsQueryError = ErrorType<void | ApiExceptionDto>

export const useUsersControllerV2GetAssignedCheckins = <TData = Awaited<ReturnType<typeof usersControllerV2GetAssignedCheckins>>, TError = ErrorType<void | ApiExceptionDto>>(
 userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof usersControllerV2GetAssignedCheckins>>, TError, TData>, request?: SecondParameter<typeof hellojoyApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersControllerV2GetAssignedCheckinsQueryKey(userId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersControllerV2GetAssignedCheckins>>> = ({ signal }) => usersControllerV2GetAssignedCheckins(userId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof usersControllerV2GetAssignedCheckins>>, TError, TData>(queryKey, queryFn, {enabled: !!(userId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const healthkitControllerV1PostHealthKitIosStore = (
    patientId: string,
    healthKitDto: HealthKitDto,
 options?: SecondParameter<typeof hellojoyApiInstance>,) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/client-api/healthkit/${patientId}/ios-store`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: healthKitDto
    },
      options);
    }
  


    export type HealthkitControllerV1PostHealthKitIosStoreMutationResult = NonNullable<Awaited<ReturnType<typeof healthkitControllerV1PostHealthKitIosStore>>>
    export type HealthkitControllerV1PostHealthKitIosStoreMutationBody = HealthKitDto
    export type HealthkitControllerV1PostHealthKitIosStoreMutationError = ErrorType<ApiExceptionDto>

    export const useHealthkitControllerV1PostHealthKitIosStore = <TError = ErrorType<ApiExceptionDto>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof healthkitControllerV1PostHealthKitIosStore>>, TError,{patientId: string;data: HealthKitDto}, TContext>, request?: SecondParameter<typeof hellojoyApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof healthkitControllerV1PostHealthKitIosStore>>, {patientId: string;data: HealthKitDto}> = (props) => {
          const {patientId,data} = props ?? {};

          return  healthkitControllerV1PostHealthKitIosStore(patientId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof healthkitControllerV1PostHealthKitIosStore>>, TError, {patientId: string;data: HealthKitDto}, TContext>(mutationFn, mutationOptions);
    }
    
