import React from 'react'
import { Box, Text, Slider, SliderTrack, SliderThumb, Flex } from '@chakra-ui/react'

interface Props {
  type: string
  minValue: number
  maxValue: number
  value: number
  minText: string
  maxText: string
  onChange: (val: number) => void
}

const icons = {
  positivity: {
    positive: '😁',
    neutral: '😐',
    negative: '☹️'
  },
  energy: {
    positive: '🤩',
    neutral: '😐',
    negative: '🥱'
  },
  sleep: {
    positive: '😌',
    neutral: '😐',
    negative: '😫'
  },
  social: {
    positive: '🤗',
    neutral: '😐',
    negative: '😔'
  }
}

export const VerticalSlider = ({
  type,
  minValue = -2,
  maxValue = 2,
  minText = '',
  maxText = '',
  value,
  onChange
}: Props) => {
  const totalRange = maxValue - minValue
  const oneThird = totalRange / 3

  const positiveCutoff = maxValue - oneThird
  const negativeCutoff = minValue + oneThird

  const emotion =
    value >= positiveCutoff
      ? 'positive'
      : value <= negativeCutoff
      ? 'negative'
      : 'neutral'

  // @ts-ignore
  const icon = () => <Text fontSize="40px">{icons[type][emotion]}</Text>

  return (
    <Flex flex={1} flexDirection="column" alignItems="center" mb="medium">
      <Text>{maxText}</Text>
      <Slider
        size="lg"
        min={minValue}
        max={maxValue}
        onChange={e => onChange(e)}
        value={value}
        orientation="vertical"
        step={0.01}
        my="small"
      >
        <SliderTrack w="16px" bg="pale_gray" borderRadius="8px" />
        <SliderThumb
          boxSize={12}
          bg="transparent"
          boxShadow="none"
          _focus={{
            boxShadow: 'none'
          }}
        >
          <Box as={icon} />
        </SliderThumb>
      </Slider>
      <Text>{minText}</Text>
    </Flex>
  )
}
