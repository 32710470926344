import { TimeRangeFilter } from './components/time-range-selector'
import { DateTime, Interval } from 'luxon'

interface ChartInterval {
  start: number
  end: number
}

export const buildChartIntervals = (
  timeRange: TimeRangeFilter
): ChartInterval[] => {
  switch (timeRange) {
    case TimeRangeFilter.ONE_WEEK:
      return Interval.fromDateTimes(
        DateTime.now().minus({ days: 6 }).startOf('day'),
        DateTime.now().plus({ days: 1 }).startOf('day')
      )
        .splitBy({ day: 1 })
        .map(d => ({
          start: d.start.toUnixInteger(),
          end: d.end.toUnixInteger()
        }))
    case TimeRangeFilter.ONE_MONTH:
      return Interval.fromDateTimes(
        DateTime.now().minus({ months: 1 }).startOf('day'),
        DateTime.now().endOf('day')
      )
        .splitBy({ day: 1 })
        .map(d => ({
          start: d.start.toUnixInteger(),
          end: d.end.toUnixInteger()
        }))
    case TimeRangeFilter.SIX_MONTHS:
      return Interval.fromDateTimes(
        DateTime.now().startOf('week').minus({ months: 5 }).startOf('day'),
        DateTime.now().endOf('day')
      )
        .splitBy({ weeks: 1 })
        .map(d => ({
          start: d.start.toUnixInteger(),
          end: d.end.toUnixInteger()
        }))
    case TimeRangeFilter.ALL_TIME:
      return Interval.fromDateTimes(
        DateTime.now().minus({ months: 11 }),
        DateTime.now().plus({ months: 1 })
      )
        .splitBy({ months: 1 })
        .map(d => ({
          start: d.start.toUnixInteger(),
          end: d.end.toUnixInteger()
        }))
  }
}

export const formatXAxisTick = (
  timestamp: number,
  timeRange: TimeRangeFilter,
  forceShow: boolean = false
): string => {
  switch (timeRange) {
    case TimeRangeFilter.ONE_WEEK:
      return DateTime.fromSeconds(timestamp).toFormat('ccc')
    case TimeRangeFilter.ONE_MONTH:
      if (DateTime.fromSeconds(timestamp).weekday === 1) {
        return DateTime.fromSeconds(timestamp).toFormat('L/d')
      } else {
        return ''
      }
    case TimeRangeFilter.SIX_MONTHS:
      if (DateTime.fromSeconds(timestamp).day <= 7) {
        return DateTime.fromSeconds(timestamp).toFormat('LLL')
      } else {
        return ''
      }
    case TimeRangeFilter.ALL_TIME:
      return DateTime.fromSeconds(timestamp).toFormat('LLL')[0]
    default:
      return ''
  }
}

export const formatYAxisTick = (value: number) => {
  return value === 0 ? 'Low' : value === 5 ? 'High' : ''
}
