import React, { useState } from 'react'
import { Box, Heading, Text, useToast } from '@chakra-ui/react'
import { Field, Form, Formik, FieldProps } from 'formik'
import * as Yup from 'yup'
import { Button, TextField } from '@blueprinthq/joy'

import {
  usePublicOnboardingControllerPostVerify
} from '~/api/public'
import {
  PatientInviteVerifyBody,
} from '~/api/public/models'

interface VerifyInviteFormProps {
  email?: string
  phoneNumber?: string
  lastName?: string;
  dateOfBirth?: string;
  onSuccess: (patientId: string, email: string | null) => void
}

const VerifyInviteSchema = Yup.object({
  code: Yup.string().minNumbers(6)
})

export const VerifyInviteForm = ({
  email,
  phoneNumber,
  dateOfBirth,
  lastName,
  onSuccess
}: VerifyInviteFormProps) => {
  const [displayError, setDisplayError] = useState<undefined | string>(
    undefined
  )
  const toast = useToast({
    status: 'success'
  })

  const { mutate: postVerify } = usePublicOnboardingControllerPostVerify()

  return (
    <Box w="100%">
      <Heading size="lg" mb="xsmall">
        Please enter the code we sent to {email || phoneNumber}
      </Heading>
      <Box mt="medium">
        <Formik
          validationSchema={VerifyInviteSchema}
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={{
            code: ''
          }}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true)

            let body: PatientInviteVerifyBody | {} = {
              code: values.code,
              email,
              phoneNumber,
              dateOfBirth,
              lastName,
            }

            if (body) {
              postVerify(
                {
                  data: body as PatientInviteVerifyBody
                },
                {
                  onSuccess: ({ patientEmail, patientId }) => {
                    onSuccess(patientId, patientEmail!)
                    toast({
                      description: 'Confirmation code accepted!'
                    })
                  },
                  onError: error => {
                    setDisplayError(error.response?.data.error.message)
                  }
                }
              )
            }
          }}
        >
          {({ errors, touched, values, isSubmitting }) => {
            return (
              <Form>
                {displayError && (
                  <Text w="100%" mb="small" color="error" align="left">
                    {displayError}
                  </Text>
                )}
                <Box
                  w="100%"
                  pb={errors.code && touched.code ? 'small' : 'initial'}
                >
                  <Field name="code">
                    {({ field }: FieldProps) => (
                        <TextField
                          {...field}
                          onInput={(e) => {
                            // Forces 'text' input to only allow for numbers
                            // why we use 'text' input for this and not 'number': https://web.dev/sms-otp-form/#type=text
                            e.currentTarget.value = e.currentTarget.value.replace(/[^0-9.]/g, "")
                          }}
                          autoFocus
                          type="text"
                          maxLength={6}
                          inputMode="numeric"
                          autoComplete="one-time-code"
                          label="Confirmation code"
                          isRequired
                          isInvalid={!!(errors.code && touched.code)}
                          errorText={errors.code}
                        />
                      )}
                  </Field>
                </Box>
                <Box w="100%" mt="small">
                  <Button
                    size="lg"
                    w="100%"
                    isLoading={isSubmitting}
                    type="submit"
                  >
                    Next
                  </Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </Box>
    </Box>
  )
}
