import React from 'react'
import { SkeletonText, Box } from '@chakra-ui/react'

export const LoadingChart = () => {
  return (
    <Box p={6} bg="white" borderRadius={8} h="220px">
      <SkeletonText w="75%" noOfLines={1} skeletonHeight="20px" mb={2} />
      <SkeletonText w="25%" noOfLines={1} skeletonHeight="20px" mb={5} />
      <SkeletonText noOfLines={5} spacing="4" skeletonHeight="3px" />
    </Box>
  )
}
