import { CheckInDtoV2 } from '~/api/public/models'
import React, { useState, useMemo, useCallback } from 'react'
import { Box } from '@chakra-ui/react'

import { CheckInForm } from '../check-in-form'

interface CheckInFormCarouselProps {
  checkIns: CheckInDtoV2[]
  userId: string
  onSubmit: (checkInId: string, result: Record<string, any>) => void
  onAllFormsSubmitted: () => void
}

export const CheckInFormCarousel = ({
  checkIns,
  userId,
  onSubmit,
  onAllFormsSubmitted,
}: CheckInFormCarouselProps) => {
  const [submittedForms, setSubbmitedForms] = useState<any>({})
  const [currentFormIndex, setCurrentFormIndex] = useState<number>(0)

  const selectedFormCheckIn = useMemo(() => {
    return checkIns[currentFormIndex]
  }, [currentFormIndex, checkIns])

  const setFormIndex = (n: number) => {
    setCurrentFormIndex(n)

    // Goto top of page when form changes
    window.requestAnimationFrame(() => {
      window.scrollTo({
        top: 0
      })
    })
  }

  const isLastCheckIn = useMemo(() => {
    return currentFormIndex >= (checkIns?.length || 0) - 1
  }, [checkIns, currentFormIndex])

  const gotoNextForm = useCallback(() => {
    setFormIndex(currentFormIndex + 1)
  }, [currentFormIndex])

  const _onSubmitForm = useCallback(async (checkInId: string, values: any) => {
    const formValues = { ...submittedForms, [checkInId]: values }
    setSubbmitedForms(formValues)

    await onSubmit(checkInId, values)

    if (isLastCheckIn) {
      onAllFormsSubmitted()
    } else {
      gotoNextForm()
    }

  }, [isLastCheckIn, submittedForms, gotoNextForm])

  return (
    <Box height="100%">
      <CheckInForm
        checkIn={selectedFormCheckIn}
        isPreview={false}
        onSubmit={_onSubmitForm}
      />
    </Box>
  )
}