import React from 'react'
import {
  Container,
  Box,
  Text,
  GridItem,
  Heading,
} from '@chakra-ui/react'
import {
  LayoutGrid,
  ArrowRightIcon,
  Button
} from '@blueprinthq/joy'
import { StickyFooter } from '@components'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { usePatientProfileStore } from '@core/store'

import { useUsersControllerV1GetCheckInScores } from '~/api/client'
import { useOnNavBack } from '@hooks'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'

export const CompletedCheckInListView = observer(() => {
  const { checkInId } = useParams()
  const { activeProfile } = usePatientProfileStore()

  const navigate = useNavigate()

  useOnNavBack(() => navigate('/progress'))

  const { data } = useUsersControllerV1GetCheckInScores(activeProfile.userId, {
    checkInIds: [checkInId!]
  }, {
    query: { enabled: !!checkInId }
  })

  if (!data || !checkInId) return null

  const { checkInModules, checkInScores } = data

  const checkInModule = checkInModules.find(m => m.id === checkInId)

  return (
    <Container
      paddingTop={{
        base: '24px'
      }}
      paddingLeft={{
        base: '8px',
        sm: '0px'
      }}
      paddingRight={{
        base: '8px',
        sm: '0px'
      }}
      pb="100px"
    >
      <LayoutGrid>
        <GridItem
          colStart={{
            base: 1,
            sm: 2,
            md: 4
          }}
          colEnd={{
            base: 5,
            sm: 8,
            md: 10
          }}
        >
          <Box w="100%">
            <Box mb={1}>
              {checkInModule && (
                <Heading size="lg">{checkInModule.title}</Heading>
              )}
            </Box>
            <Box mb={4}>
              <Text>Select a date to view your answers</Text>
            </Box>
            <Box borderWidth="1px" rounded="lg">
              {checkInScores.map(score => (
                <Box borderTopWidth="1px" _first={{ borderTopWidth: 0 }}>
                  <Link to={`${score.id}`}>
                    <Box
                      p={4}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box>
                        {DateTime.fromISO(score.createdAt).toFormat(
                          'ccc L/dd/yy h:mm a'
                        )}
                      </Box>
                      <Box>
                        <ArrowRightIcon size="md" />
                      </Box>
                    </Box>
                  </Link>
                </Box>
              ))}
            </Box>
          </Box>
          <StickyFooter>
            <Button as={Link} to={`/check-ins/${checkInId}`} size="lg" w="100%">
              Check-in
            </Button>
          </StickyFooter>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
})