import React, { useState, useEffect } from 'react'
import { Button, Flex, Heading } from '@chakra-ui/react'
import { VerticalSlider } from './vertical-slider'
import { StickyFooter } from '@components'
import { CheckInDtoV2, CheckInSliderVerticalNode } from '~/api/client/models'
import { useAppBarStore } from '@core/store'

interface LifestyleCorrelatesProps {
  checkIn: CheckInDtoV2
  onChange: (key: string, value: string) => void
  answers: Record<string, any>
  onSubmit: () => void
}

const STICKY_FOOTER_HEIGHT = 88

export const LifestyleCorrelates = ({
  checkIn,
  onChange,
  answers,
  onSubmit
}: LifestyleCorrelatesProps) => {
  const [currentStepIdx, setCurrentStepIdx] = useState<number>(0)
  const appStore = useAppBarStore()

  useEffect(() => {
    if (currentStepIdx === 0) {
      appStore.setOnBack(null)
    } else {
      appStore.setOnBack(() => {
        setCurrentStepIdx(currentStepIdx - 1)
      })
    }

  }, [currentStepIdx])

  const { content } = checkIn
  const node = content.nodes[currentStepIdx] as CheckInSliderVerticalNode
  const value = answers[node.id] || ''

  const isLastStep = content.nodes.length === currentStepIdx + 1

  useEffect(() => {
    if (value === '') {
      onChange(node.id, '0')
    }
  }, [node.id, value])

  const onNext = () => {
    if (isLastStep) {
      onSubmit()
    } else {
      setCurrentStepIdx(currentStepIdx + 1)
    }
  }

  return (
    <>
      <Flex
        flexDirection="column"
        height={`calc(100% - ${STICKY_FOOTER_HEIGHT}px)`}
      >
        <Heading fontSize="lg" mb="small" sx={{ textAlign: 'center' }}>
          {node.label}
        </Heading>
        <VerticalSlider
          minValue={node.minValue}
          maxValue={node.maxValue}
          minText={node.minLabel}
          maxText={node.maxLabel}
          value={Number(value)}
          type={node.id}
          onChange={value => onChange(node.id, value.toString())}
        />
      </Flex>
      <StickyFooter height={`${STICKY_FOOTER_HEIGHT}px`}>
        {isLastStep ? (
          <Button size="lg" w="100%" onClick={onNext}>
            Submit
          </Button>
        ) : (
          <Button
            size="lg"
            w="100%"
            onClick={onNext}
          >
            Next
          </Button>
        )}
      </StickyFooter>
    </>
  )
}
