import React from 'react'
import { Box, Container, GridItem, BoxProps } from '@chakra-ui/react'
import { LayoutGrid } from '@blueprinthq/joy'

interface StickyFooterProps extends BoxProps {
  children: JSX.Element
}

export const StickyFooter = ({ children, ...rest }: StickyFooterProps) => {
  return (
    <Box
      position="fixed"
      bottom="0px"
      left="0px"
      w="100%"
      bg="white"
      boxShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
      zIndex={9}
      {...rest}
    >
      <Container>
        <LayoutGrid>
          <GridItem
            py={4}
            colStart={{
              base: 1,
              sm: 2,
              md: 4
            }}
            colEnd={{
              base: 5,
              sm: 8,
              md: 10
            }}
          >
            {children}
          </GridItem>
        </LayoutGrid>
      </Container>
    </Box>
  )
}
