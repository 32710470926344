import React, { useEffect, useMemo, useState } from 'react'
import { useSearchParams, Link, useNavigate } from 'react-router-dom'
import { Box, useToast, Text } from '@chakra-ui/react'
import { Button } from '@blueprinthq/joy'
import { useAuthStore, usePublicUser } from '@core/hooks'
import { Loading } from '@components'
import { useFlags } from 'flagsmith/react'

import { 
  useCheckInsControllerV1GetCheckIns,
  useUserControllerV1PostSubmitCheckIn,
  useUserControllerV1GetAssignedCheckIns,
} from '~/api/public'
import { CheckInForm } from '@features'
import { CheckInFormCarousel } from '../../features/check-in-form-carousel'

import { CompletedForm } from './components'
import { FlagsmithFeature } from '@types'
import { ClientInviteStep } from '../public/accept-invite/accept-invite.view'

export const UserCheckInsView = () => {
  const { user } = usePublicUser()
  const authStore = useAuthStore()
  const toast = useToast()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const flags = useFlags([FlagsmithFeature.ACCEPT_INVITE_FLOW])
  const [isFinished, setIsFinished] = useState<boolean>(false)

  const shouldPromptAccountCreation = flags.accept_invite_flow.enabled && user.info.shouldPromptAccountCreation

  const { data: checkIn, isLoading } = useCheckInsControllerV1GetCheckIns(searchParams.get('checkInId')!, {}, {
    query: {
      enabled: !!searchParams.get('checkInId')
    }
  })

  const {
    data: checkIns,
    isLoading: isCheckInsLoading
  } = useUserControllerV1GetAssignedCheckIns(user.id, {
    query: {
      enabled: !searchParams.get('checkInId')
    }
  })

  const { mutateAsync: submitCheckIns } = useUserControllerV1PostSubmitCheckIn()

  const formCheckIns = useMemo(() => {
    return checkIn ? [checkIn] : checkIns!
  }, [checkIn, checkIns])

  const onSubmit = (checkInId: string, result: Record<string, any>) => {
    const _checkIn = formCheckIns.find((ci) => ci.id === checkInId)
    submitCheckIns({
      userId: user.id,
      data: [
        {
          moduleVersionId: _checkIn!.versionId,
          moduleId: _checkIn!.id,
          answers: Object.keys(result).map(key => ({
            key,
            value: result[key]
          }))
        }
      ]
    })
  }

  const gotoAccountButton = shouldPromptAccountCreation && (
    <Box mt="small">
      <Button
        variant="link"
        as={Link}
        to={authStore.isAuthenticated ? '/' : '/login'}
        state={{
          email: undefined
        }}
      >
        Log in to your account
      </Button>
    </Box>
  )

  useEffect(() => {
    if (
      isFinished &&
      shouldPromptAccountCreation &&
      user.type === 'patient'
    ) {
      toast({
        status: 'success',
        description: 'Check-in completed!'
      })

      if (user.info.hasOnboarded) {
        if (authStore.isAuthenticated) {
          navigate('/')
        } else {
          navigate('/login', {
            state: {
              email: undefined
            }
          })
        }
      } else {
        navigate('/accept-invite', {
          state: {
            step: ClientInviteStep.SETUP_ACCOUNT,
            email: undefined,
            patientId: user.relatedPatientId,
          }
        })
      }
    }
  }, [isFinished, user])

  if (isFinished) {
    return (
      <Box mt="large">
        <CompletedForm text="Check-in complete!">
          <>
            {gotoAccountButton}
          </>
        </CompletedForm>
      </Box>
    )
  }

  if (isLoading || isCheckInsLoading) {
    return (
      <Loading />
    )
  }

  if (formCheckIns.length === 0) {
    return (
      <Box>
        <Text align="center" fontSize="x-large" fontWeight="bold" mt="medium">
          This link is no longer available
        </Text>
      </Box>
    )
  }

  return (
    <CheckInFormCarousel
      userId={user.id}
      checkIns={formCheckIns}
      onSubmit={onSubmit}
      onAllFormsSubmitted={() => setIsFinished(true)}
    />
  )

}

