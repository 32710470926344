import React, { useState } from 'react'
import { Heading, Box, Text, Button, Flex } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

import { ForgotPasswordForm } from './components'
import { useWebViewStore, useOnNavBack } from '@core/hooks'
import { WebViewMessages } from '@types'

export const ForgotPasswordView = () => {
  const webViewStore = useWebViewStore()
  const navigate = useNavigate()


  useOnNavBack(() => {
    if (webViewStore.isReactNativeWebView) {
      webViewStore.postMessage(WebViewMessages.NAVIGATE_LOGIN)
    } else {
      navigate('/login')
    }
  })

  return (
    <Box w="100%">
      <Heading size="lg" mb="small">
        Forgot your password?
      </Heading>
      <Text>
        Enter your email address and we’ll send you instructions for resetting your password.
      </Text>
      <Box mt="small">
        <ForgotPasswordForm />
      </Box>
    </Box>
  )
}
