import React, { useState } from 'react'
import { Heading, Text, Box, useTheme, Button } from '@chakra-ui/react'
import { LifestyleGraphDto } from '~/api/client/models'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'

import {
  TimeRangeSelector,
  TimeRangeFilter
} from './components/time-range-selector'
import { EmptyChart } from './empty-chart'
import { LoadingChart } from './loading-chart'
import {
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  BarChart,
  Bar
} from 'recharts'
import { DateTime } from 'luxon'
import { meanBy, sumBy, inRange } from 'lodash'
import { buildChartIntervals, formatXAxisTick } from './utils'
import { useWebViewStore } from '@hooks'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

interface PhysicalActivityProps {
  data: LifestyleGraphDto[]
  isLoading: boolean
}

const physicalActivityTypes = ['steps', 'distance']

export const PhysicalActivityChart = ({
  data,
  isLoading
}: PhysicalActivityProps) => {
  const webviewStore = useWebViewStore()
  const [timeRange, setTimeRange] = useState<TimeRangeFilter>(
    TimeRangeFilter.ONE_WEEK
  )

  const physicalActivityData = data.filter(d =>
    physicalActivityTypes.includes(d.type)
  )

  const theme = useTheme()

  const getSummary = (value: number, type: string): string => {
    if (type === 'steps') {
      return `${Math.round(value).toLocaleString()} per day`
    } else if (type === 'distance') {
      return `${parseFloat(value.toFixed(2)).toLocaleString()} miles per day`
    } else {
      return ''
    }
  }

  const getTitle = (physicalActivityType: string): string => {
    switch (physicalActivityType) {
      case 'steps':
        return 'Steps'
      case 'distance':
        return 'Distance'
      default:
        return ''
    }
  }

  const formatYAxisTick = (type: string, val: number): string => {
    switch (type) {
      case 'steps':
        return val.toLocaleString()
      case 'distance':
        return val === 0 ? '0 mi' : `${val.toFixed(1).toLocaleString()} mi`
      default:
        return val.toLocaleString()
    }
  }

  const handleOpenAppleHealth = () => {
    const message = 'Ensure you have permissions enabled.\n\n' +
      '1. Open the Settings app.\n' +
      '2. Tap "Health"\n' +
      '3. Tap "Data Access & Devices"\n' +
      '4. Tap "Blueprint"\n' +
      '5. Select "Turn On All"'
    window.alert(message)
  }

  const intervals = buildChartIntervals(timeRange)

  return (
    <Box w="100%">
      <Heading size="lg">Physical activity</Heading>
      <Text mb={4}>
        Research indicates that increased physical activity is correlated with
        reduced depression.
      </Text>
      <Box mb={4}>
        <TimeRangeSelector
          value={timeRange}
          onChange={val => setTimeRange(val)}
        />
      </Box>
      {isLoading ? (
        <LoadingChart />
      ) : (
        <Box px={6} bg="white" borderRadius={8}>
          <Slider
            dots
            arrows={false}
            infinite={false}
            slidesToShow={1}
            slidesToScroll={1}
            adaptiveHeight
          >
            {physicalActivityData.map(physicalActivity => {
              const timeRangeData = physicalActivity.data.filter(d =>
                inRange(
                  DateTime.fromISO(d.date).toUnixInteger(),
                  intervals[0].start,
                  intervals[intervals.length - 1].end
                )
              )

              const chartData = intervals.map(interval => {
                const data = timeRangeData.filter(d =>
                  inRange(
                    DateTime.fromISO(d.date).toUnixInteger(),
                    interval.start,
                    interval.end
                  )
                )
                return {
                  date: interval.start,
                  value: sumBy(data, d => d.value)
                }
              })

              const avgValue =
                meanBy(
                  timeRangeData.filter(d => d.value !== 0),
                  d => d.value
                ) || 0

              return (
                <Box key={physicalActivity.type} minH={220}>
                  <Text mt={4}>{getTitle(physicalActivity.type)}</Text>
                  {timeRangeData.length > 0 ? (
                    <>
                      <Text color="primary">
                        {getSummary(avgValue, physicalActivity.type)}
                      </Text>
                      <ResponsiveContainer width="100%" height={180}>
                        <BarChart
                          width={500}
                          height={120}
                          data={chartData}
                          margin={{
                            top: 5,
                            left: 20,
                            bottom: 5
                          }}
                        >
                          <CartesianGrid
                            vertical={false}
                            stroke={theme.colors.pale_gray}
                          />
                          <XAxis
                            dataKey="date"
                            height={50}
                            tickLine={false}
                            tickFormatter={(val, idx) =>
                              formatXAxisTick(val, timeRange, idx === 0)
                            }
                            axisLine={{ stroke: theme.colors.pale_gray }}
                            style={{
                              fontSize: '12px',
                              color: theme.colors.dark_gray
                            }}
                          />
                          <YAxis
                            width={40}
                            tickLine={false}
                            tickFormatter={val =>
                              formatYAxisTick(physicalActivity.type, val)
                            }
                            axisLine={{ stroke: theme.colors.pale_gray }}
                            style={{
                              fontSize: '12px',
                              color: theme.colors.dark_gray
                            }}
                          />
                          <Bar
                            dataKey="value"
                            fill={theme.colors.primary}
                            isAnimationActive={false}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </>
                  ) : (
                    <EmptyChart message="To calculate this, we use data from the Apple Health app.">
                      <Button onClick={handleOpenAppleHealth} isFullWidth>
                        Show me how to turn this on
                      </Button>
                    </EmptyChart>
                  )}
                </Box>
              )
            })}
          </Slider>
        </Box>
      )}
    </Box>
  )
}
