import React from 'react'
import { Flex, Box, Text, Heading } from '@chakra-ui/react'
import { Button } from '@blueprinthq/joy'
import { StickyFooter } from '@components'

interface ConsentInfoProps {
  onAcceptConsent: () => void
  consentCopy: string
  children?: JSX.Element | null
}

export const ConsentInfo = ({
  onAcceptConsent,
  consentCopy
}: ConsentInfoProps) => {
  return (
    <Flex direction="column">
      <Box>
        <Heading size="lg">Consent to using Blueprint</Heading>
      </Box>
      <Box mt="small" textAlign="left" pb="128px">
        <Text>{consentCopy}</Text>
      </Box>
      <StickyFooter>
      <Box w="100%">
        <Button size="lg" isFullWidth onClick={onAcceptConsent}>
          I agree
        </Button>
      </Box>
      </StickyFooter>
    </Flex>
  )
}
