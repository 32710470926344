import { Button, TextField } from '@blueprinthq/joy'
import { Box, Heading, Text, useToast } from '@chakra-ui/react'
import {
  Field, FieldProps, Form,
  Formik
} from 'formik'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import InputMask from 'react-input-mask'
import { useQueryClient } from 'react-query'
import * as Yup from 'yup'
import { type VerifyBirthdayDtoTelecomType } from '~/api/public/models'
import { getUserControllerV1GetBirthdayVerificationStatusQueryKey, useUserControllerV1PostBirthdayVerification } from '~/api/public'


interface BirthdayVerificationProps {
  firstName: string
  telecomType: VerifyBirthdayDtoTelecomType
  userId: string
  userType: string
}

const BirthdayFormValidationSchema = Yup.object({
  dateOfBirth: Yup.date()
    .required()
    .max(DateTime.now(), "You can't be born in the future!")
    .min(DateTime.now().minus({ years: 130 }), 'Year is too far in the past.')
    .typeError('Please enter a valid date of birth')
})

export const BirthdayVerification = ({
  userId,
  firstName,
  userType,
  telecomType,
}: BirthdayVerificationProps) => {
  const [displayError, setDisplayError] = useState<undefined | string>(
    undefined
  )

  const toast = useToast()
  const queryClient = useQueryClient()

  const {
    mutate: checkBirthdayVerification,
  } = useUserControllerV1PostBirthdayVerification({
    mutation: {
      async onSuccess(data: any) {
        if (data?.success) {
          setDisplayError(undefined)
          const key = getUserControllerV1GetBirthdayVerificationStatusQueryKey(userId, { telecomType })
          await queryClient.invalidateQueries(key)
          toast({
            status: 'success',
            description: 'Verified date of birth!'
          })
        } else {
          setDisplayError('Incorrect date of birth, did you use the MM/dd/yyyy format? Contact your clinician for further assistance.')
        }
      },
      onError(error: any) {
        console.error('birthday verification failed', error)
        if (error.response?.data?.error?.message) {
          setDisplayError(error.response?.data.error.message)
        } else {
          setDisplayError(`Something went wrong, couldn't verify birthday`)
        }
      }
    }
  })

  const isParticipant = userType === 'participant'
  const displayName = isParticipant ? `${firstName}'s ` : 'Your'
  const descriptionName = isParticipant ? `${firstName}'s ` : 'your'

  return (
    <Box>
    <Heading mb="medium">{`Verify ${displayName} Date of Birth`}</Heading>
    <Box mb="small">
      <Text mt="medium">
        {`To verify your identity, please enter ${descriptionName} date of birth.`}
      </Text>
    </Box>
    <Formik
      initialValues={{
        dateOfBirth: '',
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={BirthdayFormValidationSchema}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true)

        await checkBirthdayVerification({
          userId, 
          data: { dateOfBirth: values.dateOfBirth, telecomType: telecomType }, 
        })
      }}
    >
      {({ errors, touched, isSubmitting }) => {
        return (
          <Form>
              <>
                <Box
                  w="100%"
                  pb={
                    errors.dateOfBirth && touched.dateOfBirth
                      ? 'small'
                      : 'initial'
                  }
                >
                  <Field name="dateOfBirth">
                    {({ field, form }: FieldProps) => (
                      <InputMask
                        mask={'99/99/9999'}
                        {...field}
                      >
                        {(inputProps: any) => (
                          <TextField
                            {...inputProps}
                            label="Date of Birth"
                            autoFocus
                            isRequired
                            isInvalid={
                              !!(errors.dateOfBirth && touched.dateOfBirth)
                            }
                            errorText={errors.dateOfBirth}
                            inputMode="numeric"
                          />
                        )}
                      </InputMask>
                    )}
                  </Field>
                </Box>
              </>
            {displayError && (
              <Text w="100%" mt="medium" color="error" align="left">
                {displayError}
              </Text>
            )}
            <Box w="100%">
              <Button
                mt="large"
                size="lg"
                w="100%"
                isLoading={isSubmitting}
                type="submit"
              >
                Verify & Continue
              </Button>
            </Box>
          </Form>
        )
      }}
    </Formik>
    </Box>
  )
}

