import React, { useState, useMemo, useCallback } from 'react'
import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { datadogLogs } from '@datadog/browser-logs'

import {
  AssessmentDto,
  AssessmentContentSectionQuestionDto,
  SubmitAssessmentsDto,
  SubmittedAssessmentAnswerBodyDto
} from '~/api/public/models'
import { AssessmentForm } from './components'
import { validate } from 'uuid'

interface AssessmentFormCarouselProps {
  assessments: AssessmentDto[]
  firstName: string
  userId: string
  onFormSubmitted?: (
    formAnswer: SubmitAssessmentsDto,
  ) => void
  onAllFormsSubmitted?: (
    formAnswers: SubmitAssessmentsDto[],
  ) => void
}


function buildAnswers(values: any, questions: AssessmentContentSectionQuestionDto[]): SubmittedAssessmentAnswerBodyDto[] {
  return Object.entries(
    values
  ).map(
    ([key, value]: [string, any]): SubmittedAssessmentAnswerBodyDto => {
      const question = questions.find((q) => q.key === key)
      if (question?.type === 'multi-select' && value.every(validate)) {
        return {
          key,
          answerValue: {
            answerIds: value,
          }
        }
      } else if (validate(value)) {
        return {
          key,
          answerValue: {
            answerId: value,
          }
        }
      }

      return {
        key,
        value,
      }
    }
  )
}

export const AssessmentFormCarousel = ({
  assessments,
  firstName,
  userId,
  onAllFormsSubmitted,
  onFormSubmitted
}: AssessmentFormCarouselProps) => {
  const [submittedForms, setSubbmitedForms] = useState<any>({})
  const [currentFormIndex, setCurrentFormIndex] = useState<number>(0)

  const selectedFormAssessment = useMemo(() => {
    return assessments[currentFormIndex]
  }, [currentFormIndex, assessments])

  const selectedSubmittedFormValues = useMemo(() => {
    return submittedForms[selectedFormAssessment.id]
  }, [selectedFormAssessment, submittedForms])

  const isLastAssessment = useMemo(() => {
    return currentFormIndex >= (assessments?.length || 0) - 1
  }, [assessments, currentFormIndex])

  const setFormIndex = (n: number) => {
    setCurrentFormIndex(n)

    // Goto top of page when form changes
    window.requestAnimationFrame(() => {
      window.scrollTo({
        top: 0
      })
    })
  }

  const gotoNextForm = useCallback(() => {
    setFormIndex(currentFormIndex + 1)
  }, [currentFormIndex])

  const _onSubmitForm = useCallback(
    async (formAssessmentId: string, values: any) => {
      datadogLogs.logger.info('_onSubmitForm', { userId, assessmentId: formAssessmentId })
      const formValues = { ...submittedForms, [formAssessmentId]: values }
      setSubbmitedForms(formValues)

      const questions = selectedFormAssessment.content.sections[0].questions

      const answers = buildAnswers(values, questions)

      const formAnswer: SubmitAssessmentsDto = {
        id: formAssessmentId,
        answers
      }

      if (onFormSubmitted) {
        await onFormSubmitted(
          formAnswer
        )
      }


      if (isLastAssessment) {
        const submittedForms: SubmitAssessmentsDto[] = Object.entries(
          formValues
        ).map(
          ([assessmentId, values]: [
            string,
            any
          ]): SubmitAssessmentsDto => {
            const assessment = assessments.find((a) => a.id === assessmentId)
            const assessmentQuestions = assessment?.content.sections[0].questions

            // @ts-ignore
            const answers = buildAnswers(values, assessmentQuestions)

            return {
              id: assessmentId,
              answers
            }
          }
        )

        if (onAllFormsSubmitted) {
          await onAllFormsSubmitted(
            submittedForms,
          )
        } else {
          datadogLogs.logger.error('onAllFormsSubmitted not defined', { userId, formAssessmentId })
        }
      } else {
        gotoNextForm()
      }
    },
    [selectedFormAssessment, submittedForms, isLastAssessment, assessments, onAllFormsSubmitted, gotoNextForm]
  )

  return (
    <Box>
      <Box mb="large">
        <Flex align="left" flexDirection="column" w="100%">
          <Heading size="lg">
           {firstName ? `${firstName}'s Assessments`: 'Assessments' }
          </Heading>
          <Text>Please complete all questions below.</Text>
        </Flex>
      </Box>
      <AssessmentForm
        assessment={selectedFormAssessment}
        userId={userId}
        values={selectedSubmittedFormValues}
        onSubmit={_onSubmitForm}
        isLastAssessment={isLastAssessment}
      />
    </Box>
  )
}
