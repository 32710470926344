import React, { useEffect } from 'react'
import { Box, useBoolean } from '@chakra-ui/react'
import { AppBar } from '@features'
import { Outlet, useParams } from 'react-router-dom'
import { useUserControllerV1GetUser } from '~/api/public'
import { datadogLogs } from '@datadog/browser-logs'
import { observer } from 'mobx-react-lite'
import { useAuthStore, useWebViewStore, useIsFromMagicLink } from '@core/hooks'
import { Loading } from '@components'
import { useFlagsmith } from 'flagsmith/react'
import { useAppBarStore } from '@core/store'

import { PublicUserVerification } from './public-user-verification'

export const PublicUserLayout = observer(() => {
  const { userId } = useParams()
  const authStore = useAuthStore()
  const webViewStore = useWebViewStore()
  const flagsmith = useFlagsmith()
  const appBarStore = useAppBarStore()
  const [isFlagsmithLoading, flagsmithLoading] = useBoolean(true)

  const isFromMagicLink = useIsFromMagicLink()

  const platform = webViewStore.isReactNativeWebView
    ? // @ts-ignore
      window.reactNativePlatform
    : 'web'

  useEffect(() => {
    async function attemptSessionSoftResume() {
      // Soft resume session if available
      try {
        await authStore.resumeSession()
      } catch (err) {
        // Don't need to handle error since it's okay if user isn't authed here
      }
    }
    attemptSessionSoftResume()
  }, [])

  const {
    data: user,
    isLoading,
    isError,
    error,
    isIdle
  } = useUserControllerV1GetUser(userId || '', {
    query: {
      enabled: !!userId
    }
  })

  useEffect(() => {
    if (user) {
      appBarStore.setLogoUrl(user.clinic.logoUrl)

      flagsmith.identify(user.id, {
        clinicId: user.clinicId,
        patientId: user.relatedPatientId
      }).then(() => {
        flagsmithLoading.off()
      })

      datadogLogs.setUser({ id: user.id, type: user.type })
      datadogLogs.logger.info('Monitoring public user.')
    }
  }, [user])

  // If user is coming from a magic link then a cold load will need to happen, this avoids the clinic logo from 'blinking'
  if (isFromMagicLink && (isFlagsmithLoading || (user?.clinic.logoUrl && !appBarStore.logoUrl))) {
    return (<Loading isFullPage />)
  }

  const content = (
    <>
      {isLoading ? (
        <Loading isFullPage />
      ) : (
        <PublicUserVerification user={user!}>
          <Outlet
            context={{
              user
            }}
          />
        </PublicUserVerification>
      )}
    </>
  )

  const errorContent: JSX.Element = (
    <Box mt="large">{error?.response?.data.error.message}</Box>
  )

  return (
    <>
      <AppBar />
      {isError || isIdle ? errorContent : content}
    </>
  )
})
