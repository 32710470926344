import React, { useMemo, useRef } from 'react'
import {
  Box,
  Text,
  Heading,
  useToast,
  Tooltip,
  Flex
} from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import { AddCircleIcon } from '@blueprinthq/joy'
import { useOnNavBack, useWebViewStore } from '@hooks'

import { useQueryClient } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'
import { InfoIcon } from '@blueprinthq/joy'
import {
  useUsersControllerV1GetNotificationPreferences,
  getUsersControllerV1GetNotificationPreferencesQueryKey,
  useUsersControllerV1DeleteNotificationPreference,
  useUsersControllerV1GetActiveOptionalComms,
  getUsersControllerV1GetActiveOptionalCommsQueryKey,
  useUsersControllerV1UpdateActiveOptionalComms,
} from '~/api/client'
import { WebViewMessages, OptionalComms } from '@types'
import {
  ConfirmDeleteCheckInReminderDialog,
  NotificationSection,
} from './components'
import { AppContainer } from '@components'
import { useUserControllerV1PostSubscribeUser } from '~/api/public'
import { usePatientProfileStore } from '@core/store'
import { observer } from 'mobx-react-lite'

export const NotificationsView = observer(() => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const webviewStore = useWebViewStore()
  const { activeProfile } = usePatientProfileStore()

  let formRef = useRef(null)

  useOnNavBack(() => {
    if (webviewStore.isReactNativeWebView && webviewStore.isUsingOldNav()) {
      webviewStore.postMessage(WebViewMessages.NAVIGATE_SETTINGS)
    } else {
      navigate('/settings')
    }
  })

  const { data: checkInReminders = [] } =
    useUsersControllerV1GetNotificationPreferences(activeProfile.userId, {
      notificationType: 'check_in'
    })

  const {
    data: activeOptionalComms = {
      sms: [],
      push: [],
      email: [],
      isPushSubscribed: false,
      isSmsSubscribed: false,
      isEmailSubscribed: false
    }
  } = useUsersControllerV1GetActiveOptionalComms(activeProfile.userId)

  const checkInRemindersEnabled = checkInReminders.some(r => r.isActive)

  const { mutate: deleteReminder } =
    useUsersControllerV1DeleteNotificationPreference({
      mutation: {
        onSuccess() {
          toast({
            title: 'Reminder deleted',
            status: 'success',
            duration: 2000,
            isClosable: true
          })
          queryClient.invalidateQueries(
            getUsersControllerV1GetNotificationPreferencesQueryKey(activeProfile.userId, {
              notificationType: 'check_in'
            })
          )
        }
      }
    })

  const { mutate: saveActiveOptionalComms } =
  useUsersControllerV1UpdateActiveOptionalComms({
      mutation: {
        onSuccess() {
          queryClient.invalidateQueries(
            getUsersControllerV1GetActiveOptionalCommsQueryKey(activeProfile.userId)
          )
        },
        onError() {
          toast({
            title: 'Unable to save your preference',
            status: 'error',
            duration: 2000,
            isClosable: true
          })
          // @ts-ignore
          formRef.current && formRef.current.resetForm()
        }
      }
    })

    const { mutate: saveSubscribeClientToEmails } =
    useUserControllerV1PostSubscribeUser({
      mutation: {
        onSuccess() {
          queryClient.invalidateQueries(
            getUsersControllerV1GetActiveOptionalCommsQueryKey(activeProfile.userId)
          )
        },
        onError() {
          toast({
            title: 'Unable to save your preference',
            status: 'error',
            duration: 2000,
            isClosable: true
          })
          // @ts-ignore
          formRef.current && formRef.current.resetForm()
        }
      }
    })


  const initialValues = useMemo(
    () => ({
      SCHEDULED_ASSESSMENT_ADMINISTRATION_push: activeOptionalComms.push?.some(
        p => p === OptionalComms.SCHEDULED_ASSESSMENT_ADMINISTRATION
      ),
      SCHEDULED_ASSESSMENT_ADMINISTRATION_email: !activeOptionalComms.isEmailSubscribed ? false : activeOptionalComms.email?.some(
        p => p === OptionalComms.SCHEDULED_ASSESSMENT_ADMINISTRATION
      ) ,
      SCHEDULED_ASSESSMENT_ADMINISTRATION_sms: activeOptionalComms.sms?.some(
        p => p === OptionalComms.SCHEDULED_ASSESSMENT_ADMINISTRATION
      ),
      SCHEDULED_CHECKIN_REMINDER_push: activeOptionalComms.push?.some(
        p => p === OptionalComms.SCHEDULED_CHECKIN_REMINDER
      ),
      SCHEDULED_CHECKIN_REMINDER_email: activeOptionalComms.email?.some(
        p => p === OptionalComms.SCHEDULED_CHECKIN_REMINDER
      ),
      SCHEDULED_CHECKIN_REMINDER_sms: activeOptionalComms.sms?.some(
        p => p === OptionalComms.SCHEDULED_CHECKIN_REMINDER
      ),
      CLIENT_SAFETY_NET_push: activeOptionalComms.push?.some(
        p => p === OptionalComms.CLIENT_SAFETY_NET
      ),
      CLIENT_SAFETY_NET_email: activeOptionalComms.email?.some(
        p => p === OptionalComms.CLIENT_SAFETY_NET
      ),
      CLIENT_SAFETY_NET_sms: activeOptionalComms.sms?.some(
        p => p === OptionalComms.CLIENT_SAFETY_NET
      )
    }),
    [activeOptionalComms]
  )

  const onSubmit = (values: any) => {
    const pushArray = []
    const emailArray = []
    const smsArray = []

    if (values.SCHEDULED_ASSESSMENT_ADMINISTRATION_push)
      pushArray.push(OptionalComms.SCHEDULED_ASSESSMENT_ADMINISTRATION)
    if (values.SCHEDULED_ASSESSMENT_ADMINISTRATION_email)
      emailArray.push(OptionalComms.SCHEDULED_ASSESSMENT_ADMINISTRATION)
      saveSubscribeClientToEmails({ userId: activeProfile?.userId})
    if (values.SCHEDULED_ASSESSMENT_ADMINISTRATION_sms)
      smsArray.push(OptionalComms.SCHEDULED_ASSESSMENT_ADMINISTRATION)
    if (values.SCHEDULED_CHECKIN_REMINDER_push)
      pushArray.push(OptionalComms.SCHEDULED_CHECKIN_REMINDER)
    if (values.SCHEDULED_CHECKIN_REMINDER_email)
      emailArray.push(OptionalComms.SCHEDULED_CHECKIN_REMINDER)
    if (values.SCHEDULED_CHECKIN_REMINDER_sms)
      smsArray.push(OptionalComms.SCHEDULED_CHECKIN_REMINDER)
    if (values.CLIENT_SAFETY_NET_push)
      pushArray.push(OptionalComms.CLIENT_SAFETY_NET)
    if (values.CLIENT_SAFETY_NET_email)
      emailArray.push(OptionalComms.CLIENT_SAFETY_NET)
    if (values.CLIENT_SAFETY_NET_sms)
      smsArray.push(OptionalComms.CLIENT_SAFETY_NET)

    const data = {
      push: pushArray,
      email: emailArray,
      sms: smsArray
    }

    return saveActiveOptionalComms({ data, userId: activeProfile.userId })
  }

  return (
    <AppContainer
      title={
        <Box mb="medium">
          <Heading size="lg">Notifications</Heading>
          <Text>Manage how we contact you.</Text>
        </Box>
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize={true}
        innerRef={formRef}
      >
        <Form>
          <NotificationSection
            title="Assessments"
            subtitle="Choose how you want to receive your clinical assessments"
            activeOptionalComms={activeOptionalComms}
            messageType={OptionalComms.SCHEDULED_ASSESSMENT_ADMINISTRATION}
            switchesEnabled
          />
          <NotificationSection
            title="Check-in Reminders"
            subtitle="Set daily or weekly check-in reminders"
            activeOptionalComms={activeOptionalComms}
            messageType={OptionalComms.SCHEDULED_CHECKIN_REMINDER}
            switchesEnabled={checkInRemindersEnabled}
          >
            <Box px={4}>
              {checkInReminders.map((reminder, i) => (
                <Box
                  py={4}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  _first={{ borderTopWidth: '0.5px' }}
                  key={`checkin-reminder-${i}`}
                  borderBottomWidth="0.5px"
                >
                  <Box>{reminder.daysOfWeek.join(', ')}</Box>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Box>
                      <Text color="dark_gray">
                        {DateTime.fromISO(reminder.timeOfDay).toLocaleString(
                          DateTime.TIME_SIMPLE
                        )}
                      </Text>
                    </Box>
                    <Box ml={5}>
                      <ConfirmDeleteCheckInReminderDialog
                        onDeleteReminder={() =>
                          deleteReminder({
                            userId: activeProfile.userId,
                            id: reminder.id 
                          })
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              ))}
              <Box py={4} display="flex" alignItems="center" color="primary">
                <AddCircleIcon fill="primary" />
                <Link to="/settings/check-in-reminders/new">
                  <Text ml={2}>Add reminder</Text>
                </Link>
              </Box>
            </Box>
          </NotificationSection>
          <NotificationSection
            title="Safety Net"
            subtitle={
              <Flex>
                <Text>
                  Choose how you want to receive safety net notifications
                </Text>
                <Tooltip label="Your clinician may override your communication preferences in certain instances. Chat with your clinician to learn more.">
                  <Box ml="xxsmall">
                    <InfoIcon fill="gray" />
                  </Box>
                </Tooltip>
              </Flex>
            }
            activeOptionalComms={activeOptionalComms}
            messageType={OptionalComms.CLIENT_SAFETY_NET}
            switchesEnabled
          />
        </Form>
      </Formik>
    </AppContainer>
  )
})