import React, { useState } from 'react'
import { Box, VStack, Link, Text } from '@chakra-ui/react'
import { Button, TextField } from '@blueprinthq/joy'
import { Formik, Field, Form, FieldProps } from 'formik'
import * as Yup from 'yup'
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom'

import { useAuthStore } from '@core/hooks'

const LoginValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required')
})

interface LoginFormLocationState {
  state: {
    email?: string | null;
  }
}

export const LoginForm = () => {
  const authStore = useAuthStore()
  const navigate = useNavigate()
  const location = useLocation() as LoginFormLocationState
  const [loginError, setLoginError] = useState(null)

  return (
    <Formik
      validationSchema={LoginValidationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={{
        email: location.state?.email || '',
        password: ''
      }}
      validate={() => setLoginError(null)}
      onSubmit={async values => {
        try {
          await authStore.login(values.email, values.password)
          navigate('/')
        } catch (error: any) {
          setLoginError(error.message)
        }
      }}
    >
      {({ errors, touched, isSubmitting, values }) => (
        <Form>
          <VStack spacing="small" mb="medium" alignItems="flex-start">
            <Box
              w="100%"
              mb={errors.email && touched.email ? 'small' : 'initial'}
            >
              <Field name="email">
                {({ field }: FieldProps) => (
                  <TextField
                    {...field}
                    isRequired
                    label="Email"
                    isInvalid={!!(errors.email && touched.email)}
                    errorText={errors.email}
                  />
                )}
              </Field>
            </Box>
            <Box
              w="100%"
              pb={errors.password && touched.password ? 'small' : 'initial'}
            >
              <Field name="password">
                {({ field }: FieldProps) => (
                  <TextField
                    {...field}
                    isRequired
                    label="Password"
                    type="password"
                    isInvalid={!!(errors.password && touched.password)}
                    errorText={errors.password}
                  />
                )}
              </Field>
            </Box>
            {loginError && <Text color="error">{loginError}</Text>}
            <Link
              as={RouterLink}
              color="primary"
              alignSelf="flex-start"
              to={{
                pathname: '/forgot-password',
                // @ts-ignore
                state: {
                  email: values.email
                }
              }}
            >
              Forgot your password?
            </Link>

            <Button
              size="lg"
              my="small"
              width="100%"
              isLoading={isSubmitting}
              type="submit"
            >
              Log in
            </Button>
          </VStack>
        </Form>
      )}
    </Formik>
  )
}
