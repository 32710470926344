import React, { useRef } from 'react'
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  VStack,
  Flex,
  IconButton,
} from '@chakra-ui/react'
import { Button, CloseIcon, TrashcanIcon } from '@blueprinthq/joy'

interface Props {
  onDeleteReminder: () => void
}

export function ConfirmDeleteCheckInReminderDialog({ onDeleteReminder }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  const handleConfirm = () => {
    onDeleteReminder()
    onClose()
  }

  return (
    <>
      <IconButton
        size="xs"
        aria-label="Delete reminder"
        variant="ghost"
        icon={<CloseIcon size="md" />}
        onClick={onOpen}
      />
      <AlertDialog
        motionPreset='slideInBottom'
        isOpen={isOpen}
        // @ts-ignore
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay display="flex">
          <AlertDialogContent alignSelf="center" mx="medium" py="medium" borderRadius="32px">
            <Flex direction="column" align="center">
              <TrashcanIcon fill="error" size="md" />
              <AlertDialogHeader mt="-2px" fontSize={22} fontWeight="bold">
                Delete Reminder
              </AlertDialogHeader>
              {/* @ts-ignore */}
              <AlertDialogBody mt="-22px" fontSize="16px" align="center">
                Are you sure you want to delete this check-in reminder?
              </AlertDialogBody>
            </Flex>
            <AlertDialogFooter>
              <VStack spacing="small" w="100%">
                <Button variant="severe" w="100%" size="xl" onClick={handleConfirm}>
                  Delete Reminder
                </Button>
                {/* @ts-ignore */}
                <Button w="100%" size="xl" variant="outline" ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
              </VStack>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

