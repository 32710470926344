import Axios, { AxiosRequestConfig, AxiosError } from 'axios'

import { AuthStore, WebViewStore, PatientProfileStore } from '../store'
import { WebViewMessages } from '@types'
import semver from 'semver'

export type ErrorType<Error> = AxiosError<Error>

function createAxiosInstance(baseURL: string) {
  const AXIOS_INSTANCE = Axios.create({
    baseURL,
  })
  
  AXIOS_INSTANCE.interceptors.request.use(
    async (config) => {
      const authStore = AuthStore.instance()
      const webViewStore = WebViewStore.instance()
      const patientProfileStore = PatientProfileStore.instance()

      let jwtToken = null
      if (authStore.isAuthenticated) {
        try {
          // Will refresh token in the background if expired
          jwtToken = await authStore.getJWTAccessToken()
        } catch (err) {
          if (webViewStore.isReactNativeWebView) {
            webViewStore.postMessage(WebViewMessages.ACTION_AUTH_INVALID)
          } else {
            // Log user out if token isn't accessible
            await authStore.logout()
            return Promise.reject(err)
          }
  
        }
      }
  
      if (jwtToken !== null) {
        // @ts-ignore
        config.headers['Authorization'] = `Bearer ${jwtToken}`
      }
  
      if (patientProfileStore.activeProfile) {
        // @ts-ignore
        config.headers['x-profile-user-id'] = patientProfileStore.activeProfile.id
      }
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )
  
  AXIOS_INSTANCE.interceptors.response.use(
    // res func was messing res up, data would always be undefined
    res => res,
    async err => {
      // anything above 2xx status
      const authStore = AuthStore.instance()
      const webViewStore = WebViewStore.instance()
  
      const { config, response } = err
  
      if (response.status === 401 && authStore.isAuthenticated) {
        if (!config._retry) {
          config._retry = true
          try {
            if (semver.lte(webViewStore.version, '3.2.6')) {
              await authStore.resumeSession(false) // attempt to refresh cognito token
              return AXIOS_INSTANCE(config)
            } else {
              await authStore.resumeSession(true) // attempt to refresh cognito token
            }
            const _retryResponse = await AXIOS_INSTANCE(config) // retry the call
            return _retryResponse
          } catch (_error) {
            if (webViewStore.isReactNativeWebView) {
              webViewStore.postMessage(WebViewMessages.ACTION_AUTH_INVALID)
            } else {
              await authStore.logout()
              return Promise.reject(_error)
            }
          }
        } else {
          if (webViewStore.isReactNativeWebView) {
            webViewStore.postMessage(WebViewMessages.ACTION_AUTH_INVALID)
          } else {
            await authStore.logout()
          }
        }
      } else {
        return Promise.reject(err)
      }
    }
  )
  
  return AXIOS_INSTANCE
}

const coreApiAxiosInstance = createAxiosInstance(process.env.REACT_APP_NODE_API_ROOT_URL)
export const coreApiInstance = async <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<T> => {
  const { data } = await coreApiAxiosInstance({
    ...config,
    ...options,
    headers: {
      ...options?.headers,
    }
  })

  return data;
};

const hellojoyApiAxiosInstance = createAxiosInstance(process.env.REACT_APP_NODE_HELLOJOY_API_ROOT_URL)
export const hellojoyApiInstance = async <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<T> => {
  const { data } = await hellojoyApiAxiosInstance({
    ...config,
    ...options,
    headers: {
      ...options?.headers,
    }
  })

  return data;
};
