import React, { useState } from 'react'
import { Box, Button } from '@chakra-ui/react'

const DAYS = [
  {
    key: 'Sun',
    label: 'S'
  },
  {
    key: 'Mon',
    label: 'M'
  },
  {
    key: 'Tue',
    label: 'T'
  },
  {
    key: 'Wed',
    label: 'W'
  },
  {
    key: 'Thu',
    label: 'T'
  },
  {
    key: 'Fri',
    label: 'F'
  },
  {
    key: 'Sat',
    label: 'S'
  }
]

interface DayOfWeekSelectorProps {
  selectedDays: string[]
  showEveryDayOption?: boolean
  onChange: (selectedDays: string[]) => void
}

export const DayOfWeekSelector = ({
  selectedDays,
  showEveryDayOption = false,
  onChange
}: DayOfWeekSelectorProps) => {
  const [isEveryDaySelected, setIsEveryDaySelected] = useState(false)

  const handleDaySelect = (value: string) => {
    let newState: string[] = [...selectedDays]

    setIsEveryDaySelected(false)

    if (selectedDays.includes(value)) {
      newState = newState.filter(d => d !== value)
      onChange(newState)
    } else {
      newState.push(value)
      onChange(newState)
    }
  }

  const handleEveryDaySelected = () => {
    setIsEveryDaySelected(!isEveryDaySelected)
    if (!isEveryDaySelected) {
      onChange(DAYS.map(d => d.key))
    } else {
      onChange([])
    }
  }

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        gap="xsmall"
        mb="xsmall"
      >
        {DAYS.map((day, index) => {
          const isSelected =
            !isEveryDaySelected && selectedDays.includes(day.key)

          return (
            <Button
              key={index}
              variant={isSelected ? 'solid' : 'outline'}
              width="100%"
              maxH="40px"
              m={0}
              borderRadius="4px"
              borderColor="pale_gray"
              onClick={() => handleDaySelect(day.key)}
            >
              {day.label}
            </Button>
          )
        })}
      </Box>
      {showEveryDayOption && (
        <Box>
          <Button
            variant={isEveryDaySelected ? 'solid' : 'outline'}
            h="40px"
            isFullWidth
            m={0}
            borderRadius="4px"
            borderColor="pale_gray"
            onClick={handleEveryDaySelected}
          >
            Every Day
          </Button>
        </Box>
      )}
    </Box>
  )
}
