import React from 'react'
import { Text, Box, Button } from '@chakra-ui/react'

interface EmptyChartProps {
  message: string
  children?: React.ReactNode;
}

export const EmptyChart = ({ message, children }: EmptyChartProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p="medium"
    >
      <Text fontSize="xl" textAlign="center">
        🔒
      </Text>
      <Text mb="xsmall" textAlign="center" fontSize="md" color="dark_gray">
        {message}
      </Text>
      {
        children
      }
    </Box>
  )
}
