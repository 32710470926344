import React from 'react'
import { HStack } from '@chakra-ui/react'
import { Button } from '@blueprinthq/joy'

export enum TimeRangeFilter {
  ONE_WEEK = '1_week',
  ONE_MONTH = '1_month',
  SIX_MONTHS = '6_months',
  ALL_TIME = 'all'
}

const displayMap = {
  [TimeRangeFilter.ONE_WEEK]: 'W',
  [TimeRangeFilter.ONE_MONTH]: 'M',
  [TimeRangeFilter.SIX_MONTHS]: '6M',
  [TimeRangeFilter.ALL_TIME]: 'Y'
}

interface TimeRangeSelectorProps {
  value: TimeRangeFilter
  onChange: (val: TimeRangeFilter) => void
}

export const TimeRangeSelector = ({
  value,
  onChange
}: TimeRangeSelectorProps) => {
  const options = Object.values(TimeRangeFilter)

  return (
    <HStack justifyContent="space-between" gap="0" width="100%">
      {options.map(option => (
        <Button
          cursor="pointer"
          size="sm"
          height="32px"
          margin="0"
          width="100%"
          key={option}
          borderRadius="full"
          variant={value === option ? 'solid' : 'transparent'}
          justifyContent="center"
          onClick={() => onChange(option)}
        >
          {displayMap[option]}
        </Button>
      ))}
    </HStack>
  )
}
