import React from 'react'
import { makeAutoObservable } from 'mobx'

export class AppBarStore {
  private static globalInstance: AppBarStore | null = null

  leftComponent: JSX.Element | null = null
  rightComponent: JSX.Element | null = null
  isCheckInButtonVisible: boolean = false
  onBack: (() => void) | null = null
  logoUrl: string | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setOnBack(callback: (() => void) | null) {
    this.onBack = callback
  }

  setLeftComponent(element: JSX.Element | null) {
    this.leftComponent = element
  }

  setRightComponent(element: JSX.Element | null) {
    this.rightComponent = element
  }

  toggleCheckInVisibility(isVisible: boolean) {
    this.isCheckInButtonVisible = isVisible
  }

  setLogoUrl(logoUrl: string | null) {
    this.logoUrl = logoUrl
  }

  static instance() {
    if (this.globalInstance === null) {
      this.globalInstance = new AppBarStore()
    }

    return this.globalInstance
  }
}

// @ts-ignore
export const AppBarStoreContext = React.createContext<AppBarStore>(null)

export const AppBarStoreProvider = ({ children }: any) => {
  const store = AppBarStore.instance()
  return (
    <AppBarStoreContext.Provider value={store}>
      {children}
    </AppBarStoreContext.Provider>
  )
}

export const useAppBarStore = () => React.useContext(AppBarStoreContext)
