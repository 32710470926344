import React, { forwardRef, createRef, useMemo, useRef } from 'react'
import {
  Box,
  Text,
  Radio,
  RadioGroup,
  Stack,
  FormControl,
  FormErrorMessage,
  Textarea,
  Divider,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberDecrementStepper,
  NumberIncrementStepper
} from '@chakra-ui/react'
import { FastField, FastFieldProps } from 'formik'
import { Checkbox, TextField } from '@blueprinthq/joy'
import { isMobileOnly } from 'react-device-detect'

import { useScrollToInvalidField } from '@core/hooks'
import {
  AssessmentContentSectionQuestionDto,
  AssessmentContentSectionAnswerDto
} from '~/api/public/models'

interface AssessmentFormFieldProps {
  question: AssessmentContentSectionQuestionDto
  answers: AssessmentContentSectionAnswerDto[]
  number: number
}

const NonmemoizedAssessmentFormField = ({
  question,
  answers,
  number
}: AssessmentFormFieldProps) => {
  const ref = useScrollToInvalidField<HTMLDivElement>(question.key)

  const renderField = () => {
    if (question.type === 'multi-select') {
      return (
        <AssessmentFormFieldCheckbox
          ref={ref}
          question={question}
          answers={answers}
        />
      )
    } else if (question.type === 'free_text') {
      return <AssessmentFormFieldFreetext ref={ref} question={question} />
    } else if (question.type === 'number') {
      return (
        <AssessmentFormFieldNumber ref={ref} question={question} />
      )
    }
    else {
      return (
        <AssessmentFormFieldRadio
          ref={ref}
          question={question}
          answers={answers}
        />
      )
    }
  }

  return (
    <Box key={question.key} w="100%">
      <Text fontWeight="bold" mb={question.skippable ? 'xsmall' : 'small'}>
        {number}. {question.title}
      </Text>
      {question.skippable && (
        <Text color="dark_gray" mb="small">
          You may optionally skip this question.
        </Text>
      )}
      {renderField()}
    </Box>
  )
}

export const AssessmentFormField = React.memo(
  NonmemoizedAssessmentFormField,
  (prevProps, nextProps) => {
    return prevProps.question.key === nextProps.question.key
  }
)

interface AssessmentFormFieldCheckboxProps {
  question: AssessmentContentSectionQuestionDto
  answers: AssessmentContentSectionAnswerDto[]
}

const AssessmentFormFieldCheckbox = forwardRef<
  HTMLDivElement,
  AssessmentFormFieldCheckboxProps
>(({ question, answers }, ref) => {
  return (
    <FastField name={question.key} type="checkbox">
      {({ field, form }: FastFieldProps) => {
        // Form field structure gets updated sometimes before new values change
        if (!(field.name in form.values)) {
          return null
        }
        return (
          <FormControl
            isInvalid={
              form.errors[field.name] !== undefined &&
              form.touched[field.name] !== undefined
            }
          >
            <Stack 
              direction="column"
              ref={ref}
              spacing="0px"
              border="1px solid"
              borderColor="pale_gray"
              borderRadius="4px"
              divider={<Divider />}
            >
              {answers.map((option, i) => (
                <Box
                  key={i}
                  w="100%"
                  _hover={{
                    bg: !isMobileOnly ? 'blue.50' : 'initial'
                  }}
                >
                  <Checkbox
                    {...field}
                    p="xsmall"
                    value={option.id || option.value.toString()}
                    w="100%"
                    isChecked={form.values[field.name].includes(
                      (option.id ? option.id : option.value.toString())
                    )}
                    onChange={e => {
                      let values = form.values[field.name]

                      if (option.isOverride) {
                        const hasOverride = values.find(
                          (val: string) => val === e.target.value
                        )
                        if (hasOverride) {
                          form.setFieldValue(field.name, [])
                        } else {
                          form.setFieldValue(field.name, [e.target.value])
                        }
                      } else {
                        const overrideAnswer = answers.find(
                          a => a.isOverride === true
                        )
                        if (overrideAnswer !== null) {
                          values = values.filter(
                            (val: string) =>
                              val !== overrideAnswer?.value.toString()
                          )
                        }

                        if (values.includes(e.target.value)) {
                          values = values.filter(
                            (val: string) => val !== e.target.value
                          )
                        } else {
                          values = [...values, e.target.value]
                        }

                        form.setFieldValue(field.name, values)
                      }
                    }}
                  >
                    {option.title}
                  </Checkbox>
                </Box>
              ))}
            </Stack>
            <FormErrorMessage>
              {form.errors !== undefined && form.errors[field.name]?.toString()}
            </FormErrorMessage>
          </FormControl>
        )
      }}
    </FastField>
  )
})

interface AssessmentFormFieldRadioProps {
  question: AssessmentContentSectionQuestionDto
  answers: AssessmentContentSectionAnswerDto[]
}

const AssessmentFormFieldRadio = forwardRef<
  HTMLDivElement,
  AssessmentFormFieldRadioProps
>(({ question, answers }, ref) => {
  return (
    <FastField name={question.key}>
      {({ field, form }: FastFieldProps) => {
        return (
          <FormControl
            isInvalid={
              form.errors[field.name] !== undefined &&
              form.touched[field.name] === true
            }
          >
            <RadioGroup
              ref={ref}
              {...field}
              onChange={value => {
                form.setFieldValue(field.name, value)
              }}
            >
              <Stack 
                direction={'column'} 
                spacing="0px"
                border="1px solid"
                borderColor="pale_gray"
                borderRadius="4px"
                divider={<Divider />}
              >
                {answers.map((option, i) => (
                  <Box
                    key={i}
                    w="100%"
                    _hover={{
                      bg: !isMobileOnly ? 'blue.50' : 'initial'
                    }}
                  >
                    <Radio p="xsmall" value={option.id || option.value.toString()} w="100%">
                      {option.title.toString()}
                    </Radio>
                  </Box>
                ))}
              </Stack>
            </RadioGroup>
            <FormErrorMessage>
              {form.errors !== undefined && form.errors[field.name]?.toString()}
            </FormErrorMessage>
          </FormControl>
        )
      }}
    </FastField>
  )
})

interface AssessmentFormFieldFreetextProps {
  question: AssessmentContentSectionQuestionDto
}

const AssessmentFormFieldFreetext = forwardRef<
  HTMLDivElement,
  AssessmentFormFieldFreetextProps
>(({ question }, ref) => {
  return (
    <FastField name={question.key}>
      {({ field, form }: FastFieldProps) => {
        return (
          <FormControl
            isInvalid={
              form.errors[field.name] !== undefined &&
              form.touched[field.name] === true
            }
          >
            <Box ref={ref}>
              <Textarea {...field} maxHeight="200px" />
            </Box>
            <FormErrorMessage>
              {form.errors !== undefined && form.errors[field.name]?.toString()}
            </FormErrorMessage>
          </FormControl>
        )
      }}
    </FastField>
  )
})

interface AssessmentFormFieldNaturalNumberProps {
  question: AssessmentContentSectionQuestionDto
}


const AssessmentFormFieldNumber = forwardRef<
  HTMLDivElement,
  AssessmentFormFieldNaturalNumberProps
>(({ question }, ref) => {

  const inputRef = useRef<HTMLInputElement>(null);

  const onHandleInputEvent = (e: React.KeyboardEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>) => {
    // Only allows positive and negative integers
    if (/^-?[0-9]*$/.test(e.currentTarget.value)) {
      // @ts-ignore
      inputRef.current.oldValue = inputRef.current.value
    } else {
      // @ts-ignore
      e.target.value = inputRef.current.oldValue
    }
  };


  

  const placeholder = useMemo(() => {
    if (question.meta?.max !== undefined && question.meta?.min !== undefined) {
      return `Between ${question.meta.min} and ${question.meta.max}`
    } else if (question.meta?.min !== undefined) {
      return `>= ${question.meta.min}`
    } else if (question.meta?.max !== undefined) {
      return `<= ${question.meta.max}`
    }

    return 'Number'
  }, [question.meta?.max, question.meta?.min])

  return (
    <FastField name={question.key}>
      {({ field, form }: FastFieldProps) => {
        return (
          <FormControl
            isInvalid={
              form.errors[field.name] !== undefined &&
              form.touched[field.name] === true
            }
          >
          <Box ref={ref}>
            <NumberInput 
              clampValueOnBlur
              keepWithinRange
              min={question.meta?.min}
              max={question.meta?.max}
              onChange={(numStr) => {
                form.setFieldValue(question.key, numStr)
             }}>
              <NumberInputField
                {...field}
                ref={inputRef}
                placeholder={placeholder}
                onKeyDown={onHandleInputEvent} 
                onChange={onHandleInputEvent}
              />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Box>
          <FormErrorMessage>
            {form.errors !== undefined && form.errors[field.name]?.toString()}
          </FormErrorMessage>
        </FormControl>
        )
      }}
    </FastField>
  )
})
