import React from 'react'
import { useParams } from 'react-router-dom'

import { KioskFlow } from '@types'
import { KioskSharedView } from './kiosk-shared.view'

export const KioskClinicView = () => {
  const { clinicDisplayId } = useParams()

  return (
    <KioskSharedView
      flow={KioskFlow.CLINIC}
      clinicDisplayId={clinicDisplayId}
    />
  )
}
