import React, { useMemo, useEffect } from 'react'
import {
  VStack,
  Box,
  Text,
  Image,
  Flex,
  Heading,
  List,
  ListItem,
  ListIcon
} from '@chakra-ui/react'
import MobileDetect from 'mobile-detect'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useAppBarStore } from '@core/store'
import { CheckIcon, Button } from '@blueprinthq/joy'

import AppInviteQRCode from '../../assets/images/AppInvite-QRCode.jpeg'
import AppIcon from '../../assets/images/app-icon.png'

export const AppInviteView = () => {
  const navigate = useNavigate()
  const appBarStore = useAppBarStore()
  const md = new MobileDetect(window.navigator.userAgent)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.has('isInviteFlow')) {
      appBarStore.setRightComponent(
        <Button
          variant="ghost"
          color="white"
          m="0px"
          onClick={() => navigate('/')}
        >
          Skip
        </Button>
      )
    }
  }, [searchParams.toString()])

  const isAppStoreDevice = useMemo(() => {
    return md.os() === 'iOS' || md.os() === 'iPadOS'
  }, [])

  const isGooglePlayDevice = useMemo(() => {
    return md.os() === 'AndroidOS'
  }, [])

  return (
    <Flex justify="center">
      <Box width="320px">
        {(isAppStoreDevice || isGooglePlayDevice) && (
          <Flex justify="center" mb="medium">
            <Image width="104px" height="104px" src={AppIcon} />
          </Flex>
        )}
        <Heading textAlign="center" size="lg" mb="xsmall">
          Get the Blueprint app
        </Heading>
        <Text textAlign="center" mb="medium">
          Scan the QR code with your phone.
        </Text>
        <VStack spacing="medium" mb="medium" alignItems="center">
          <List spacing="xsmall">
            <ListItem>
              <ListIcon mr="small" as={CheckIcon} boxSize="24px" fill="primary" />
              View treatment progress
            </ListItem>
            <ListItem>
              <ListIcon mr="small" as={CheckIcon} boxSize="24px" fill="primary" />
              Turn on daily check-in reminders
            </ListItem>
            <ListItem>
              <ListIcon mr="small" as={CheckIcon} boxSize="24px" fill="primary" />
              Connect Health data
            </ListItem>
            <ListItem>
              <ListIcon mr="small" as={CheckIcon} boxSize="24px" fill="primary" />
              Get the full experience
            </ListItem>
          </List>
          <Flex justify="center" align="center" flexDirection="column">
            {isAppStoreDevice && (
              <Box width="200px" mt="xsmall">
                <a href="https://apps.apple.com/us/app/blueprint-health/id1333549266">
                  <Image
                    width="100%"
                    src="https://res.cloudinary.com/hellojoy/image/upload/v1636398355/marketing/App_Store.png"
                  />
                </a>
              </Box>
            )}
            {isGooglePlayDevice && (
              <Box width="200px" mt="xsmall">
                <a href="https://play.google.com/store/apps/details?id=com.blueprint_health.android_prod">
                  <Image
                    width="100%"
                    src="https://res.cloudinary.com/hellojoy/image/upload/v1636398356/marketing/Play_Store.png"
                  />
                </a>
              </Box>
            )}
            {!isAppStoreDevice && !isGooglePlayDevice && (
              <Box width="280px" height="280px">
                <Image src={AppInviteQRCode} />
              </Box>
            )}
          </Flex>
        </VStack>
      </Box>
    </Flex>
  )
}
