import React, { useState, useEffect } from 'react'
import { Box, VStack, Text } from '@chakra-ui/react'
import { Button, TextField } from '@blueprinthq/joy'
import { Formik, Field, Form, FieldProps } from 'formik'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as Yup from 'yup'

import { useAuthenticationControllerV1PostForgotPasswordConfirm } from '~/api/public'
import { useWebViewStore } from '@core/hooks'
import { PasswordRules } from '@components/password-rules'

interface ResetPasswordFormProps {
  onSuccess: (bool: boolean) => void
}

export const ResetPasswordForm = ({ onSuccess }: ResetPasswordFormProps) => {
  const ResetPasswordSchema = Yup.object({
    code: Yup.string().required(
      'Please enter the reset code sent to your email'
    ),
    password: Yup.string().password().required('Required'),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password')],
      'Passwords do not match'
    )
  })

  const [confirmError, setConfirmError] = useState<string | null>(null)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const webViewStore = useWebViewStore()

  useEffect(() => {
    // If query parameter is not provided, then go back
    if (!searchParams.has('email')) {
      navigate('/forgot-password')
    }
  }, [searchParams])

  const { mutate: forgotPasswordResetConfirmCode } =
    useAuthenticationControllerV1PostForgotPasswordConfirm({
      mutation: {
        onSuccess: () => {
          onSuccess(true)
        },
        onError() {
          setConfirmError(
            'Something went wrong. Did you enter the right email?.'
          )
        }
      }
    })

  return (
    <Formik
      validationSchema={ResetPasswordSchema}
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={{
        code: '',
        password: '',
        confirmPassword: ''
      }}
      validate={() => setConfirmError(null)}
      onSubmit={async (values, actions) => {
        if (searchParams.has('email')) {
          await forgotPasswordResetConfirmCode({
            data: {
              email: searchParams.get('email') as string,
              confirmationCode: values.code.trim(),
              newPassword: values.password,
              userType: 'patient'
            }
          })
        }
        actions.setSubmitting(false)
      }}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <VStack mb="medium" spacing="small" alignItems="flex-start">
            <Box
              w="100%"
              mb={errors.code && touched.code ? 'small' : 'initial'}
            >
              <Field name="code">
                {({ field }: FieldProps) => (
                  <TextField
                    {...field}
                    onInput={(e) => {
                      // Forces 'text' input to only allow for numbers
                      // why we use 'text' input for this and not 'number': https://web.dev/sms-otp-form/#type=text
                      e.currentTarget.value = e.currentTarget.value.replace(/[^0-9.]/g, "")
                    }}
                    autoFocus
                    type="text"
                    maxLength={6}
                    inputMode="numeric"
                    autoComplete="one-time-code"
                    label="Reset code from your email"
                    isRequired
                    isInvalid={!!(errors.code && touched.code)}
                    errorText={errors.code}
                  />
                )}
              </Field>
            </Box>
            <Box
              w="100%"
              pb={errors.password && touched.password ? 'small' : 'initial'}
            >
              <Field name="password">
                {({ field }: FieldProps) => (
                  <>
                    <TextField
                      {...field}
                      type="password"
                      label="New password"
                      isRequired
                      isInvalid={!!(errors.password && touched.password)}
                    />
                    <Box>
                      {touched.password && errors.password && (
                        <Box mt="xsmall">
                          <PasswordRules password={field.value} />
                        </Box>
                      )}
                    </Box>
                  </>
                )}
              </Field>
            </Box>
            <Box
              w="100%"
              pb={
                errors.confirmPassword && touched.confirmPassword
                  ? 'small'
                  : 'initial'
              }
            >
              <Field name="confirmPassword">
                {({ field }: FieldProps) => (
                  <TextField
                    {...field}
                    label="Confirm new password"
                    type="password"
                    isRequired
                    isInvalid={
                      !!(errors.confirmPassword && touched.confirmPassword)
                    }
                    errorText={errors.confirmPassword}
                  />
                )}
              </Field>
            </Box>
            {confirmError && (
              <Text w="100%" color="error">
                {confirmError}
              </Text>
            )}
            <Button
              size="lg"
              isLoading={isSubmitting}
              type="submit"
              w="100%"
            >
              Update
            </Button>
          </VStack>
        </Form>
      )}
    </Formik>
  )
}
