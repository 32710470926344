import React from 'react'
import { Box} from '@chakra-ui/react'

import { Loading } from '@components'
import { AssessmentForm } from '../../features/assessment-form-carousel/components'
import { useAssessmentControllerV1GetAssessments } from '~/api/public'
import { useSearchParams } from 'react-router-dom'

export const AssessmentPreviewView = () => {
  const [searchParams] = useSearchParams()

  const { data: assessments = [], isLoading: isAssessmentLoading } = useAssessmentControllerV1GetAssessments({
    // @ts-ignore
    Ids: [searchParams.get('assessment')]
  })

  return (
    <Box w="100%">
      <Box>
      {isAssessmentLoading ? <Loading /> : (
        <AssessmentForm assessment={assessments[0]} isLastAssessment isPreview />
      )}
      </Box>
    </Box>
  )

}