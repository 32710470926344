import React from 'react'
import { Box } from '@chakra-ui/react'

export const IconBadge = ({
  count,
  children
}: {
  count: number
  children: any
}) => {
  const size = '18px'
  const position = '-8px'

  return (
    <>
      {count > 0 ? (
        <Box position="relative">
          {children}
          <Box
            display="flex"
            position="absolute"
            justifyContent="center"
            border="2px solid"
            borderColor="white"
            bgColor="primary"
            borderRadius="50%"
            h={size}
            w={size}
            fontSize="10px"
            fontWeight="bold"
            textColor="white"
            top={position}
            right={position}
          >
            {count}
          </Box>
        </Box>
      ) : (
        <> {children}</>
      )}
    </>
  )
}
