import React, { useMemo } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { useOnNavBack } from '@hooks'
import { BlueForm } from '@blueprinthq/joy-business'
import { useUsersControllerV1GetPatientIntervention } from '~/api/client'
import { Loading, AppContainer } from '@components'

export const InterventionView = () => {
  const { userId, interventionId } = useParams()
  const navigate = useNavigate()
  useOnNavBack(() => navigate(-1))

  const { data: patientIntervention, isLoading } =
    useUsersControllerV1GetPatientIntervention(userId as string, interventionId as string)

  const values = useMemo(() => {
      return patientIntervention?.answers
      .reduce((acc: any, a) => {
        acc[a.interventionItemId] = a.answer.value
        return acc
      }, {})
    }, [patientIntervention?.answers])
  
  if (isLoading) return <Loading isFullPage />

  return (
    <AppContainer>
       <BlueForm
        title={patientIntervention!.name}
        form={patientIntervention!.items}
        isDisabled
        initialValues={values}
      />
    </AppContainer>
  )
}
