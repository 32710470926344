import React from 'react'
import { Box, Text } from '@chakra-ui/react'

interface NoAssignedAssessmentsProps {
  children?: JSX.Element | null
}

export const NoAssignedAssessments = ({
  children
}: NoAssignedAssessmentsProps) => {
  return (
    <Box>
      <Text align="center" fontSize="x-large" fontWeight="bold" mt="medium">
        This link is no longer available
      </Text>
      {children}
    </Box>
  )
}
